import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const partnersAdminApi = createApi({
   reducerPath: 'partnersApi',
   baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
      prepareHeaders: (headers, { getState }) => {
         const token = getState().userAuth.token
         headers.set('Authorization', `Bearer ${token}`)
         return headers
      },
   }),
   endpoints: (builder) => ({
      getPartners: builder.query({
         query: () => '/partners/allPartners',
         providesTags: ['Partner'],
      }),
      getPartnerById: builder.query({
         query: (id) => `/partners/${id}`,
         providesTags: ['Partner'],
      }),
      createPartner: builder.mutation({
         query: (partner) => ({
            url: '/partners',
            method: 'POST',
            body: partner,
         }),
         invalidatesTags: ['Partner'],
      }),
      removePartner: builder.mutation({
         query: (id) => ({
            url: `/partners/${id}/delete`,
            method: 'DELETE',
         }),
         invalidatesTags: ['Partner'],
      }),
      updatePartner: builder.mutation({
         query: ({ id, ...updatedData }) => ({
            url: `/partners/${id}/update`,
            method: 'PUT',
            body: updatedData,
         }),
         invalidatesTags: ['Partner'],
      }),
   }),
})

export const {
   useGetPartnersQuery,
   useCreatePartnerMutation,
   useRemovePartnerMutation,
   useUpdatePartnerMutation,
   useGetPartnerByIdQuery,
} = partnersAdminApi
