import { createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseBlogUrl = process.env.REACT_APP_BASE_URL
export const blogSlice = createSlice({
   name: 'blogSlice',
   initialState: { editID: 0 },
   reducers: {
      editID: (state, action) => {
         state.editID = action.payload
      },
   },
})

export const blogActions = blogSlice.actions

export const getBlogById = createApi({
   reducerPath: 'getBlogById',
   baseQuery: fetchBaseQuery({
      baseUrl: baseBlogUrl,
      prepareHeaders: (headers, { getState }) => {
         const token = getState().userAuth.token
         headers.set('Authorization', `Bearer ${token}`)
         return headers
      },
   }),
   endpoints: (builder) => ({
      getBlogById: builder.query({
         query: (editID) => (editID ? `/blogs/${editID}/get` : ''),
      }),
      postImage: builder.mutation({
         query: (file) => ({
            url: '/file',
            method: 'POST',
            body: file,
         }),
      }),
      updateBlog: builder.mutation({
         query: ({ id, title, text, image }) => ({
            url: `/blogs/${id}/update`,
            method: 'PUT',
            body: { title, text, image },
         }),
      }),
      postBlog: builder.mutation({
         query: ({ title, image, text }) => ({
            url: `/blogs`,
            method: 'POST',
            body: { title, image, text },
         }),
         invalidatesTags: ['Blog'],
      }),
      deleteBlog: builder.mutation({
         query: (id) => ({
            url: `blogs/${id}/delete`,
            method: 'DELETE',
            headers: {
               Authorization: 'Bearer YOUR_TOKEN_HERE',
            },
         }),
      }),
   }),
})

export const {
   useGetBlogByIdQuery,
   usePostImageMutation,
   useUpdateBlogMutation,
   usePostBlogMutation,
   useDeleteBlogMutation,
} = getBlogById
