import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'

const styledBox = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   zIndex: 100,
   width: { xs: '80%', md: '90%' },
   maxWidth: '715px',
   height: '434px',
   maxHeight: '100%',
   bgcolor: 'background.paper',
   border: '2px solid #000',
   borderRadius: '8px',
   boxShadow: 24,
   p: { xs: '2rem', sm: '3rem', md: '95px 70px 0 70px' },
   overflow: 'auto',
   transition: 'all 0.3s ease-in-out',
}

export default function SendNotificationSuccess({
   open,
   onClose,
   text,
   onNavigate,
}) {
   useEffect(() => {
      if (open) {
         const timer = setTimeout(() => {
            onClose()
            if (onNavigate) onNavigate()
         }, 3000)
         return () => clearTimeout(timer)
      }
   }, [open, onClose])

   return (
      <Modal
         open={open}
         onClose={onClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
      >
         <Box sx={styledBox}>
            <IconButton
               sx={{
                  background: 'black',
                  width: '48px',
                  height: '48px',
                  position: 'absolute',
                  right: '32px',
                  top: '32px',
               }}
               onClick={onClose}
            >
               <CloseIcon
                  sx={{ color: 'white', fontSize: '30px', cursor: 'pointer' }}
               />
            </IconButton>

            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80%',
                  gap: '20px',
               }}
            >
               <IconButton
                  sx={{
                     maxWidth: '116px',
                     width: '100%',
                     maxHeight: '116px',
                     height: '100%',
                     border: '10px solid green',
                  }}
               >
                  <DoneOutlinedIcon
                     sx={{
                        fontSize: '100px',
                        color: 'green',
                     }}
                  />
               </IconButton>
               <Typography
                  sx={{
                     fontSize: { xs: '24px', md: '32px' },
                     fontWeight: '600',
                     textAlign: 'center',
                  }}
                  id="modal-modal-description"
               >
                  {text}
               </Typography>
            </Box>
         </Box>
      </Modal>
   )
}
