import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const AdminToken =
   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im51ckBnbWFpbC5jb20iLCJpYXQiOjE3MzA2MTMwNzUsImV4cCI6MTczMzIwNTA3NX0.0Qqd4BVYXitViN-diHlD3zzexA-gLK5946risMNEje0'

export const reviewApi = createApi({
   reducerPath: 'reviewApi',
   baseQuery: fetchBaseQuery({
      baseUrl: 'http://3.122.191.120/api/',
      prepareHeaders: (headers) => {
         headers.set('Authorization', `Bearer ${AdminToken}`)
         return headers
      },
   }),
   endpoints: (builder) => ({
      GetReview: builder.query({
         query: () => 'reviews/allReviews?currentPage=1&pageSize=1',
      }),
   }),
})

export const { useGetReviewQuery } = reviewApi;
