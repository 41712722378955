import {
   Box,
   Button,
   Container,
   InputBase,
   Modal,
   Typography,
   TextField,
} from '@mui/material'
import React, { useEffect } from 'react'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import CloseIcon from '@mui/icons-material/Close'
import { style } from './FormBlog'
import gold from '../../assets/gold.png'
import mark from '../../assets/mark.svg'
import { useSelector } from 'react-redux'
import {
   useGetBlogByIdQuery,
   usePostImageMutation,
   useUpdateBlogMutation,
} from '../../store/features/blogSlice'

const EditBlog = () => {
   const [open, setOpen] = React.useState(false)
   const [updatedTitle, setUpdatedTitle] = React.useState('')
   const [updatedText, setUpdatedText] = React.useState('')
   const [updatedImage, setUpdatedImage] = React.useState('')

   const { editID } = useSelector((state) => state.blogSlice)

   const handleOpen = () => {
      updateBlog()
      setOpen(true)
   }

   const { data } = useGetBlogByIdQuery(editID, {
      skip: !editID,
   })

   const [updateBlog] = useUpdateBlogMutation()

   const [postImage, { data: imageData }] =
      usePostImageMutation()

   useEffect(() => {
      if (imageData) {
         setUpdatedImage(imageData.link)
      }
   }, [imageData])

   const handleImageUpload = async (event) => {
      const file = new FormData()
      file.append('file', event.target.files[0])
      await postImage(file)
   }

   const handleUpdateBlog = async () => {
      await updateBlog({
         id: editID,
         title: updatedTitle,
         text: updatedText,
         image: updatedImage,
      })
      handleOpen()
   }

   useEffect(() => {
      if (data) {
         setUpdatedTitle(data.title || '')
         setUpdatedText(data.text || '')
         setUpdatedImage(data.image || '')
      }
   }, [data, updateBlog])

   return (
      <Container>
         <Box
            sx={{
               padding: '30px 0',
               width: '945px',
               '@media (max-width: 850px)': {
                  width: '100%',
               },
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '15px',
                  marginBottom: '44px',
               }}
            >
               <img
                  style={{
                     borderRadius: '8px',
                     width: '220px',
                     height: '250px',
                  }}
                  src={updatedImage ? updatedImage : gold}
                  alt=""
               />
               <Box sx={{ position: 'relative', width: '219px' }}>
                  <InputBase
                     sx={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        cursor: 'pointer',
                     }}
                     type="file"
                     onChange={handleImageUpload}
                  />
                  <Box
                     sx={{
                        color: 'rgb(77, 75, 75)',
                        border: '1px solid rgb(210, 210, 211)',
                        borderRadius: '8px',
                        background: 'rgb(255, 255, 255)',
                        boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        padding: '10px 6px',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '19px',
                        fontWeight: '400',
                        lineHeight: '26px',
                        zIndex: 0,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                        justifyContent: 'center',
                     }}
                  >
                     <ImageSearchIcon /> Cүрөттү өзгөртүү
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '50px',
                  '@media (max-width: 600px)': {
                     flexDirection: 'column',
                     alignItems: 'flex-start',
                     margin: '0 auto',
                  },
               }}
            >
               <Typography
                  sx={{
                     fontFamily: 'Istok Web, sans-serif',
                     fontSize: '24px',
                     fontWeight: '400',
                     lineHeight: '29px',
                     marginBottom: '10px',
                     '@media (max-width: 750px)': {
                        fontSize: '20px',
                     },
                  }}
               >
                  Тема:
               </Typography>
               <TextField
                  onChange={(e) => setUpdatedTitle(e.target.value)}
                  value={updatedTitle}
                  sx={{
                     borderRadius: '8px',
                     background: 'rgb(255, 255, 255)',
                     boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '19px',
                     fontWeight: '400',
                     lineHeight: '26px',
                     width: '60%',

                     '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                           borderColor: 'grey',
                        },
                        '&:hover fieldset': {
                           borderColor: 'lightgrey',
                        },
                        '&.Mui-focused fieldset': {
                           borderColor: 'transparent',
                        },
                     },
                     border: '1px solid grey',
                     '@media (max-width: 600px)': {
                        width: '100%',
                     },
                  }}
                  placeholder="Text"
               />
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  '@media (max-width: 600px)': {
                     flexDirection: 'column',
                     alignItems: 'flex-start',
                     marginTop: '20px',
                  },
               }}
            >
               <Typography
                  sx={{
                     fontFamily: 'Istok Web, sans-serif',
                     fontSize: '24px',
                     fontWeight: '400',
                     lineHeight: '29px',
                     marginBottom: '10px',
                     '@media (max-width: 750px)': {
                        fontSize: '20px',
                     },
                  }}
               >
                  Маалымат:
               </Typography>
               <TextField
                  onChange={(e) => setUpdatedText(e.target.value)}
                  value={updatedText}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  placeholder="Text"
                  sx={{
                     borderRadius: '8px',
                     background: 'rgb(255, 255, 255)',
                     boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '19px',
                     fontWeight: '400',
                     lineHeight: '26px',
                     width: '60%',

                     '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                           borderColor: 'grey',
                        },
                        '&:hover fieldset': {
                           borderColor: 'lightgrey',
                        },
                        '&.Mui-focused fieldset': {
                           borderColor: 'transparent',
                        },
                     },
                     border: '1px solid grey',
                     '@media (max-width: 600px)': {
                        width: '100%',
                     },
                  }}
               />
            </Box>
            <Button
               onClick={handleUpdateBlog}
               sx={{
                  fontFamily: 'Inter, sans-serif',
                  borderRadius: '8px',
                  background: 'rgb(205, 164, 7)',
                  color: 'rgb(0, 0, 0)',
                  fontSize: '24px',
                  fontWeight: '600',
                  lineHeight: '29px',
                  padding: '13px 48px',
                  marginTop: '35px',
                  marginLeft: '380px',
               }}
            >
               Өзгөртүү
            </Button>
         </Box>
         <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <Button
                  onClick={() => setOpen(false)}
                  sx={{
                     minWidth: '46px',
                     minHeight: '46px',
                     borderRadius: '50%',
                     background: 'rgb(3, 22, 52)',
                     marginLeft: 'auto',
                  }}
               >
                  <CloseIcon sx={{ color: '#fff' }} />
               </Button>
               <img
                  style={{
                     width: '116px',
                     height: '116px',
                     margin: '0 auto',
                  }}
                  src={mark}
                  alt=""
               />
               <Typography
                  variant="h1"
                  sx={{
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '32px',
                     fontWeight: '600',
                     lineHeight: '39px',
                     color: '#000',
                     textAlign: 'center',
                     margin: '33px auto',
                     width: '503px',
                     '@media (max-width: 750px)': {
                        fontSize: '26px',
                        width: '80%',
                     },
                  }}
               >
                  Блог тууралуу маалымат ийгиликтүү кошулду!
               </Typography>
            </Box>
         </Modal>
      </Container>
   )
}

export default EditBlog
