import React from 'react'
import PartnersForms from '../../../components/admin/formSection/PartnersForms'
import { useGetPartnerByIdQuery } from '../../../store/features/partnersSlice'
import { useParams } from 'react-router-dom'

const AddOrEditPartnersPage = () => {
   const { id } = useParams()
   const isEditMode = Boolean(id)
   const { data: initialData, isLoading } = useGetPartnerByIdQuery(id, {
      skip: !isEditMode,
   })

   if (isLoading) return <div>Loading...</div>
   console.log('checked', initialData)
   return (
      <PartnersForms
         initialData={initialData}
         isEditMode={isEditMode}
      />
   )
}

export default AddOrEditPartnersPage
