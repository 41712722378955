import { createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const mentorSlice = createSlice({
   name: 'mentorSlice',
   initialState: { editID: 0 },
   reducers: {
      editID: (state, action) => {
         state.editID = action.payload
      },
   },
})

export const mentorActions = mentorSlice.actions

export const getMentorSlice = createApi({
   reducerPath: 'getMentorSlice',
   baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
      prepareHeaders: (headers, { getState }) => {
         const token = getState().userAuth.token
         if (token) {
            headers.set('Authorization', `Bearer ${token}`)
         }
         return headers
      },
   }),
   tagTypes: ['Mentors'],
   endpoints: (builder) => ({
      getAllMentors: builder.query({
         query: () => ({
            url: 'mentor/getAll',
            method: 'GET',
         }),
         transformResponse: (response) => {
            return {
               mentors: response.mentors || [],
               currentPage: response.currentPage,
               pageSize: response.pageSize,
            }
         },
         providesTags: ['Mentors'],
      }),

      getMentorSlice: builder.query({
         query: (editID) => (editID ? `/mentor/${editID}` : ''),
         providesTags: ['Mentors'],
      }),

      postImage: builder.mutation({
         query: (file) => ({
            url: '/file',
            method: 'POST',
            body: file,
         }),
      }),

      updateMentor: builder.mutation({
         query: ({ id, imageMentor, fullName, specialization }) => ({
            url: `/mentor/${id}/update`,
            method: 'PUT',
            body: { imageMentor, fullName, specialization },
         }),
         invalidatesTags: ['Mentors'],
      }),

      postMentor: builder.mutation({
         query: ({ imageMentor, fullName, specialization }) => ({
            url: `/mentor/save`,
            method: 'POST',
            body: { imageMentor, fullName, specialization },
         }),
         invalidatesTags: ['Mentors'],
      }),

      deleteMentor: builder.mutation({
         query: (id) => ({
            url: `mentor/${id}`,
            method: 'DELETE',
         }),
         invalidatesTags: ['Mentors'],
      }),
   }),
})

export const {
   useGetAllMentorsQuery,
   useGetMentorSliceQuery,
   usePostImageMutation,
   useUpdateMentorMutation,
   usePostMentorMutation,
   useDeleteMentorMutation,
} = getMentorSlice
