import React from 'react'
import { useDispatch } from 'react-redux'
import { useGoogleSignInMutation } from '../../store/features/authAPI'
import singUpWithGoogle from './firebase-auth'
import { Button, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { setUser } from '../../store/features/userAuthSlice'

// Styled MUI Button
const GoogleButton = styled(Button)(({ theme }) => ({
   width: '100%',
   height: '60px',
   backgroundColor: '#fff',
   color: '#757575',
   textTransform: 'none',
   fontSize: '16px',
   fontWeight: 500,
   border: '1px solid #dadce0',
   borderRadius: '4px',
   boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
   margin: '40px 0 ',
   '&:hover': {
      backgroundColor: '#f8f9fa',
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.1)',
      border: '1px solid #dadce0',
   },
   '&.Mui-disabled': {
      backgroundColor: '#fff',
      color: '#757575',
      opacity: 0.7,
   },
}))

const GoogleLoginButton = () => {
   const dispatch = useDispatch()
   const [googleSignIn, { isLoading }] = useGoogleSignInMutation()

   const handleGoogleLogin = async () => {
      try {
         // Get Firebase user data
         const firebaseUser = await singUpWithGoogle()
         const idToken = await firebaseUser.getIdToken()

         // Get backend authentication data
         const response = await googleSignIn(idToken).unwrap()

         // Combine Firebase and backend data
         const userData = {
            email: response.email,
            fullName: firebaseUser.displayName,
            id: firebaseUser.uid,
            token: response.token,
            role: response.roleName, // Changed from data?.role to response.roleName
            photoURL: firebaseUser.photoURL || null,
         }

         // Dispatch combined user data to Redux
         dispatch(setUser(userData))

         // Optional: Log the combined data
         console.log('Combined user data:', userData)
      } catch (error) {
         console.error('Error during Google sign in:', error)
         // You might want to add error handling here (e.g., showing a snackbar/toast)
      }
   }

   return (
      <GoogleButton
         onClick={handleGoogleLogin}
         disabled={isLoading}
         startIcon={
            isLoading ? (
               <CircularProgress size={20} color="inherit" />
            ) : (
               <svg
                  width="18"
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
               >
                  <path
                     fill="#FFC107"
                     d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                  />
                  <path
                     fill="#FF3D00"
                     d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                  />
                  <path
                     fill="#4CAF50"
                     d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                  />
                  <path
                     fill="#1976D2"
                     d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                  />
               </svg>
            )
         }
      >
         {isLoading ? 'Кирүүдө...' : 'Google менен кирүү'}
      </GoogleButton>
   )
}

export default GoogleLoginButton
