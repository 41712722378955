import React, { useEffect, useState } from 'react'
import {
   Avatar,
   Box,
   Typography,
   TextField,
   Button,
   CircularProgress,
   Alert,
   Snackbar,
} from '@mui/material'
import InputMask from 'react-input-mask'
import { CiMobile3 } from 'react-icons/ci'
import { styled } from '@mui/system'
import ProfilePicture from '../assets/imgs/profile-picture.svg'
import ProfileNav from './ProfileNav'
import ImageUpload from './ui/UploadProfileImage'
import {
   useUpdateProfileMutation,
   useGetProfileQuery,
   useUploadImageMutation,
} from '../store/features/profileApi'
import { useSelector } from 'react-redux'
import { useGetAllPartnersQuery } from '../store/features/apiPartners'

const StyledContainer = styled(Box)(({ theme }) => ({
   width: '100%',
   height: '100vh',
   display: 'flex',
   padding: '0',
   [theme.breakpoints.up('sm')]: {
      padding: '20px',
   },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
   fontSize: '2.5rem',
   fontWeight: '400',
   textAlign: 'center',
   marginBottom: '30px',
   [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
   },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
   marginBottom: '20px',
   borderRadius: '8px',
   width: '100%',
   height: '55px',
}))

const PhoneInputContainer = styled(Box)(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   border: '1px solid #ccc',
   borderRadius: '8px',
   height: '55px',
   flex: 1,
   [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
   },
   [theme.breakpoints.up('sm')]: {
      maxWidth: '420px',
   },
   '&:focus-within': {
      borderColor: 'blue',
   },
}))

const CountryCode = styled(Typography)(({ theme }) => ({
   padding: '10px',
   backgroundColor: '#f0f0f0',
   borderRight: '1px solid #ccc',
   fontSize: '16px',
   border: 'none',
   background: 'none',
}))

const StyledInputMask = styled(InputMask)({
   flex: 1,
   fontSize: '16px',
   height: '50px',
   background: 'transparent',
   border: 'none',
   outline: 'none',
})

const EditButton = styled(Button)(({ isEditing }) => ({
   color: isEditing ? 'white' : 'rgba(205, 164, 7, 0.83)',
   textTransform: 'none',
   backgroundColor: isEditing ? 'rgb(205, 164, 7)' : 'rgb(3, 22, 52)',
   padding: '9px 36px',
   width: '168px',
   height: '50px',
   fontSize: '22px',
   fontWeight: '700',
   borderRadius: '6px',
   cursor: 'pointer',
   '&:hover': {
      opacity: 0.9,
   },
}))

export default function Profile() {
   const userId = useSelector((state) => state.userAuth.id)
   const {
      data: profileDataFromApi,
      error,
      isLoading,
   } = useGetProfileQuery(userId)

   const [profileData, setProfileData] = useState({
      id: userId,
      fullName: '',
      phone: '',
      avatar: ProfilePicture,
   })

   const [isEditing, setIsEditing] = useState(false)
   const [snackbarMessage, setSnackbarMessage] = useState('')
   const [openSnackbar, setOpenSnackbar] = useState(false)

   const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation()
   const [uploadImage] = useUploadImageMutation()

   useEffect(() => {
      if (profileDataFromApi) {
         setProfileData({
            id: userId,
            name: profileDataFromApi.fullName || '',
            phone: profileDataFromApi.phoneNumber || '',
            avatar: profileDataFromApi.imageProfile || ProfilePicture,
         })
      }
   }, [profileDataFromApi, userId])

   const handleChange = (field) => (event) => {
      setProfileData((prevData) => ({
         ...prevData,
         [field]: event.target.value,
      }))
   }

   const handleImageSelect = async (file) => {
      try {
         const formData = new FormData()
         formData.append('file', file)

         const response = await uploadImage(formData).unwrap()
         setProfileData((prevData) => ({
            ...prevData,
            avatar: response.link,
         }))
      } catch (error) {
         console.error('Ошибка при загрузке изображения:', error)
      }
   }

   const handleButtonClick = async () => {
      if (isEditing) {
         try {
            await updateProfile(profileData).unwrap()
            setSnackbarMessage('Малымат сакталды!')
         } catch (error) {
            setSnackbarMessage('Сактоо мүмкүн эмес')
         } finally {
            setOpenSnackbar(true)
         }
      }
      setIsEditing(!isEditing)
   }

   const handleCloseSnackbar = () => {
      setOpenSnackbar(false)
   }

   if (isLoading)
      return (
         <div>
            {' '}
            <Box
               display="flex"
               justifyContent="center"
               alignItems="center"
               height="100vh"
               bgcolor="darkblue"
            >
               <CircularProgress
                  size={60}
                  thickness={5}
                  sx={{
                     color: 'linear-gradient(45deg, white, limegreen)',
                  }}
               />
            </Box>
         </div>
      )
   if (error)
      return (
         <div>
            <Box
               display="flex"
               justifyContent="center"
               alignItems="center"
               height="100vh"
               bgcolor="darkblue"
            >
               <Alert
                  severity="error"
                  sx={{ bgcolor: 'white', color: 'black' }}
               >
                  <Typography variant="h6" sx={{ color: 'black' }}>
                     Кандайдыр бир катта бар!
                  </Typography>
               </Alert>
            </Box>
         </div>
      )

   return (
      <StyledContainer>
         <ProfileNav />
         <Box sx={{ padding: '20px', width: '100%', overflow: 'auto' }}>
            <StyledTypography>Профиль</StyledTypography>
            <Typography
               variant="subtitle1"
               sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  marginBottom: '10px',
                  textAlign: 'start',
               }}
            >
               Колдонуучунун сүрөтү
            </Typography>
            <Box display="flex" alignItems="flex-end" mb={4}>
               <Box mr={{ xs: '20px', sm: 4 }}>
                  <Avatar
                     src={profileData.avatar}
                     sx={{
                        width: { xs: '100px', sm: 150 },
                        height: { xs: '100px', sm: 150 },
                     }}
                  />
               </Box>
               <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="start"
               >
                  <Typography
                     variant="caption"
                     display="block"
                     sx={{
                        fontSize: '14px',
                        color: '#666',
                        marginBottom: '10px',
                        textAlign: 'start',
                     }}
                  >
                     PNG, JPG 225x280 ден жогору болбогон 10 мб.
                  </Typography>
                  <ImageUpload
                     isEditing={isEditing}
                     onImageSelect={handleImageSelect}
                  />
               </Box>
            </Box>
            <Box mb={2} sx={{ maxWidth: '480px' }}>
               <Typography
                  variant="subtitle1"
                  sx={{
                     fontSize: '16px',
                     fontWeight: 700,
                     color: 'rgb(35, 27, 27)',
                     marginBottom: '5px',
                     textAlign: 'start',
                  }}
               >
                  Аты-жөнү*
               </Typography>
               <StyledTextField
                  fullWidth
                  variant="outlined"
                  value={profileData.name}
                  onChange={handleChange('name')}
                  disabled={!isEditing}
               />
            </Box>
            <Box margin="40px 0">
               <Typography
                  variant="subtitle1"
                  sx={{
                     fontSize: '16px',
                     fontWeight: 700,
                     color: 'rgb(35, 27, 27)',
                     marginBottom: '5px',
                     textAlign: 'start',
                  }}
               >
                  Телефон номер*
               </Typography>
               <Box display="flex" alignItems="center">
                  <CiMobile3
                     style={{
                        minWidth: '40px',
                        fontSize: '50px',
                        marginRight: '10px',
                     }}
                  />
                  <PhoneInputContainer>
                     <CountryCode>+996</CountryCode>
                     <StyledInputMask
                        mask="999 99 99 99"
                        maskChar="_"
                        value={profileData.phone}
                        onChange={handleChange('phone')}
                        placeholder="000 00 00 00"
                        disabled={!isEditing}
                     />
                  </PhoneInputContainer>
               </Box>
            </Box>
            <Box marginTop={4} alignItems="center" sx={{ maxWidth: '480px' }}>
               <EditButton
                  onClick={handleButtonClick}
                  isEditing={isEditing}
                  disabled={isUpdating}
               >
                  {isEditing
                     ? isUpdating
                        ? 'Сакталууда...'
                        : 'Cактоо'
                     : 'Өзгөртүү'}
               </EditButton>
            </Box>
            <Snackbar
               open={openSnackbar}
               autoHideDuration={6000}
               onClose={handleCloseSnackbar}
               message={snackbarMessage}
            />
         </Box>
      </StyledContainer>
   )
}
