import React from 'react';

const ImageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink" // Changed here
      width="25.882812"
      height="21.802490"
      viewBox="0 0 25.8828 21.8025"
      fill="none"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        d="M2.62 21.05L23.25 21.05C23.77 21.05 24.21 20.85 24.58 20.45C24.94 20.06 25.12 19.58 25.12 19.02L25.12 2.78C25.12 2.21 24.94 1.74 24.58 1.34C24.21 0.94 23.77 0.75 23.25 0.75L2.62 0.75C2.11 0.75 1.66 0.94 1.3 1.34C0.93 1.74 0.75 2.21 0.75 2.78L0.75 19.02C0.75 19.58 0.93 20.06 1.3 20.45C1.66 20.85 2.11 21.05 2.62 21.05ZM16.22 5.82C16.22 6.16 16.06 6.33 15.75 6.33C15.44 6.33 15.28 6.16 15.28 5.82C15.28 5.48 15.44 5.31 15.75 5.31C16.06 5.31 16.22 5.48 16.22 5.82ZM1.3 16.45L1.3 16.48C1.01 16.79 0.55 16.81 0.24 16.52C-0.07 16.24 -0.09 15.77 0.2 15.46L0.23 15.46L1.3 16.45ZM18.15 15.46L18.17 15.46C18.46 15.77 18.44 16.24 18.13 16.52C17.83 16.81 17.36 16.79 17.07 16.48L17.07 16.45L18.15 15.46ZM16.3 14.42L16.3 14.45C16.01 14.76 15.55 14.78 15.24 14.49C14.93 14.21 14.91 13.74 15.2 13.43L15.23 13.43L16.3 14.42ZM25.65 15.46L25.67 15.46C25.96 15.77 25.94 16.24 25.63 16.52C25.33 16.81 24.86 16.79 24.57 16.48L24.57 16.45L25.65 15.46Z"
        fill="#000000"
        fillOpacity="0" // Changed here
        fillRule="nonzero" // Changed here
      />
      <path
        d="M0.75 15.97L7.2 8.99C7.75 8.39 8.41 8.1 9.19 8.1C9.96 8.1 10.63 8.39 11.17 8.99L17.62 15.97M15.75 13.94L17.51 12.03C18.06 11.44 18.72 11.14 19.5 11.14C20.28 11.14 20.94 11.44 21.49 12.03L25.12 15.97M23.25 21.05C23.77 21.05 24.21 20.85 24.58 20.45C24.94 20.06 25.12 19.58 25.12 19.02L25.12 2.78C25.12 2.21 24.94 1.74 24.58 1.34C24.21 0.94 23.77 0.75 23.25 0.75L2.62 0.75C2.11 0.75 1.66 0.94 1.3 1.34C0.93 1.74 0.75 2.21 0.75 2.78L0.75 19.02C0.75 19.58 0.93 20.06 1.3 20.45C1.66 20.85 2.11 21.05 2.62 21.05L23.25 21.05ZM15.75 6.33C15.44 6.33 15.28 6.16 15.28 5.82C15.28 5.48 15.44 5.31 15.75 5.31C16.06 5.31 16.22 5.48 16.22 5.82C16.22 6.16 16.06 6.33 15.75 6.33Z"
        stroke="#000000"
        strokeOpacity="1.000000" // Changed here
        strokeWidth="1.5" // Changed here
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ImageIcon;
