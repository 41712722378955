import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const partnersApi = createApi({
   reducerPath: 'partnerApi',
   baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
   endpoints: (builder) => ({
      getAllPartners: builder.query({
         query: () => `/partners/allPartners`,
         providesTags: ['Partners'],
      }),
   }),
})

export const { useGetAllPartnersQuery } = partnersApi
