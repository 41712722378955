import React from 'react'
import { Container, Box, Typography, Grid2 } from '@mui/material'

import mentor from '../assets/imgs/mentor.png'

const mentors = [
   { name: 'Нургул Чоюбекова', position: 'Flutter Developer', img: mentor },
   {
      name: 'Камалидин уулу Бекболот',
      position: 'Frontend Developer',
      img: mentor,
   },
   { name: 'Амирбек Амираев', position: 'Frontend Developer', img: mentor },
]

const Mentors = () => {
   return (
      <section>
         <Container
            maxWidth={false}
            sx={{
               maxWidth: '1288px',
               margin: '50px auto',
               fontFamily: 'Inter',
            }}
         >
            <Box component="section">
               <Typography
                  variant="h2"
                  component="h2"
                  sx={{
                     fontSize: {
                        xs: '32px',
                        sm: '40px',
                        md: '48px',
                     },
                     fontWeight: 500,
                     textAlign: 'center',
                     marginBottom: {
                        xs: '20px',
                        sm: '40px',
                        md: '70px',
                     },
                  }}
               >
                  Менторлор
               </Typography>
               <Grid2
                  container
                  spacing={5}
                  justifyContent={{
                     xs: 'center',
                     sm: 'center',
                     md: 'space-between',
                  }}
               >
                  {mentors.map((item) => (
                     <Grid2
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={item.name}
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           gap: '10px',
                           textAlign: 'center',
                        }}
                     >
                        <img
                           src={item.img}
                           alt={item.name}
                           style={{
                              maxWidth: '100%',
                              height: 'auto',
                           }}
                        />
                        <div>
                           <Typography
                              level="h3"
                              sx={{ fontSize: '20px', fontWeight: '700' }}
                           >
                              {item.name}
                           </Typography>
                           <Typography
                              level="title-lg"
                              sx={{ fontSize: '16px' }}
                           >
                              {item.position}
                           </Typography>
                        </div>
                     </Grid2>
                  ))}
               </Grid2>
            </Box>
         </Container>
      </section>
   )
}
export default Mentors
