import React from 'react'
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import { useGetScheduleQuery } from '../../store/features/scheduleApi'

export default function AboutUsTable() {
   const { data: scheduleData, error, isLoading } = useGetScheduleQuery()
   // console.log(scheduleData, 'scheduleData log')

   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('md'))

   const days = Array.from(
      new Set(scheduleData?.flatMap((item) => Object.keys(item.days)) || [])
   )
   const teams = scheduleData?.map((item) => item.direction) || []

   const renderTable = () => {
      const headerItems = isMobile ? teams : days
      const rowItems = isMobile ? days : teams

      return (
         <Box
            sx={{
               overflowX: 'auto',
               width: '100%',
               maxWidth: '850px',
               margin: '0 auto',
            }}
         >
            <table
               style={{
                  borderCollapse: 'collapse',
                  width: '100%',
                  minWidth: isMobile ? 'auto' : '600px',
               }}
            >
               <thead>
                  <tr>
                     <th
                        style={{
                           background: 'none',
                           border: '3px solid #000',
                           padding: '5px',
                        }}
                     ></th>
                     {headerItems.map((item) => (
                        <th
                           key={item}
                           style={{
                              background: 'none',
                              border: '3px solid #000',
                              padding: '5px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              height: '60px',
                              color: 'black',
                           }}
                        >
                           <Typography
                              sx={{
                                 fontSize: '20px',
                                 fontWeight: '600',
                              }}
                           >
                              {item}
                           </Typography>
                        </th>
                     ))}
                  </tr>
               </thead>
               <tbody>
                  {rowItems.map((rowItem) => (
                     <tr key={rowItem}>
                        <td
                           style={{
                              border: '3px solid #000',
                              padding: '10px',
                              maxWidth: '150px',
                              height: '60px',
                              verticalAlign: 'middle',
                              color: 'black',
                           }}
                        >
                           <Typography
                              sx={{
                                 fontSize: {
                                    xs: '20px',
                                    sm: '16px',
                                    md: '20px',
                                 },
                                 textAlign: 'center',
                                 fontWeight: '600',
                              }}
                           >
                              {isMobile
                                 ? rowItem.slice(0, 1).toUpperCase()
                                 : rowItem}
                           </Typography>
                        </td>
                        {headerItems.map((colItem) => {
                           const team = isMobile ? colItem : rowItem
                           const day = isMobile ? rowItem : colItem

                           const teamSchedule = scheduleData?.find(
                              (item) => item.direction === team
                           )
                           const isScheduled = teamSchedule?.days[day]
                           const timeRange = isScheduled
                              ? `${teamSchedule.startTime.slice(0, 5)} - ${teamSchedule.endTime.slice(0, 5)}`
                              : null

                           return (
                              <td
                                 key={`${rowItem}-${colItem}`}
                                 style={{
                                    border: '3px solid #000',
                                    padding: '5px',
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                    height: '60px',
                                    backgroundColor: isScheduled
                                       ? 'rgb(196, 196, 196)'
                                       : 'white',
                                 }}
                              >
                                 <Typography
                                    sx={{
                                       fontSize: {
                                          xs: '14px',
                                          sm: '16px',
                                          md: '20px',
                                       },
                                       color: 'black',
                                       fontWeight: '400',
                                    }}
                                 >
                                    {isScheduled ? (
                                       timeRange
                                    ) : (
                                       <Typography
                                          sx={{ color: 'transparent' }}
                                       >
                                          {' '}
                                          not scheduled
                                       </Typography>
                                    )}
                                 </Typography>
                              </td>
                           )
                        })}
                     </tr>
                  ))}
               </tbody>
            </table>
         </Box>
      )
   }

   return isLoading ? (
      <Typography>Жүктөө...</Typography>
   ) : error ? (
      <Typography>Расписаниени жүктөөдө ката чыкты</Typography>
   ) : (
      renderTable()
   )
}
