import AdminSectionItems from '../../../components/admin/AdminSectionItems'
import Button from '../../../components/ui/Button'
import * as SC from '../AdminSections.style'
import SendNotificationFail from '../../../components/ui/SendNotificationFail'
import SendNotificationSuccess from '../../../components/ui/SendNotificationSuccess'
import { useNavigate, useParams, Outlet } from 'react-router-dom'
import { useState } from 'react'
import {
   useGetPartnersQuery,
   useRemovePartnerMutation,
} from '../../../store/features/partnersSlice'

const SponsorsPage = () => {
   const [openSuccessoOrFail, setOpenSuccessOrFail] = useState(false)
   const navigate = useNavigate()
   const { data, isLoading } = useGetPartnersQuery()
   const [deletePartners, { error: errorDelete }] = useRemovePartnerMutation()
   console.log(data)

   const handleDeletePartners = async (id) => {
      try {
         await deletePartners(id).unwrap()
         setOpenSuccessOrFail(true)
         console.log('parnters id:', id)
      } catch (error) {
         console.error('Failed to delete the blog:', error)
         setOpenSuccessOrFail(true)
      }
   }
   const { id } = useParams()
   if (id) return <Outlet />
   if (isLoading) return <div>Loading...</div>
   return (
      <SC.StackItems>
         <SC.BoxItem>
            <SC.AdminSectionName>Өнөктөштөр</SC.AdminSectionName>
            <Button handleClick={() => navigate('/admin/add-sponsors')}>
               Кошуу
            </Button>
         </SC.BoxItem>
         {data?.partners?.map((partner, indexItem) => (
            <AdminSectionItems
               key={partner.id}
               numberItems={indexItem + 1}
               companyName={partner?.name}
               fotoSrc={partner?.logo}
               handleDelete={() => handleDeletePartners(partner?.id)}
               textForDeleteModal="Өнөктөштү тизмеден өчүрүү "
               editPathName={`/admin-partners/edit/${partner.id}`}
            />
         ))}
         <SendNotificationSuccess
            open={openSuccessoOrFail}
            onClose={() => setOpenSuccessOrFail(false)}
            text="Өнөктөш тууралуу маалымат ийгиликтүү өчүрүлдү!"
         />
         {errorDelete && (
            <SendNotificationFail
               open={openSuccessoOrFail}
               onClose={() => setOpenSuccessOrFail(false)}
               text={errorDelete?.data?.message}
            />
         )}
      </SC.StackItems>
   )
}

export default SponsorsPage
