import React, { useState } from 'react'
import { googleLogout } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import { clearUser } from '../../store/features/userAuthSlice'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Snackbar,
   useMediaQuery,
   useTheme,
} from '@mui/material'
import { IoLogOutOutline } from "react-icons/io5";

const LogoutButton = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [openDialog, setOpenDialog] = useState(false)
   const [openSnackbar, setOpenSnackbar] = useState(false)
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('md'))

   const handleLogoutClick = () => {
      setOpenDialog(true)
   }

   const handleClose = () => {
      setOpenDialog(false)
   }

   const handleConfirmLogout = () => {
      setOpenDialog(false)

      setOpenSnackbar(true)

      googleLogout()
      Cookies.remove('token')
      dispatch(clearUser())

      setTimeout(() => {
         navigate('/login')
      }, 1500)
   }
   const StyledButton = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      pl: '23px',
      gap: '20px',
      borderRadius: '10px',
      textDecoration: 'none',
      color: 'rgb(205, 164, 7)',
      width: '341px',
      height: '41px',
      boxShadow: '3px 5px 5px rgba(0, 0, 0, 0.3)',
      background: 'white',
      '&.active': {
         background: 'rgb(120, 129, 166)',
         color: 'white',
      },
   }
   

   return (
      <>
         {
            isMobile ? (
               <Button
            onClick={handleLogoutClick}
            variant="contained"
            sx={{
               background: 'none',
               '&:hover': {
                  color: 'rgb(205, 164, 7)',
               },
               width:'110px',
               display:'flex',
               gap:'10px',
               justifyContent:'flex-start',
               padding:'0'
            }}
            
         >
            <IoLogOutOutline size={25} color='rgb(205, 164, 7)'/>
            Чыгуу
         </Button>
            ):(
               <Button
            onClick={handleLogoutClick}
            variant="contained"
            sx={{
               ...StyledButton,
               // ...(isActive && StyledButton['&.active']),
               fontSize: '16px', color: 'black'
            }}
            
            
         >
            <IoLogOutOutline size={25} color='rgb(205, 164, 7)'/>
            Чыгуу
         </Button>
            )
         }

         <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="logout-dialog-title"
            aria-describedby="logout-dialog-description"
         >
            <DialogTitle id="logout-dialog-title">Чыгууну ырастоо</DialogTitle>
            <DialogContent>
               <DialogContentText id="logout-dialog-description">
                  Сиз чын эле чыгууну каалайсызбы?
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} sx={{ color: 'rgb(3, 22, 52)' }}>
                  Жок
               </Button>
               <Button
                  onClick={handleConfirmLogout}
                  variant="contained"
                  sx={{
                     backgroundColor: 'rgb(3, 22, 52)',
                     color: 'rgb(205, 164, 7)',
                     '&:hover': {
                        backgroundColor: 'rgb(3, 22, 52, 0.9)',
                     },
                  }}
               >
                  Ооба
               </Button>
            </DialogActions>
         </Dialog>
         <Snackbar
            open={openSnackbar}
            autoHideDuration={2000}
            message="Чыгууда..."
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
         />
      </>
   )
}

export default LogoutButton
