import { useState, useEffect } from 'react'
import ChoosePhoto from './ChoosePhoto'
import { Stack, Typography } from '@mui/material'
import Input from './Input'
import ButtonComponent from '../../ui/Button'
import SendNotificationSuccess from '../../ui/SendNotificationSuccess'
import SendNotificationFail from '../../ui/SendNotificationFail'
import * as SC from '../../../pages/admin/AdminSections.style'
import {
   useCreatePartnerMutation,
   useUpdatePartnerMutation,
} from '../../../store/features/partnersSlice'
import { useNavigate } from 'react-router-dom'

const PartnersForms = ({ initialData, isEditMode }) => {
   const navigate = useNavigate()
   const [isOpen, setIsOpen] = useState(false)
   const handleClose = () => setIsOpen(false)
   const [errors, setErrors] = useState({})
   const [logo, setLogo] = useState(initialData?.logo || '')
   const [name, setName] = useState('')

   useEffect(() => {
      if (initialData) {
         setName(initialData.name || '')
         setLogo(initialData.logo || '')
      }
   }, [initialData])

   const [addPartners, { error: addPartnerError }] = useCreatePartnerMutation()
   const [updatePartners, { error: updatePartnersError }] =
      useUpdatePartnerMutation()

   const handleInputChange = (e) => {
      setName(e.target.value)
      setErrors((prevErrors) => ({
         ...prevErrors,
         name: !e.target.value ? 'This field is required' : '',
      }))
   }

   const handlePhotoChange = (selectedPhoto) => {
      setLogo(selectedPhoto)
      setErrors((prevErrors) => ({
         ...prevErrors,
         logo: selectedPhoto ? '' : 'Сүрөттү тандаңыз',
      }))
   }

   const resetForm = () => {
      setName('')
      setLogo('')
   }
   const validateForm = () => {
      const newErrors = {}
      if (!name) newErrors.name = 'Компаниянын аты жазылыш керек!'
      if (!logo) newErrors.logo = 'Cуротту танданыз'

      setErrors(newErrors)
      return Object.keys(newErrors).length === 0
   }

   const handleSubmit = async () => {
      if (!validateForm()) return
      try {
         const newData = {
            name,
            logo,
         }

         if (isEditMode && initialData) {
            await updatePartners({ id: initialData.id, ...newData }).unwrap()
         } else {
            await addPartners(newData).unwrap()
         }

         setIsOpen(true)
         resetForm()
      } catch (error) {
         console.error('Error:', error)
         setIsOpen(true)
      }
   }
   return (
      <Stack width="100%" mb="40px">
         <ChoosePhoto
            photo={logo}
            onPhotoChange={handlePhotoChange}
            isEditMode={isEditMode}
         />
         {errors.logo && (
            <Typography color="error" variant="caption">
               {errors.logo}
            </Typography>
         )}
         <Stack spacing={3} m="25px" boxSizing={'border-box'}>
            <Input
               title="Компаниянын аты"
               placeholder={'Apple'}
               onChange={handleInputChange}
               value={name}
               error={Boolean(errors.name)}
               helperText={errors.name}
            />
            <SC.ButtonBox
               maxWidth={500}
               display={'flex'}
               justifyContent={'end'}
            >
               <ButtonComponent handleClick={handleSubmit}>
                  {isEditMode ? 'Өзгөртүү ' : 'Кошуу'}
               </ButtonComponent>
            </SC.ButtonBox>
         </Stack>
         <SendNotificationSuccess
            open={isOpen}
            onClose={handleClose}
            text={
               isEditMode
                  ? 'Маалымат ийгиликтүү өзгөртүлдү!'
                  : 'Маалымат ийгиликтүү кошулду!'
            }
            onNavigate={() => navigate('/admin-partners')}
         />
         {(addPartnerError || updatePartnersError) && (
            <SendNotificationFail
               open={isOpen}
               onClose={handleClose}
               text={(addPartnerError || updatePartnersError)?.data?.message}
            />
         )}
      </Stack>
   )
}

export default PartnersForms
