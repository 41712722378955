import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { initialData } from '../../../constants/data'
import { EditItem } from '../../../components/admin/adminOpinions/EditItem'
import { DeleteItem } from '../../../components/admin/adminOpinions/DeleteItem'
import { VievItem } from '../../../components/admin/adminOpinions/Viev'
import { CustomCheckbox } from '../../../components/admin/adminOpinions/Checkbox'
import DeleteItemSuccess from '../../../components/ui/SendNotificationSuccess'

const Opinions = () => {
   const [data, setData] = useState(initialData)
   const [deletItemSuccess, setDeleteItemSuccess] = useState(false)

   const handleDelete = (id) => {
      const updatedData = data.filter((item) => item.id !== id)
      setData(updatedData)
      setDeleteItemSuccess(true)
   }

   const handleModalClose = () => {
      setDeleteItemSuccess(false)
   }

   return (
      <Box
         sx={{
            margin: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            paddingBottom: '50px',
         }}
      >
         <Box
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               marginTop: '30px',
               marginBottom: '30px',
            }}
         >
            <p
               style={{
                  fontWeight: 600,
                  fontSize: '36px',
                  lineHeight: '44px',
               }}
            >
               Пикирлер
            </p>
         </Box>

         <DeleteItemSuccess
            open={deletItemSuccess}
            onClose={handleModalClose}
            text={'Пикир тизмеден өчүрүлдү!'}
         />
         {data.map((item) => (
            <Box
               key={item.id}
               sx={{
                  padding: 0.5,
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  width: 924,
                  height: 60,
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Grid container spacing={4} alignItems="flex-start">
                  <Grid item xs={8}>
                     <Typography variant="h6" sx={{ textAlign: 'left' }}>
                        {item.number} {item.name}
                     </Typography>
                  </Grid>
                  <Grid
                     item
                     xs={4}
                     container
                     justifyContent="flex-end"
                     spacing={1}
                  >
                     <Box
                        item
                        sx={{
                           width: '40px',
                           height: '40px',
                           borderRadius: '5px',
                           boxShadow:
                              'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                           background: 'rgb(255, 255, 255)',
                           marginTop: '8px',
                           marginLeft: '20px',
                        }}
                     >
                        <VievItem item={item} />
                     </Box>
                     <Grid
                        item
                        sx={{
                           position: 'relative',
                           left: '40px',
                           width: '40px',
                           height: '40px',
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           marginLeft: '30px',
                           marginTop: '5px',
                        }}
                     >
                        <CustomCheckbox item={item} />
                     </Grid>
                     <Box
                        item
                        sx={{
                           width: '40px',
                           height: '40px',
                           borderRadius: '5px',
                           boxShadow:
                              'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                           background: 'rgb(255, 255, 255)',
                           marginTop: '8px',
                           marginLeft: '20px',
                        }}
                     >
                        <EditItem item={item} setData={setData} />
                     </Box>
                     <Box
                        item
                        sx={{
                           width: '40px',
                           height: '40px',
                           borderRadius: '5px',
                           boxShadow:
                              'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                           background: 'rgb(255, 255, 255)',
                           marginTop: '8px',
                           marginLeft: '20px',
                           color: 'black',
                        }}
                     >
                        <DeleteItem
                           item={item}
                           onDelete={(id) => handleDelete(id)}
                           style={{ color: 'red' }}
                        />
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         ))}
      </Box>
   )
}

export default Opinions
