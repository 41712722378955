import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import techLogo from '../assets/frontend-info.png';


const FrontendContainer = styled(Container)(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  padding: '20px',
  margin: '0 auto',
  textAlign: 'left',
  maxWidth: '1400px',
  fontWeight: 500,
}));

const FrontendNav = styled('nav')({
  fontSize: '18px',
  color: '#666',
  marginBottom: '20px',
});

const FrontendIcon = styled('div')({
  float: 'left', 
  width: '50%', 
  height: 'auto',
  marginBottom: '10px',
  marginRight: '20px', 
});

const FrontendDescription = styled('div')({
  textAlign: 'justify',
  fontSize: '20px',
  fontWeight: 500,
  color: 'rgb(0, 0, 0)',
  display: 'block', 
});

const CatalogButton = styled(Button)(({ theme }) => ({
  display: 'block',
  width: '150px',
  height: '48px',
  margin: '30px auto',
  backgroundColor: 'rgb(3, 22, 52)',
  color: 'white',
  fontSize: '18px',
  borderRadius: '15px',
  '&:hover': {
    backgroundColor: '#053f7d',
  },
}));

const FrontendInfo = () => {
  return (
    <FrontendContainer>
      <FrontendNav>
        <Link href="#" color="inherit">Багыттар</Link> &gt; <Link href="#" color="inherit">Frontend</Link>
      </FrontendNav>

      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h1" fontSize="48px" fontWeight={500}>
            Frontend
          </Typography>
        </Grid>
        
       
        <Grid item xs={12}>
          <FrontendIcon>
            <img src={techLogo} alt="frontend icon" style={{ width: '100%', height: 'auto' }} />
          </FrontendIcon>
          <FrontendDescription>
            <Typography paragraph>
              Фронтэнд (англ. front end, frontend) — презентационная часть
              информационной или программной системы, её пользовательский
              интерфейс и связанные с ним компоненты; применяется в
              соотношении с базисной частью системы, её внутренней
              реализацией, называемой в этом случае бэкенд (англ. backend).
            </Typography>
            <Typography paragraph>
              Разделение программных систем на фронтенд и бэкенд — одно из
              стандартных решений для архитектуры программного обеспечения,
              связанное в программной инженерии с принципом разделения
              ответственности между внешним представлением и внутренней
              реализацией. Как правило, бэкенд реализует API, используемые
              фронтендом, и таким образом фронтенд-разработчику не нужно
              знать особенности реализации внутренней части, а
              бэкенд-разработчику — интерфейсные решения. Кроме того, такое
              разделение позволяет использовать различные инструменты для
              реализации внутренней и внешней части системы, более
              эффективные для соответствующих задач. Например, в
              веб-разработке к технологиям фронтенда относятся HTML, CSS,
              JavaScript; а бэкенд может быть реализован на PHP, Java,
              Python, JavaScript (Node.js).
            </Typography>
            <Typography paragraph>
              Иногда под бэкендом в контексте информационных систем
              понимается административная часть системы (включая интерфейс),
              не отображаемая конечному пользователю, в частности, такая
              терминология используется в системах управления содержимым (в
              этом случае разделение ближе к используемому в сфере
              организационного управления, где бэк-офис отвечает за
              административные функции, а фронт-офис — за непосредственную
              работу с заказчиками). Профессия фронтенд-разработчика — одна
              из самых востребованных в IT. Прямо сейчас на hh.ru
              опубликовано почти 3 500 вакансий для фронтендеров.
            </Typography>
          </FrontendDescription>
        </Grid>
      </Grid>

      <CatalogButton variant="contained">Катталуу</CatalogButton>
    </FrontendContainer>
  );
};

export default FrontendInfo;
