import React, { useEffect, useState } from 'react'
import {
   Box,
   Typography,
   Checkbox,
   Button,
   useTheme,
   useMediaQuery,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import {
   useGetScheduleQuery,
   useSetScheduleMutation,
} from '../../store/features/scheduleApi'

export default function ScheduleTable() {
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

   const { data, isLoading, error } = useGetScheduleQuery()
   // console.log(data, 'data query')

   const [setScheduleMutation] = useSetScheduleMutation()
   const [editedSchedule, setEditedSchedule] = useState([])

   useEffect(() => {
      if (data) {
         setEditedSchedule(data)
      }
   }, [data])

   // Обработчик изменения состояния checkbox
   const handleCheckboxChange = (directionID, day, checked) => {
      setEditedSchedule((prevSchedule) =>
         prevSchedule.map((scheduleItem) =>
            scheduleItem.id === directionID
               ? {
                    ...scheduleItem,
                    days: {
                       ...scheduleItem.days,
                       [day]: checked,
                    },
                 }
               : scheduleItem
         )
      )
   }

   const handleSaveChanges = async () => {
      try {
         for (const scheduleItem of editedSchedule) {
            const payload = {
               directionID: scheduleItem.id,
               days: scheduleItem.days,
            }
            console.log(payload, 'payload log')

            await setScheduleMutation(payload).unwrap()
         }
         console.log('Changes are succesfully changed!')
      } catch (error) {
         console.error('Error in post request', error)
      }
   }

   if (isLoading) return <Typography>Жүктөө...</Typography>
   if (error) return <Typography>Расписаниени жүктөөдө ката чыкты</Typography>
   if (!data) return <Typography>Маалымат жок</Typography>

   const daysOfWeek = [
      'Дүйшөмбү',
      'Шейшемби',
      'Шаршемби',
      'Бейшемби',
      'Жума',
      'Ишемби',
   ]

   return (
      <Box sx={{ overflowX: 'auto', width: '100%' }}>
         <table
            style={{
               borderCollapse: 'collapse',
               width: '100%',
               minWidth: isMobile ? 'auto' : '600px',
            }}
         >
            <thead>
               <tr>
                  <th
                     style={{
                        background: 'rgb(196, 196, 196)',
                        border: '1px solid #000',
                        padding: '5px',
                     }}
                  ></th>
                  {daysOfWeek.map((day) => (
                     <th
                        key={day}
                        style={{
                           background: 'rgb(196, 196, 196)',
                           border: '1px solid #000',
                           padding: '10px',
                           textAlign: 'center',
                           verticalAlign: 'middle',
                           height: isMobile ? '80px' : '127px',
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: {
                                 xs: '14px',
                                 sm: '16px',
                                 md: '20px',
                              },
                              fontWeight: '600',
                           }}
                        >
                           {day}
                        </Typography>
                        {!isMobile && (
                           <Typography
                              sx={{
                                 fontSize: { sm: '14px', md: '20px' },
                                 fontWeight: '600',
                              }}
                           >
                              (9:00-17:00)
                           </Typography>
                        )}
                     </th>
                  ))}
               </tr>
            </thead>
            <tbody>
               {editedSchedule.map((scheduleItem) => (
                  <tr key={scheduleItem.id}>
                     <td
                        style={{
                           border: '1px solid #000',
                           padding: '10px',
                           maxWidth: '150px',
                           height: '70px',
                           verticalAlign: 'middle',
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: {
                                 xs: '20px',
                                 sm: '16px',
                                 md: '20px',
                              },
                              textAlign: 'center',
                              fontWeight: '600',
                           }}
                        >
                           {scheduleItem.direction}
                        </Typography>
                        {isMobile && (
                           <Typography
                              sx={{
                                 fontSize: { xs: '12px', sm: '14px' },
                                 textAlign: 'center',
                                 fontWeight: 600,
                              }}
                           >
                              ({scheduleItem.startTime} - {scheduleItem.endTime}
                              )
                           </Typography>
                        )}
                     </td>
                     {daysOfWeek.map((day) => {
                        const checked = scheduleItem.days[day] === true
                        return (
                           <td
                              key={`${scheduleItem.id}-${day}`}
                              style={{
                                 border: '1px solid #000',
                                 padding: '10px',
                                 textAlign: 'center',
                                 verticalAlign: 'middle',
                                 height: '70px',
                              }}
                           >
                              <Checkbox
                                 checked={checked}
                                 onChange={(e) =>
                                    handleCheckboxChange(
                                       scheduleItem.id,
                                       day,
                                       e.target.checked
                                    )
                                 }
                                 icon={
                                    <Box
                                       sx={{ width: '20px', height: '20px' }}
                                    />
                                 }
                                 checkedIcon={
                                    <CheckIcon
                                       sx={{
                                          color: 'black',
                                          fontSize: {
                                             xs: '20px',
                                             sm: '24px',
                                             md: '28px',
                                          },
                                       }}
                                    />
                                 }
                                 sx={{
                                    width: {
                                       xs: '32px',
                                       sm: '40px',
                                       md: '48px',
                                    },
                                    height: {
                                       xs: '32px',
                                       sm: '40px',
                                       md: '48px',
                                    },
                                    '&:hover': {
                                       background: 'rgb(233, 233, 233)',
                                    },
                                    background: 'rgb(233, 233, 233)',
                                    borderRadius: '0',
                                    '& .MuiSvgIcon-root': {
                                       fontSize: { xs: 20, sm: 24, md: 28 },
                                    },
                                 }}
                              />
                           </td>
                        )
                     })}
                  </tr>
               ))}
            </tbody>
         </table>
         <Button onClick={handleSaveChanges}>Графикти сактоо</Button>
      </Box>
   )
}
