import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Container, Box, List, Typography, Button } from '@mui/material'

import PaginationComponent from './PaginationComponent'
import ViewEditNotificationModal from './ViewEditNotificationModal'
import SendNotificationSuccess from '../../ui/SendNotificationSuccess'
import DeleteNotificationModal from './DeleteNotificationModal'
import AddNotificationModal from '../../ui/AddNotificationModal'
import NotificationListItem from './NotificationListItem'

import {
   fetchNotificationsFromBackend,
   sendNotificationToBackend,
   deleteNotificationFromBackend,
   updateNotificationOnBackend,
} from './notification.api'

const IndexNotifications = () => {
   const [page, setPage] = useState(1)
   const [open, setOpen] = useState(false)
   const [viewOpen, setViewOpen] = useState(false)
   const [deleteOpen, setDeleteOpen] = useState(false)
   // eslint-disable-next-line no-unused-vars
   const [isRed, setIsRed] = useState(false)
   const [notifications, setNotifications] = useState([])
   const [confirmationOpen, setConfirmationOpen] = useState(false)

   const [newNotification, setNewNotification] = useState({
      type: [],
      subject: '',
      message: '',
   })

   const [selectedNotification, setSelectedNotification] = useState(null)

   // Новые состояния для редактирования
   const [editSubject, setEditSubject] = useState('')
   const [editMessage, setEditMessage] = useState('')
   const [editType, setEditType] = useState([])

   // Получаем уведомления с бэкенда при загрузке компонента
   useEffect(() => {
      const loadNotifications = async () => {
         try {
            const response = await fetchNotificationsFromBackend()
            const data = response.data.items
            // Проверяем, является ли data массивом
            if (Array.isArray(data)) {
               setNotifications(data)
            } else {
               console.error('Ошибка: данные не являются массивом:', data)
               setNotifications([]) // Устанавливаем пустой массив в случае ошибки
            }
         } catch (error) {
            console.error('Ошибка при загрузке уведомлений:', error)
         }
      }
      loadNotifications()
   }, [])

   const handleOpen = () => setOpen(true)
   const handleClose = () => setOpen(false)
   const handleViewOpen = () => setViewOpen(true)
   const handleViewClose = () => {
      setViewOpen(false)
      setSelectedNotification(null)
   }

   const handleDeleteOpen = (notification) => {
      setSelectedNotification(notification)
      setDeleteOpen(true)
      setIsRed(true)
   }
   const handleDeleteClose = () => {
      setDeleteOpen(false)
      setSelectedNotification(null)
      setIsRed(false)
   }

   const handlePageChange = (event, value) => {
      setPage(value)
   }

   const handleAddNotification = async () => {
      if (
         !newNotification.message.type.length ||
         !newNotification.message.subject ||
         !newNotification.message.message
      ) {
         alert('Пожалуйста, заполните все поля уведомления.')
         return
      }
      const currentDate = new Date().toLocaleDateString()
      const newNotif = {
         ...newNotification,
         id: uuidv4(),
         date: currentDate,
      }

      try {
         await sendNotificationToBackend(newNotif)
         setNotifications([...notifications, newNotif])
         setNewNotification({ type: [], subject: '', message: '' })
         handleClose()
      } catch (error) {
         console.error('Ошибка при отправке уведомления:', error)
      }
   }

   const handleViewNotification = (notification) => {
      setSelectedNotification(notification)
      setEditSubject(notification.message.subject || '')
      setEditMessage(notification.message.message || '')
      setEditType(notification.message.type || [])
      handleViewOpen()
   }

   const handleDeleteNotification = async () => {
      if (selectedNotification) {
         try {
            await deleteNotificationFromBackend(selectedNotification.id)
            setNotifications((prevNotifications) =>
               prevNotifications.filter(
                  (notification) => notification.id !== selectedNotification.id
               )
            )
            handleDeleteClose(true)
            setConfirmationOpen(true)
         } catch (error) {
            console.error('Ошибка при удалении уведомления:', error)
         }
      }
   }

   const handleConfirmationClose = () => {
      setConfirmationOpen(false)
   }

   const handleSaveChanges = async () => {
      if (selectedNotification) {
         const updatedNotif = {
            ...selectedNotification,
            message: {
               ...selectedNotification.message,
               subject: editSubject,
               message: editMessage,
               type: editType,
            },
         }

         try {
            const result = await updateNotificationOnBackend(updatedNotif)

            if (result.success) {
               // Обновляем состояние с новыми данными
               setNotifications((prevNotifications) =>
                  prevNotifications.map((notification) =>
                     notification.id === updatedNotif.id
                        ? result.data
                        : notification
                  )
               )

               // Возвращаем успешность
               return { success: true }
            } else {
               console.error(
                  'Не удалось обновить уведомление на сервере:',
                  result.error
               )
               return { success: false }
            }
         } catch (error) {
            console.error('Ошибка при обновлении уведомления:', error)
            return { success: false }
         }
      }
   }

   // Функция для обработки обновления уведомления

   const notificationsPerPage = 10
   const displayedNotifications = notifications.slice(
      (page - 1) * notificationsPerPage,
      page * notificationsPerPage
   )

   return (
      <Container>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               padding: '10px',
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
               }}
            >
               {/* Контейнер для текста */}
               <Typography
                  variant="h5"
                  gutterBottom
                  fontWeight={600}
                  fontSize={'36px'}
                  sx={{
                     flexGrow: 1,
                     textAlign: 'center',
                  }}
               >
                  Кулактандыруу
               </Typography>

               {/* Кнопка */}
               <Button
                  variant="contained"
                  sx={{
                     width: '150px',
                     height: '40px',
                     fontWeight: '600',
                     fontSize: '16px',
                     backgroundColor: 'rgb(205, 164, 7)',
                     color: 'black',
                  }}
                  onClick={handleOpen}
               >
                  Кошуу
               </Button>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', alignItems: 'center' }}>
               <List
                  sx={{
                     mt: '2px',
                     padding: ' 0 1px',
                     borderRadius: '8px',
                  }}
               >
                  {displayedNotifications.map((notification, index) => (
                     <NotificationListItem
                        key={notification.id}
                        notification={notification}
                        index={index}
                        handleDeleteOpen={handleDeleteOpen}
                        handleViewNotification={handleViewNotification}
                        notificationsPerPage={notificationsPerPage}
                        page={page}
                     />
                  ))}
               </List>
            </Box>

            {notifications.length > 9 && (
               <PaginationComponent
                  count={Math.ceil(notifications.length / notificationsPerPage)}
                  page={page}
                  onPageChange={handlePageChange}
               />
            )}
         </Box>

         <AddNotificationModal
            open={open}
            handleClose={handleClose}
            onAddNotification={handleAddNotification}
         />
         <ViewEditNotificationModal
            open={viewOpen}
            handleViewClose={handleViewClose}
            selectedNotification={selectedNotification}
            editSubject={editSubject}
            setEditSubject={setEditSubject}
            editMessage={editMessage}
            setEditMessage={setEditMessage}
            handleSaveChanges={handleSaveChanges}
         />

         <DeleteNotificationModal
            deleteOpen={deleteOpen}
            handleDeleteClose={handleDeleteClose}
            handleDeleteNotification={handleDeleteNotification}
            selectedNotification={selectedNotification}
         />

         <SendNotificationSuccess
            open={confirmationOpen}
            onClose={handleConfirmationClose}
            text={'Кулактандыруу тизмеден өчүрүлдү!'}
         />
      </Container>
   )
}

export default IndexNotifications
