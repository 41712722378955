import React, { useState } from 'react'
import {
   Typography,
   Paper,
   Grid,
   IconButton,
   Container,
   useTheme,
   useMediaQuery,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import ScheduleTable from '../../components/admin/AdminScheduleTable'
import AddNotificationModal from '../../components/ui/AddNotificationModal'
import { sendNotificationToBackend } from '../../components/admin/notify_admin/notification.api'

export default function AdminDashboard() {
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
   const [openModal, setOpenModal] = useState(false)
   const [notifications, setNotifications] = useState([])

   const [newNotification, setNewNotification] = useState({
      type: [],
      subject: '',
      message: '',
   })

   const handleAddNotification = async () => {
      if (
         !newNotification.message.type.length ||
         !newNotification.message.subject ||
         !newNotification.message.message
      ) {
         alert('Пожалуйста, заполните все поля уведомления.')
         return
      }
      const currentDate = new Date().toLocaleDateString()
      const newNotif = {
         ...newNotification,
         id: uuidv4(),
         date: currentDate,
      }

      try {
         await sendNotificationToBackend(newNotif)
         setNotifications([...notifications, newNotif])
         setNewNotification({ type: [], subject: '', message: '' })
         handleCloseModal()
      } catch (error) {
         console.error('Ошибка при отправке уведомления:', error)
      }
   }

   const handleOpenModal = () => setOpenModal(true)
   const handleCloseModal = () => setOpenModal(false)

   return (
      <Container
         maxWidth="lg"
         sx={{ p: { xs: '20px', sm: '30px', md: '46px 20px' } }}
      >
         <AddNotificationModal
            open={openModal}
            handleClose={handleCloseModal}
            onAddNotification={handleAddNotification}
         />
         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
               <Paper
                  elevation={0}
                  sx={{
                     height: { xs: '100px', sm: '120px' },
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     justifyContent: 'center',
                     bgcolor: 'rgb(249, 212, 70)',
                     borderRadius: '8px',
                     cursor: 'pointer',
                  }}
                  onClick={handleOpenModal}
               >
                  <IconButton size="large" sx={{ color: 'black', mb: 1 }}>
                     <NotificationsNoneIcon
                        sx={{ fontSize: { xs: '36px', sm: '48px' } }}
                     />
                  </IconButton>
                  <Typography
                     variant="h6"
                     sx={{
                        color: 'black',
                        fontWeight: '600',
                        fontSize: { xs: '16px', sm: '20px' },
                        textAlign: 'center',
                     }}
                  >
                     Кулактандыруу жиберүү
                  </Typography>
               </Paper>
            </Grid>

            <Grid
               item
               xs={12}
               sm={6}
               sx={{ mb: { xs: '40px', sm: '60px', md: '85px' } }}
            >
               <Paper
                  elevation={0}
                  sx={{
                     height: { xs: '100px', sm: '120px' },
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     justifyContent: 'center',
                     bgcolor: 'rgb(249, 212, 70)',
                     borderRadius: '8px',
                  }}
               >
                  <Typography
                     sx={{
                        fontWeight: '600',
                        fontSize: { xs: '36px', sm: '48px' },
                     }}
                  >
                     6
                  </Typography>
                  <Typography
                     variant="h6"
                     sx={{
                        color: 'black',
                        fontWeight: '600',
                        fontSize: { xs: '16px', sm: '20px' },
                        textAlign: 'center',
                     }}
                  >
                     Жаңы келген пикирлер
                  </Typography>
               </Paper>
            </Grid>
            <Grid item xs={12}>
               <ScheduleTable isMobile={isMobile} />
            </Grid>
         </Grid>
      </Container>
   )
}
