import React from 'react'
import { List, ListItem, ListItemText, styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

const StyledNavBar = styled('nav')(({ theme }) => ({
   backgroundColor: '#001b37',
   color: 'white',
   height: '100vh',
   padding: theme.spacing(2),
   display: 'flex',
   flexDirection: 'column',
   position: 'relative',
}))

const StyledList = styled(List)({
   width: '100%',
   padding: 0,
   position: 'sticky',
   top: 110,
})

const StyledListItem = styled(ListItem)({
   padding: 0,
   '& .MuiListItemText-root': {
      margin: 0,
   },
})

const StyledLink = styled(NavLink)(({ theme }) => ({
   color: 'white',
   textDecoration: 'none',
   margin: theme.spacing(1, 0),
   width: '100%',
   padding: theme.spacing(1, 2),
   '&.active': {
      fontWeight: 'bold',
      color: '#f0c100',
   },
}))

const NavBar = () => {
   return (
      <StyledNavBar>
         <StyledList>
            <StyledListItem>
               <StyledLink to="/admin">
                  <ListItemText primary="Башкы бет" />
               </StyledLink>
            </StyledListItem>
            <StyledListItem>
               <StyledLink to="/admin-directions">
                  <ListItemText primary="Багыттар" />
               </StyledLink>
            </StyledListItem>
            <StyledListItem>
               <StyledLink to="/admin-partners">
                  <ListItemText primary="Өнөктөштөр" />
               </StyledLink>
            </StyledListItem>
            <StyledListItem>
               <StyledLink to="/admin-mentors">
                  <ListItemText primary="Менторлор" />
               </StyledLink>
            </StyledListItem>
            <StyledListItem>
               <StyledLink to="/admin-opinions">
                  <ListItemText primary="Пикирлер" />
               </StyledLink>
            </StyledListItem>
            <StyledListItem>
               <StyledLink to="/admin-blogs">
                  <ListItemText primary="Блогдор" />
               </StyledLink>
            </StyledListItem>
            <StyledListItem>
               <StyledLink to="/admin-participants">
                  <ListItemText primary="Катышуучулар" />
               </StyledLink>
            </StyledListItem>
            <StyledListItem>
               <StyledLink to="/admin-notifications">
                  <ListItemText primary="Кулактандыруу" />
               </StyledLink>
            </StyledListItem>
         </StyledList>
      </StyledNavBar>
   )
}

export default NavBar
