import React, { useState } from 'react'
import {
  Box,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CancelIcon from '@mui/icons-material/Cancel'

export const VievItem = ({ item }) => {
  const [openView, setOpenView] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleViewClick = () => {
    setSelectedItem(item)
    setOpenView(true)
  };

  return (
    <Box>
      <Grid item>
        <IconButton onClick={handleViewClick}>
          <VisibilityIcon sx={{ color: openView && selectedItem?.id === item.id ? 'blue' : 'black' }} />
        </IconButton>
        <Dialog open={openView && selectedItem?.id === item.id} onClose={() => setOpenView(false)}>
          <DialogActions>
            <IconButton onClick={() => setOpenView(false)} style={{ marginLeft: 'auto' }}>
              <CancelIcon />
            </IconButton>
          </DialogActions>
          <DialogTitle variant='h4' textAlign="center">{item.name}</DialogTitle>
          <DialogContent sx={{
            margin:2
          }}>
            <DialogContentText>{item.text}</DialogContentText>
          </DialogContent>
        </Dialog>
      </Grid>
    </Box>
  )
}