import { useState, useEffect } from 'react'
import ChoosePhoto from './ChoosePhoto'
import { Stack, Typography } from '@mui/material'
import Input from './Input'
import ButtonComponent from '../../ui/Button'
import SendNotificationSuccess from '../../ui/SendNotificationSuccess'
import SendNotificationFail from '../../ui/SendNotificationFail'
import * as SC from '../../../pages/admin/AdminSections.style'
import {
   useAddDirectionMutation,
   useUpdateDirectionMutation,
} from '../../../store/features/apiSlice'
import { useNavigate } from 'react-router-dom'

const DirectionForm = ({ initialData, isEditMode }) => {
   const navigate = useNavigate()
   const [isOpen, setIsOpen] = useState(false)
   const handleClose = () => setIsOpen(false)
   const [errors, setErrors] = useState({})
   const [imageLink, setImageLink] = useState(initialData?.imageLink || '')
   const [formData, setFormData] = useState({
      color: '',
      name: '',
      description: '',
   })

   useEffect(() => {
      if (initialData) {
         setFormData({
            color: initialData.color || '',
            name: initialData.name || '',
            description: initialData.description || '',
         })
         setImageLink(initialData.imageLink || '')
      }
   }, [initialData])

   const [addDirection, { error: addDirectionError }] =
      useAddDirectionMutation()
   const [updateDirection, { error: updateDirectionError }] =
      useUpdateDirectionMutation()

   const handleInputChange = (key, value) => {
      setFormData((prevData) => ({
         ...prevData,
         [key]: value,
      }))
      setErrors((prevErrors) => ({
         ...prevErrors,
         [key]: !value ? 'This field is required' : '',
      }))
   }

   const handlePhotoChange = (selectedPhoto) => {
      setImageLink(selectedPhoto)
      setErrors((prevErrors) => ({
         ...prevErrors,
         imageLink: selectedPhoto ? '' : 'Сүрөттү тандаңыз',
      }))
   }

   const resetForm = () => {
      setFormData({ color: '', name: '', description: '' })
      setImageLink('')
   }
   const validateForm = () => {
      const newErrors = {}
      if (!formData.color) newErrors.color = 'Color is required'
      if (!formData.name) newErrors.name = 'Name is required'
      if (!formData.description)
         newErrors.description = 'Description is required'
      if (!imageLink) newErrors.imageLink = 'Cуротту танданыз'

      setErrors(newErrors)
      return Object.keys(newErrors).length === 0
   }
   
   const handleSubmit = async () => {
      if (!validateForm()) return
      try {
         const newData = {
            name: formData.name,
            imageLink,
            color: formData.color,
            description: formData.description,
         }

         if (isEditMode && initialData) {
            await updateDirection({ id: initialData.id, newData }).unwrap()
         } else {
            await addDirection(newData).unwrap()
         }

         setIsOpen(true)
         resetForm()
      } catch (error) {
         console.error('Error:', error)
         setIsOpen(true)
      }
   }
   return (
      <Stack width="100%" mb="40px">
         <ChoosePhoto
            photo={imageLink}
            onPhotoChange={handlePhotoChange}
            isEditMode={isEditMode}
         />
         {errors.imageLink && (
            <Typography color="error" variant="caption">
               {errors.imageLink}
            </Typography>
         )}
         <Stack spacing={3} m="25px" boxSizing={'border-box'}>
            <Input
               title="Тус"
               placeholder={'#ffffff'}
               onChange={(e) => handleInputChange('color', e.target.value)}
               value={formData.color}
               error={Boolean(errors.color)}
               helperText={errors.color}
            />
            <Input
               title="Багыт"
               placeholder={'Flutter'}
               onChange={(e) => handleInputChange('name', e.target.value)}
               value={formData.name}
               error={Boolean(errors.name)}
               helperText={errors.name}
            />
            <Input
               title="Маалымат"
               placeholder={'Text'}
               multiline
               onChange={(e) =>
                  handleInputChange('description', e.target.value)
               }
               value={formData.description}
               error={Boolean(errors.description)}
               helperText={errors.description}
            />
            <SC.ButtonBox
               maxWidth={500}
               display={'flex'}
               justifyContent={'end'}
            >
               <ButtonComponent handleClick={handleSubmit}>
                  {isEditMode ? 'Өзгөртүү ' : 'Кошуу'}
               </ButtonComponent>
            </SC.ButtonBox>
         </Stack>
         <SendNotificationSuccess
            open={isOpen}
            onClose={handleClose}
            text={
               isEditMode
                  ? 'Маалымат ийгиликтүү өзгөртүлдү!'
                  : 'Маалымат ийгиликтүү кошулду!'
            }
            onNavigate={()=>navigate("/admin-directions")}
         />
         {(addDirectionError || updateDirectionError) && (
            <SendNotificationFail
               open={isOpen}
               onClose={handleClose}
               text={(addDirectionError || updateDirectionError)?.data?.message}
               
            />
         )}
      </Stack>
   )
}

export default DirectionForm
