import React from 'react'
import AdminSectionItems from '../../../components/admin/AdminSectionItems'
import Button from '../../../components/ui/Button'
import * as SC from '../AdminSections.style'
import { useNavigate, useParams,Outlet } from 'react-router-dom'
import {
   useGetAllDirectionsDataAdminQuery,
   useDeleteDirectionsMutation,
} from '../../../store/features/apiSlice'
import SendNotificationSuccess from '../../../components/ui/SendNotificationSuccess'
import SendNotificationFail from '../../../components/ui/SendNotificationFail'
import { useState } from 'react'
const DirectionsPage = () => {
   const [openSuccessoOrFail, setOpenSuccessOrFail] = useState(false)
   const navigate = useNavigate()
   const { data } = useGetAllDirectionsDataAdminQuery()
   const [deleteDirections, { error: errorDeleteDirection }] =
      useDeleteDirectionsMutation()

   const handleDeleteDirections = async (id) => {
      try {
         await deleteDirections(id).unwrap()
         setOpenSuccessOrFail(true)
         console.log(id)
      } catch (error) {
         console.error('Failed to delete the blog:', error)
         setOpenSuccessOrFail(true)
      }
   }
   const params = useParams();
   if (params.id) return <Outlet />;

   return (
      <SC.StackItems>
         <SC.BoxItem>
            <SC.AdminSectionName>Багыттар</SC.AdminSectionName>
            <Button handleClick={() => navigate('/admin/add-directions')}>
               Кошуу
            </Button>
         </SC.BoxItem>
         {data?.map((direction, indexItem) => (
            <AdminSectionItems
               key={direction.id}
               numberItems={indexItem + 1}
               companyName={direction.name}
               fotoSrc={direction.imageLink}
               isDirections
               handleDelete={() => handleDeleteDirections(direction.id)}
               textForDeleteModal="Багытты тизмеден өчүрүү "
               editPathName={`/admin-directions/edit/${direction.id}`}
            />
         ))}
         <SendNotificationSuccess
            open={openSuccessoOrFail}
            onClose={() => setOpenSuccessOrFail(false)}
            text="Багыт тууралуу маалымат ийгиликтүү өчүрүлдү!"
         />
         {errorDeleteDirection && (
            <SendNotificationFail
               open={openSuccessoOrFail}
               onClose={() => setOpenSuccessOrFail(false)}
               text={errorDeleteDirection?.data?.message}
            />
         )}
      </SC.StackItems>
   )
}

export default DirectionsPage
