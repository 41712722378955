import React from 'react'
import { Button } from '@mui/material'

const ButtonComponent = ({ children, handleClick }) => {
   return (
      <Button
         color='black'
         onClick={handleClick}
         sx={{
            backgroundColor: 'rgb(205, 164, 7)',
            padding: '15px 40px',
            borderRadius: '8px',
            font: '600 24px Inter,sans-serif',
            // textTransform: 'none',
            maxWidth: '190px',
            width: '100%',
         }}
      >
         {children}
      </Button>
   )
}

export default ButtonComponent
