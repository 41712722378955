// src/components/admin/notify_admin/NotificationModal.jsx

import React, { useState } from 'react'
import {
   Modal,
   Box,
   Typography,
   IconButton,
   TextField,
   Button,
} from '@mui/material'
import CloseIcon from '../../../assets/svg/CloseIcon.svg' // Укажите путь к вашему иконке закрытия
import SendNotificationSuccess from '../../ui/SendNotificationSuccess'
import SendNotificationFail from '../../ui/SendNotificationFail'

const ViewEditNotificationModal = ({
   open,
   handleViewClose,
   selectedNotification,
   editSubject,
   setEditSubject,
   editMessage,
   setEditMessage,
   handleSaveChanges,
}) => {
   const [showFailNotificationsUpdate, setShowFailNotificationsUpdate] =
      useState(false)
   const [showSuccessNotificationsUpdate, setShowSuccessNotificationsUpdate] =
      useState(false)

   const handleUpdateNotification = async () => {
      try {
         const result = await handleSaveChanges()
         if (result.success) {
            setShowSuccessNotificationsUpdate(true)
            console.log(selectedNotification)
         } else {
            setShowFailNotificationsUpdate(true)
         }
      } catch (error) {
         console.error('Ошибка при обновлении уведомления:', error)
         setShowFailNotificationsUpdate(true)
      }
   }

   return (
      <>
         <Modal open={open} onClose={handleViewClose}>
            <Box
               sx={{
                  width: '828px',
                  height: '525px',
                  bgcolor: 'white',
                  borderRadius: '8px',
                  boxShadow: 24,
                  p: 4,
                  mx: 'auto',
                  mt: '5vh',
                  border: '1px solid #ccc',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
               }}
            >
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     mb: 2,
                  }}
               >
                  <Typography variant="h5" fontWeight={600}>
                     Кулактандыруу деталдары
                  </Typography>
                  <IconButton onClick={handleViewClose}>
                     <img
                        src={CloseIcon}
                        alt="Close"
                        style={{ width: 36, height: 36 }}
                     />
                  </IconButton>
               </Box>

               {selectedNotification?.message ? (
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        p: 2,
                        height: 'calc(100% - 60px)',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                           width: '12px',
                        },
                        '&::-webkit-scrollbar-track': {
                           background: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                           background: 'rgb(205, 164, 7)',
                           borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                           background: '#555',
                        },
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'rgb(205, 164, 7) #f1f1f1',
                     }}
                  >
                     {/* Получатели уведомления */}
                     <Typography
                        variant="body1"
                        fontSize="20px"
                        fontWeight={500}
                     >
                        <strong style={{ marginRight: '7%' }}>
                           Кулактандырууну алуучу:
                        </strong>
                        <strong style={{ fontSize: '16px', fontWeight: '500' }}>
                           {Array.isArray(selectedNotification.message.type)
                              ? selectedNotification.message.type.join(' - ')
                              : 'Нет получателей'}
                        </strong>
                     </Typography>

                     {/* Поле редактирования темы */}
                     <TextField
                        label="Тема"
                        fullWidth
                        value={editSubject}
                        onChange={(e) => setEditSubject(e.target.value)}
                     />

                     {/* Поле редактирования текста */}
                     <TextField
                        label="Текст"
                        fullWidth
                        multiline
                        rows={4}
                        value={editMessage}
                        onChange={(e) => setEditMessage(e.target.value)}
                     />

                     {/* Кнопка для сохранения изменений и отображение даты */}
                     <Box
                        mt={2}
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                        }}
                     >
                        <Typography variant="body1" fontSize={'20px'}>
                           <strong>Дата:</strong>{' '}
                           {new Date(
                              selectedNotification.created
                           ).toLocaleString('ru-KG', {
                              timeZone: 'Asia/Bishkek', // Установите часовой пояс Бишкек
                              hour: '2-digit', // Форматируем часы
                              minute: '2-digit', // Форматируем минуты
                              second: '2-digit', // Форматируем секунды (если необходимо)
                              year: 'numeric', // Форматируем год
                              month: '2-digit', // Форматируем месяц
                              day: '2-digit', // Форматируем день
                           })}
                        </Typography>
                        <Button
                           variant="contained"
                           color="primary"
                           onClick={handleUpdateNotification}
                           sx={{
                              backgroundColor: 'rgb(205, 164, 7)',
                              color: 'black',
                              fontWeight: '600',
                           }}
                        >
                           Өзгөртүүнү сактоо
                        </Button>
                     </Box>
                  </Box>
               ) : (
                  <Typography variant="body1" fontSize={'20px'}>
                     Данные уведомления отсутствуют
                  </Typography>
               )}
            </Box>
         </Modal>

         {/* Уведомление об успешном обновлении */}
         <SendNotificationSuccess
            open={showSuccessNotificationsUpdate}
            onClose={() => {
               setShowSuccessNotificationsUpdate(false)
               handleViewClose()
            }}
            text={'Кулактандыруу ийгиликтүү өзгөртүүлдү!'}
         />

         {/* Уведомление о неудаче при обновлении */}
         <SendNotificationFail
            open={showFailNotificationsUpdate}
            onClose={() => setShowFailNotificationsUpdate(false)}
            text={'Сураныч, кайра аракет кылыңыз!'}
         />
      </>
   )
}

export default ViewEditNotificationModal
