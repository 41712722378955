import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import './index.css'
import { AppProvider } from './router/AppProvider'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { GoogleOAuthProvider } from '@react-oauth/google'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
   <React.StrictMode>
      <Provider store={store}>
         <GoogleOAuthProvider clientId={process.env.CLIENT_ID}>
            <RouterProvider router={AppProvider} />
         </GoogleOAuthProvider>
      </Provider>
   </React.StrictMode>
)
