import axios from "axios";

// Функция для получения уведомлений с бэкенда
const fetchNotificationsFromBackend = async () => {
   try {
      const response = await axios.get('/api/send-notifications');
      return response; // axios уже возвращает JSON данные
   } catch (error) {
      console.error('Ошибка при загрузке уведомлений:', error);
      return [];
   }
};

// Функция для отправки нового уведомления на бэкенд
const sendNotificationToBackend = async (newNotif) => {
   try {
      const response = await axios.post('/api/send-notifications', {
         message: newNotif
      });
      alert('Уведомление отправлено на сервер');
      return response.data;
   } catch (error) {
      console.error('Ошибка при отправке уведомления, сохранено локально:', error);
      return newNotif; // Возвращаем уведомление для отображения
   }
};

// Функция для удаления уведомления с бэкенда
const deleteNotificationFromBackend = async (id) => {
   try {
      await axios.delete(`/api/send-notifications/${id}`);
   } catch (error) {
      console.error('Ошибка при удалении уведомления, удалено локально:', error);
      // Если сервер недоступен, удаляем уведомление из localStorage
      
   }
};

// Функция для обновления уведомления на бэкенде
const updateNotificationOnBackend = async (updatedNotif) => {
   try {
      const response = await axios.patch(`/api/send-notifications/${updatedNotif.id}`, {
         message: updatedNotif.message
      });

      if (response.status === 200 || response.status === 204) {
         // Операция успешна
         return { success: true, data: response.data };
      } else {
         console.error("Неудачный статус ответа:", response.status);
         return { success: false, error: `Статус ответа: ${response.status}` };
      }
   } catch (error) {
      console.log("id записи:", updatedNotif.id);
      console.error("Ошибка при обновлении на сервере:", error);
      return { success: false, error: error.message }; // Возвращаем объект с флагом ошибки и сообщением
   }
};




export {
   fetchNotificationsFromBackend,
   sendNotificationToBackend,
   deleteNotificationFromBackend,
   updateNotificationOnBackend
};
