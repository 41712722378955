import React, { useEffect, useState } from 'react'
import Flickity from 'react-flickity-component'
import styled from 'styled-components'
import { useGetReviewQuery } from '../store/features/reviewApi'
import reviewImg from '../assets/imgs/reviewImg.png'
import { Typography, styled as style } from '@mui/material'

export const ReviewsSlider = () => {
   const { data } = useGetReviewQuery()
   const [reviews, setReviews] = useState([])
   console.log(reviews)

   useEffect(() => {
      if (data && data.reviews) {
         setReviews(data.reviews)
      }
   }, [data])

   const flickityOptions = {
      initialIndex: 1,
      wrapAround: true,
   }

   return (
      <div style={{ overflow: 'hidden', marginBottom: '150px' }}>
         <TitleStyle sx={{ fontSize: '48px', textAlign: 'center' }}>
            Пикирлер
         </TitleStyle>
         <Slider
            className="Slider"
            elementType="div"
            disableImagesLoaded={false}
            options={flickityOptions}
            reloadOnUpdate
            static
         >
            {reviews?.map((review) => {
               return review?.okey === true ? (
                  <Plate key={review.id} className="Plate">
                     <div className="about">
                        <img
                           src={reviewImg}
                           alt="reviewPhoto"
                           width="120px"
                           height="120px"
                           style={{ borderRadius: '50%' }}
                        />
                        <p>{review.fullName}</p>
                     </div>
                     <p>{review.text}</p>
                  </Plate>
               ) : null
            })}
         </Slider>
      </div>
   )
}

const TitleStyle = styled(Typography)({
   fontFamily: 'Inter',
   fontWeight: 500,
   fontSize: '48px',
})

const Slider = styled(Flickity)`
   outline: none;
   position: relative;
   padding-top: 100px;

   > button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 4.16vw;
      height: 4.16vw;
      border-radius: 50%;
      box-shadow: 0px 1px 43px 0px rgba(0, 0, 0, 0.25);
      background: rgb(255, 255, 255);
      border: none;
      padding: 0.694vw;
      min-width: 30px;
      min-height: 30px;
      cursor: pointer;
      > * {
        
      }
   }

   > .flickity-prev-next-button.previous {
      left: 4vw;
   }

   > .flickity-prev-next-button.next {
      right: 4vw;
   }
`

const Plate = styled.div`
   margin: 0 3vw;
   padding: 8vh 7vw;
   width: 52vw;
   min-width: 240px;
   height: 462px;
   overflow-y: auto;
   border-radius: 10px;
   box-shadow: 0px 1px 43px 0px rgba(0, 0, 0, 0.25);
   background: rgb(255, 255, 255);
   > .about {
      display: flex;
      align-items: center;
      gap: 25px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      > p {
         color: rgb(0, 0, 0);
         font-family: 'Inter';
         font-size: 24px;
         font-weight: 500;
         line-height: 29px;
         letter-spacing: 0%;
      }
   }
`
