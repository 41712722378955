import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import AboutUsListItems from '../components/about-us/AboutUsListItems'

export default function AboutUs() {
   return (
      <Container sx={{padding: "50px 0"}}>
         <Typography
            variant="h2"
            sx={{
               fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' },
               paddingTop: '15px',
               textAlign: 'center',
            }}
         >
            Биз тууралуу
         </Typography>
         <Box>
            <AboutUsListItems />
         </Box>
      </Container>
   )
}
