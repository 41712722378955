import React, { useRef, useState } from 'react'
import { Snackbar, Typography, Button } from '@mui/material'
import { IoImageOutline } from 'react-icons/io5'
import { styled } from '@mui/system'

const ImageUploadButton = styled('label')({
   backgroundColor: 'white',
   color: 'black',
   border: '1px solid #ccc',
   borderRadius: '8px',
   textTransform: 'none',
   padding: '10px 20px',
   fontSize: '14px',
   width: '220px',
   height: { xs: '36px', sm: '50px' },
   display: 'flex',
   alignItems: 'center',
   cursor: 'pointer',
   '&:hover': {
      backgroundColor: '#f0f0f0',
   },
   '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
   },
})

const HiddenInput = styled('input')({
   display: 'none',
})

const ImageUpload = ({ isEditing, onImageSelect }) => {
   const fileInputRef = useRef(null)
   const [snackbarOpen, setSnackbarOpen] = useState(false)
   const [snackbarMessage, setSnackbarMessage] = useState('')

   const handleFileSelect = (event) => {
      if (!isEditing) return

      const file = event.target.files[0]
      if (file) {
         if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            setSnackbarMessage('Сүрөт PNG же JPG форматында болушу керек.')
            setSnackbarOpen(true)
            return
         }
         if (file.size > 10 * 1024 * 1024) {
            setSnackbarMessage('Сүрөттүн өлчөмү 10 МБ дан ашпашы керек.')
            setSnackbarOpen(true)
            return
         }

         const img = new Image()
         img.onload = () => {
            if (img.width > 1000 || img.height > 1000) {
               setSnackbarMessage(
                  'Сүрөттүн өлчөмү 225x280 пикселден ашпашы керек.'
               )
               setSnackbarOpen(true)
            } else {
               onImageSelect(file)
            }
         }
         img.src = URL.createObjectURL(file)
      }
   }

   const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarOpen(false)
   }

   return (
      <>
         <ImageUploadButton disabled={!isEditing}>
            <IoImageOutline style={{ marginRight: '10px' }} />
            <Typography sx={{ fontSize: { xs: '14px', sm: '18px' } }}>
               Сүрөттү өзгөртүү
            </Typography>
            <HiddenInput
               ref={fileInputRef}
               type="file"
               accept="image/png, image/jpeg"
               onChange={handleFileSelect}
               disabled={!isEditing}
            />
         </ImageUploadButton>
         <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={
               <Button
                  color="secondary"
                  size="small"
                  onClick={handleSnackbarClose}
               >
                  OK
               </Button>
            }
         />
      </>
   )
}

export default ImageUpload
