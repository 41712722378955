import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import {
   useGetBlogsByIdQuery,
   useGetAllBlogsQuery,
} from '../../store/features/blogUserSlice'
import noImage from '../../assets/noImage.png'

const BlogPage = () => {
   const { id } = useParams()
   const { data: blog } = useGetBlogsByIdQuery(id)
   const { data } = useGetAllBlogsQuery(1)
   const [selectedBlog, setSelectedBlog] = useState(null)

   const handleBlogClick = (blog) => {
      setSelectedBlog(blog)
   }

   const typographyStyles = {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '20px',
      marginBottom: '16px',
   }

   console.log(selectedBlog)

   return (
      <Box
         sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '66px',
            maxWidth: '1440px',
            margin: '0 auto',
         }}
      >
         <Box sx={{ display: 'flex', width: '1227px', gap: '72px' }}>
            <Box sx={{ width: '830px', padding: '16px' }}>
               <Box>
                  <Typography
                     variant="h1"
                     sx={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 600,
                        fontSize: '36px',
                        lineHeight: '44px',
                        margin: 0,
                     }}
                  >
                     {selectedBlog ? selectedBlog.title : blog?.title}
                  </Typography>
                  <Typography
                     sx={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: 'rgb(85, 96, 113)',
                        padding: '30px 0px 0px 0px',
                     }}
                  >
                     {selectedBlog ? selectedBlog.createdAt : blog?.createdAt}
                  </Typography>
               </Box>

               <Box sx={{ marginTop: '20px' }}>
                  <Box
                     component="img"
                     src={
                        selectedBlog
                           ? selectedBlog.image?.startsWith(
                                'https://images-techjol.s3.amazonaws.com'
                             )
                              ? selectedBlog.image
                              : noImage
                           : blog?.image?.startsWith(
                                  'https://images-techjol.s3.amazonaws.com'
                               )
                             ? blog.image
                             : noImage
                     }
                     alt=""
                     sx={{
                        width: '683px',
                        height: '416px',
                        objectFit: 'cover',
                     }}
                  />
               </Box>

               <Box sx={{ marginTop: '20px' }}>
                  <Typography sx={typographyStyles}>
                     {selectedBlog ? selectedBlog.text : blog?.text}
                  </Typography>
               </Box>
            </Box>
            <Box sx={{ width: '400px', padding: '16px' }}>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: '36px',
                  }}
               >
                  {data?.blogs?.length > 0 &&
                     data.blogs
                        .filter((el) => el.id !== selectedBlog?.id)
                        .slice(-3)
                        .map((el) => (
                           <Link
                              to={`/blog/${el.id}`}
                              onClick={() => handleBlogClick(el)}
                              key={el.id}
                           >
                              <Box
                                 sx={{
                                    backgroundColor: 'rgb(246, 249, 251)',
                                 }}
                              >
                                 <Box
                                    component="img"
                                    src={
                                       el.image.startsWith(
                                          'https://images-techjol.s3.amazonaws.com'
                                       )
                                          ? el.image
                                          : noImage
                                    }
                                    alt=""
                                    sx={{
                                       width: '400px',
                                       height: '308px',
                                       borderRadius: '8px',
                                    }}
                                 />
                                 <Box
                                    sx={{
                                       padding: '25px',
                                       borderRadius: '8px',
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          fontFamily: 'Inter, sans-serif',
                                          fontWeight: 600,
                                          fontSize: '24px',
                                          lineHeight: '29px',
                                       }}
                                    >
                                       {el.title}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          fontFamily: 'Inter, sans-serif',
                                          fontWeight: 400,
                                          fontSize: '16px',
                                          lineHeight: '19px',
                                          color: 'rgb(85, 96, 113)',
                                          padding: '16px 0px 20px 0px',
                                       }}
                                    >
                                       {el.createdAt}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          fontFamily: 'Inter, sans-serif',
                                          fontWeight: 400,
                                          fontSize: '16px',
                                          lineHeight: '19px',
                                          color: 'rgb(85, 96, 113)',
                                       }}
                                    >
                                       {el.text}
                                    </Typography>
                                 </Box>
                              </Box>
                           </Link>
                        ))}
               </Box>
            </Box>
         </Box>
      </Box>
   )
}

export default BlogPage
