import React from 'react'
import {
   Container,
   Box,
   Typography,
   Grid2,
   FormControl,
   InputLabel,
   TextField,
   Button,
} from '@mui/material'

import logo1 from '../assets/imgs/mbank.png'
import logo2 from '../assets/imgs/Оденьги.png'
import logo3 from '../assets/imgs/optima.png'

const paymentsLogo = [{ img: logo1 }, { img: logo2 }, { img: logo3 }]

export const Payment = () => {
   return (
      <Container
         maxWidth={false}
         sx={{
            maxWidth: '790px',
            width: '100%',
            fontFamily: 'Inter',
            padding: '50px 10px',
         }}
      >
         <Typography
            variant="h2"
            component="h2"
            sx={{
               fontSize: {
                  xs: '30px',
                  sm: '38px',
                  md: '48px',
               },
               fontWeight: 400,
               textAlign: 'center',
               marginBottom: {
                  xs: '15px',
                  sm: '23px',
                  md: '32px',
               },
            }}
         >
            Төлөм
         </Typography>

         <Box
            component="form"
            sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'start',
               gap: '25px',
            }}
         >
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 1,
               }}
            >
               <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                     fontSize: {
                        xs: '12px',
                        sm: '16px',
                        md: '20px',
                     },
                     marginTop: {
                        xs: '5px',
                        md: '10px',
                     },
                  }}
               >
                  Карта ээсинин аты-жөнү:
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'start',
                  }}
               >
                  <TextField
                     label="Kamalidinov Bekbolot"
                     variant="outlined"
                     sx={{
                        width: {
                           xs: '258px',
                           sm: '339px',
                           md: '420px',
                        },
                        '& .MuiOutlinedInput-root': {
                           height: {
                              xs: '40px',
                              sm: '45px',
                              md: '50px',
                           },
                        },
                        '& .MuiOutlinedInput-input': {
                           padding: '14px 12px',
                        },
                        '& .MuiFormLabel-root': {
                           fontSize: {
                              xs: '10px',
                              sm: '12px',
                              md: '15px',
                           },
                        },
                     }}
                  />
                  <Typography
                     variant="span"
                     component="span"
                     sx={{
                        fontSize: {
                           xs: '9px',
                           sm: '12px',
                           md: '16px',
                        },
                        color: 'gray',
                        margin: '4px 0 0 15px',
                     }}
                  >
                     Латын тамгалары гана колдонулат
                     <span style={{ color: 'rgb(251, 6, 6)' }}>*</span>
                  </Typography>
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',

                  gap: 1,
               }}
            >
               <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                     fontSize: {
                        xs: '12px',
                        sm: '16px',
                        md: '20px',
                     },
                     marginTop: {
                        xs: '5px',
                        md: '10px',
                     },
                  }}
               >
                  Карта номери:
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'start',
                  }}
               >
                  <TextField
                     label="1234 5678 9123 4567"
                     variant="outlined"
                     sx={{
                        width: {
                           xs: '258px',
                           sm: '339px',
                           md: '420px',
                        },
                        '& .MuiOutlinedInput-root': {
                           height: {
                              xs: '40px',
                              sm: '45px',
                              md: '50px',
                           },
                        },
                        '& .MuiOutlinedInput-input': {
                           padding: '14px 12px',
                        },
                        '& .MuiFormLabel-root': {
                           fontSize: {
                              xs: '10px',
                              sm: '12px',
                              md: '15px',
                           },
                        },
                     }}
                  />
                  <Typography
                     variant="span"
                     component="span"
                     sx={{
                        fontSize: {
                           xs: '9px',
                           sm: '12px',
                           md: '16px',
                        },
                        color: 'gray',
                        margin: '4px 0 0 15px',
                     }}
                  >
                     16 цифра
                     <span style={{ color: 'rgb(251, 6, 6)' }}>*</span>
                  </Typography>
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',

                  gap: 1,
               }}
            >
               <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                     fontSize: {
                        xs: '12px',
                        sm: '16px',
                        md: '20px',
                     },
                     marginTop: {
                        xs: '5px',
                        md: '10px',
                     },
                  }}
               >
                  Төлөмгө жарактуу карталар:
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     gap: {
                        xs: '19px',
                        md: '47px',
                     },
                     height: {
                        xs: '40px',
                        sm: '45px',
                        md: '50px',
                     },
                  }}
               >
                  <Box>
                     <img src={logo1} alt="" />
                  </Box>
                  <Box>
                     <img src={logo2} alt="" />
                  </Box>
                  <Box>
                     <img src={logo3} alt="" />
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',

                  gap: 1,
               }}
            >
               <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                     fontSize: {
                        xs: '12px',
                        sm: '16px',
                        md: '20px',
                     },
                     marginTop: {
                        xs: '5px',
                        md: '10px',
                     },
                  }}
               >
                  CVV/CVC2:
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     gap: {
                        xs: '19px',
                        md: '47px',
                     },
                  }}
               >
                  <TextField
                     label="123"
                     variant="outlined"
                     sx={{
                        width: {
                           xs: '258px',
                           sm: '339px',
                           md: '420px',
                        },
                        '& .MuiOutlinedInput-root': {
                           height: {
                              xs: '40px',
                              sm: '45px',
                              md: '50px',
                           },
                        },
                        '& .MuiOutlinedInput-input': {
                           padding: '14px 12px',
                        },
                        '& .MuiFormLabel-root': {
                           fontSize: {
                              xs: '10px',
                              sm: '12px',
                              md: '15px',
                           },
                        },
                     }}
                  />
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',

                  gap: 1,
               }}
            >
               <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                     fontSize: {
                        xs: '12px',
                        sm: '16px',
                        md: '20px',
                     },
                     marginTop: '10px',
                  }}
               >
                  Жарактуу мөөнөтү:
               </Typography>
               <Box
                  sx={{
                     width: {
                        xs: '258px',
                        sm: '339px',
                        md: '420px',
                     },
                     display: 'flex',
                     gap: '15px',
                  }}
               >
                  <TextField
                     label="Айы"
                     variant="outlined"
                     sx={{
                        width: {
                           xs: '116px',
                           sm: '150px',
                           md: '192px',
                        },

                        '& .MuiOutlinedInput-root': {
                           height: {
                              xs: '40px',
                              sm: '45px',
                              md: '50px',
                           },
                        },
                        '& .MuiOutlinedInput-input': {
                           padding: '14px 12px',
                        },
                        '& .MuiFormLabel-root': {
                           fontSize: {
                              xs: '10px',
                              sm: '12px',
                              md: '15px',
                           },
                        },
                     }}
                  />
                  <span
                     style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '26px',
                        fontSize: {
                           xs: '20px',
                           sm: '23px',
                           md: '26px',
                        },
                        fontWeight: 700,
                     }}
                  >
                     /
                  </span>
                  <TextField
                     sx={{
                        width: {
                           xs: '116px',
                           sm: '150px',
                           md: '192px',
                        },

                        '& .MuiOutlinedInput-root': {
                           height: {
                              xs: '40px',
                              sm: '45px',
                              md: '50px',
                           },
                        },
                        '& .MuiOutlinedInput-input': {
                           padding: '14px 12px',
                        },
                        '& .MuiFormLabel-root': {
                           fontSize: {
                              xs: '10px',
                              sm: '12px',
                              md: '15px',
                           },
                        },
                     }}
                     label="Жылы"
                     variant="outlined"
                  />
               </Box>
            </Box>{' '}
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',

                  gap: 1,
               }}
            >
               <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                     fontSize: {
                        xs: '12px',
                        sm: '16px',
                        md: '20px',
                     },
                     marginTop: '10px',
                  }}
               >
                  E-mail:
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     gap: '15px',
                  }}
               >
                  <TextField
                     sx={{
                        width: {
                           xs: '258px',
                           sm: '339px',
                           md: '420px',
                        },

                        '& .MuiOutlinedInput-root': {
                           height: {
                              xs: '40px',
                              sm: '45px',
                              md: '50px',
                           },
                        },
                        '& .MuiOutlinedInput-input': {
                           padding: '14px 12px',
                        },
                        '& .MuiFormLabel-root': {
                           fontSize: {
                              xs: '10px',
                              sm: '12px',
                              md: '15px',
                           },
                        },
                     }}
                     variant="outlined"
                  />
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',

                  gap: 1,
               }}
            >
               <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                     fontSize: {
                        xs: '12px',
                        sm: '16px',
                        md: '20px',
                     },
                     marginTop: '10px',
                  }}
               >
                  Сумма:
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     gap: '15px',
                  }}
               >
                  <TextField
                     sx={{
                        width: {
                           xs: '258px',
                           sm: '339px',
                           md: '420px',
                        },
                        '& .MuiOutlinedInput-root': {
                           height: {
                              xs: '40px',
                              sm: '45px',
                              md: '50px',
                           },
                        },
                        '& .MuiOutlinedInput-input': {
                           padding: '14px 12px',
                        },
                        '& .MuiFormLabel-root': {
                           fontSize: {
                              xs: '10px',
                              sm: '12px',
                              md: '15px',
                           },
                        },
                     }}
                     variant="outlined"
                  />
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: {
                     xs: '5px',
                     sm: '15px',
                     md: '25px',
                  },
               }}
            >
               <Button
                  sx={{
                     width: {
                        xs: '144px',
                        md: '185px',
                     },
                     height: {
                        xs: '40px',
                        md: '50px',
                     },
                     background: 'rgb(3, 22, 52)',
                     color: 'rgb(205, 164, 7)',
                  }}
               >
                  Төлөө
               </Button>
            </Box>
         </Box>
      </Container>
   )
}
