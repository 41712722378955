import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMembers = createAsyncThunk(
   'members/fetchMembers',
   async ({ currentPage, pageSize }, { getState }) => {
      const token = getState().userAuth.token
      const response = await axios.get(
         `${process.env.REACT_APP_BASE_URL}/users/allUsers`,
         {
            params: { currentPage, pageSize },
            headers: { Authorization: `Bearer ${token}` },
         }
      )
      console.log(response.data)
      return response.data
   }
)

export const profileMembers = createAsyncThunk(
   'members/profileMembers',
   async (userId, { getState }) => {
      const token = getState().userAuth.token
      const response = await axios.get(
         `${process.env.REACT_APP_BASE_URL}/users/${userId}`,
         {
            headers: { Authorization: `Bearer ${token}` },
         }
      )
      return response.data
   }
)

export const deleteMember = createAsyncThunk(
   'members/deleteMember',
   async (userId, { getState }) => {
      const token = getState().userAuth.token
      await axios.delete(
         `${process.env.REACT_APP_BASE_URL}/users/${userId}/delete`,
         {
            headers: { Authorization: `Bearer ${token}` },
         }
      )
      return userId
   }
)

const membersSlice = createSlice({
   name: 'members',
   initialState: {
      members: [],
      totalPages: 0,
      currentPage: 1,
      searchTerm: '',
      selectedMember: null,
      isLoading: false,
      error: null,
      openViewModal: false,
      openDeleteModal: false,
   },
   reducers: {
      setSearchTerm: (state, action) => {
         state.searchTerm = action.payload
      },
      setCurrentPage: (state, action) => {
         state.currentPage = action.payload
      },
      setSelectedMember: (state, action) => {
         state.selectedMember = action.payload
      },
      setOpenViewModal: (state, action) => {
         state.openViewModal = action.payload
      },
      setOpenDeleteModal: (state, action) => {
         state.openDeleteModal = action.payload
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchMembers.pending, (state) => {
            state.isLoading = true
         })
         .addCase(fetchMembers.fulfilled, (state, action) => {
            state.isLoading = false
            state.members = action.payload.users
            state.totalPages = Math.ceil(action.payload.total / 5)
            state.error = null
         })
         .addCase(fetchMembers.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
         })
         .addCase(profileMembers.pending, (state) => {
            state.isLoading = true
         })
         .addCase(profileMembers.fulfilled, (state, action) => {
            state.isLoading = false
            state.selectedMember = action.payload
            state.error = null
         })
         .addCase(profileMembers.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
         })
         .addCase(deleteMember.fulfilled, (state, action) => {
            state.members = state.members.filter(
               (member) => member.id !== action.payload
            )
            state.openDeleteModal = false
         })
   },
})

export const {
   setSearchTerm,
   setCurrentPage,
   setSelectedMember,
   setOpenViewModal,
   setOpenDeleteModal,
} = membersSlice.actions

export default membersSlice.reducer
