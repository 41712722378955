import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
   Box,
   Grid,
   IconButton,
   InputAdornment,
   List,
   TextField,
   Typography,
   Pagination,
   Modal,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteIcon from '@mui/icons-material/Delete'
import ModalMember from './modal-member'
import DeleteMember from './delete-member'
import {
   fetchMembers,
   deleteMember,
   setSearchTerm,
   setCurrentPage,
   setSelectedMember,
   setOpenViewModal,
   setOpenDeleteModal,
} from '../../store/memberSlice'

const Members = () => {
   const dispatch = useDispatch()
   const {
      members,
      totalPages,
      currentPage,
      searchTerm,
      selectedMember,
      isLoading,
      openViewModal,
      openDeleteModal,
   } = useSelector((state) => state.members)

   useEffect(() => {
      dispatch(fetchMembers({ currentPage, pageSize: 5 }))
      if (members.length > 5 && currentPage === 1) {
         dispatch(setCurrentPage(2))
      }
   }, [dispatch, currentPage, members.length])

   const handleDeleteMember = async (userId) => {
      await dispatch(deleteMember(userId))
      dispatch(fetchMembers({ currentPage, pageSize: 5 }))
   }

   const handleOpen = (member) => {
      dispatch(setSelectedMember(member))
      dispatch(setOpenViewModal(true))
   }

   const handleClose = () => {
      dispatch(setSelectedMember(null))
      dispatch(setOpenViewModal(false))
   }

   const handleOpenDelete = (member) => {
      dispatch(setSelectedMember(member))
      dispatch(setOpenDeleteModal(true))
   }

   const handleCloseDelete = () => {
      dispatch(setSelectedMember(null))
      dispatch(setOpenDeleteModal(false))
   }

   const filteredMembers = members.filter((member) =>
      member.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
   )

   const handlePageChange = (event, value) => {
      dispatch(setCurrentPage(value))
   }

   return (
      <div style={{ width: '95%', margin: '20px auto' }}>
         <Box
            display="flex"
            alignItems="center"
            mb={5}
            sx={{
               justifyContent: 'space-between',
               width: '60%',
               marginLeft: 'auto',
               '@media (max-width: 1000px)': {
                  width: '100%',
               },
               '@media (max-width: 600px)': {
                  width: '100%',
                  flexDirection: 'column',
                  gap: '20px',
                  alignItems: 'start',
               },
            }}
         >
            <Typography
               sx={{
                  textAlign: 'center',
                  '@media (max-width: 600px)': {
                     textAlign: 'start',
                  },
               }}
               variant="h4"
            >
               Катышуучулар
            </Typography>
            <TextField
               variant="outlined"
               placeholder="Search"
               value={searchTerm}
               sx={{
                  width: '40%',
                  marginRight: '40px',
                  '@media (max-width: 600px)': {
                     width: '100%',
                  },
               }}
               onChange={(e) => dispatch(setSearchTerm(e.target.value))}
               InputProps={{
                  startAdornment: (
                     <InputAdornment position="start">
                        <SearchIcon />
                     </InputAdornment>
                  ),
               }}
            />
         </Box>

         <List>
            {isLoading ? (
               <Typography>Loading...</Typography>
            ) : (
               filteredMembers.map((item, index) => (
                  <Grid
                     container
                     key={item.id}
                     alignItems="center"
                     justifyContent="space-between"
                     sx={{
                        width: '90%',
                        padding: '10px 0',
                        borderRadius: '8px',
                        marginRight: '10px',
                        marginBottom: '20px',
                        boxSizing: 'border-box',
                        boxShadow: 'inset 0px 0px 4px 4px rgba(0, 0, 0, 0.25)',
                        background: 'rgb(255, 255, 255)}',
                     }}
                  >
                     <Grid
                        item
                        display={'flex'}
                        alignItems={'center'}
                        gap={6}
                        xs={6}
                        onClick={() => handleOpen(item)}
                        sx={{ cursor: 'pointer', paddingLeft: '20px' }}
                     >
                        <Typography>
                           {(currentPage - 1) * 5 + index + 1}
                        </Typography>
                        {item.direction && (
                           <img
                              src={item.direction}
                              alt={item.fullName || 'User image'}
                              style={{
                                 width: '50px',
                                 height: '50px',
                                 borderRadius: '50%',
                                 objectFit: 'cover',
                              }}
                              onError={(e) => {
                                 e.target.onerror = null
                                 e.target.src = 'https://via.placeholder.com/50'
                              }}
                           />
                        )}
                        <Typography>{item.fullName || 'No name'}</Typography>
                     </Grid>
                     <Grid item xs={1.5}>
                        <Box display="flex" justifyContent="space-around">
                           <IconButton
                              aria-label="view"
                              onClick={() => handleOpen(item)}
                              sx={{
                                 width: '40px',
                                 height: '40px',
                                 minWidth: '40px',
                                 minHeight: '40px',
                                 boxSizing: 'border-box',
                                 borderRadius: '5px',
                                 boxShadow:
                                    'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                 background: 'rgb(255, 255, 255)}',
                              }}
                           >
                              <RemoveRedEyeIcon />
                           </IconButton>
                           <IconButton
                              aria-label="delete"
                              onClick={() => handleOpenDelete(item)}
                              sx={{
                                 width: '40px',
                                 height: '40px',
                                 minWidth: '40px',
                                 minHeight: '40px',
                                 boxSizing: 'border-box',
                                 borderRadius: '5px',
                                 boxShadow:
                                    'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                 background: 'rgb(255, 255, 255)}',
                              }}
                           >
                              <DeleteIcon />
                           </IconButton>
                        </Box>
                     </Grid>
                  </Grid>
               ))
            )}
         </List>

         <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            sx={{
               display: 'flex',
               justifyContent: 'center',
               marginTop: '20px',
            }}
         />

         <Modal
            open={openViewModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <ModalMember handleClose={handleClose} member={selectedMember} />
         </Modal>

         <Modal
            open={openDeleteModal}
            onClose={handleCloseDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <DeleteMember
               handleCloseDelete={handleCloseDelete}
               handleDeleteMember={() => handleDeleteMember(selectedMember?.id)}
               member={selectedMember}
            />
         </Modal>
      </div>
   )
}

export default Members
