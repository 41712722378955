import React, { useState, useEffect } from 'react'
import {
   Box,
   Button,
   Container,
   InputBase,
   Modal,
   Typography,
} from '@mui/material'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import {
   usePostImageMutation,
   useUpdateMentorMutation,
   useGetMentorSliceQuery,
} from '../../store/features/mentorSlice'
import CircularProgress from '@mui/material/CircularProgress'
import ProfilePicture from '../../assets/imgs/amdin-profile-picture.svg'
import { useParams } from 'react-router-dom'
import SendNotificationSuccess from '../ui/SendNotificationSuccess'

const EditMentor = () => {
   const [open, setOpen] = useState(false)
   const [isUploading, setIsUploading] = useState(false)
   const params = useParams()
   const { data: mentorData } = useGetMentorSliceQuery(params.id)

   // Initialize state with mentorData values
   const [imageUrl, setImageUrl] = useState(ProfilePicture)
   const [fullName, setFullName] = useState('')
   const [specialization, setSpecialization] = useState('')

   const [postImage] = usePostImageMutation()
   const [updateMentor] = useUpdateMentorMutation()

   // Update state when mentorData changes
   useEffect(() => {
      if (mentorData) {
         setImageUrl(mentorData.imageMentor || ProfilePicture)
         setFullName(mentorData.fullName || '')
         setSpecialization(mentorData.specialization || '')
      }
   }, [mentorData])

   const handleImageUpload = async (event) => {
      const file = event.target.files[0]
      if (!file) return

      setIsUploading(true)

      try {
         const formData = new FormData()
         formData.append('file', file)
         const response = await postImage(formData).unwrap()

         setImageUrl(response.link || response.url)
      } catch (error) {
         console.error('Failed to upload image:', error)
      } finally {
         setIsUploading(false)
      }
   }

   const handleSubmit = async () => {
      try {
         await updateMentor({
            id: params.id,
            imageMentor: imageUrl,
            fullName,
            specialization,
         }).unwrap()

         setOpen(true)
      } catch (error) {
         console.error('Failed to update mentor:', error)
      }
   }

   const handleClose = () => setOpen(false)

   const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 676,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: '20px',
      '@media (max-width: 750px)': {
         width: '90%',
      },
   }

   return (
      <Container>
         <Box
            sx={{
               padding: '30px 0',
               width: '945px',
               '@media (max-width: 850px)': {
                  width: '100%',
               },
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '15px',
                  marginBottom: '44px',
                  '@media (max-width: 600px)': {
                     flexDirection: 'column',
                     alignItems: 'center',
                  },
               }}
            >
               <Box
                  sx={{
                     background: 'rgb(205, 164, 7)',
                     padding: imageUrl === ProfilePicture ? '10px' : '0px',
                     width: '220px',
                     height: '220px',
                     position: 'relative',
                     borderRadius: '8px',
                     overflow: 'hidden',
                  }}
               >
                  {isUploading && (
                     <Box
                        sx={{
                           position: 'absolute',
                           top: '50%',
                           left: '50%',
                           transform: 'translate(-50%, -50%)',
                           zIndex: 2,
                        }}
                     >
                        <CircularProgress />
                     </Box>
                  )}
                  <Box
                     sx={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <img
                        style={{
                           opacity: isUploading ? 0.5 : 1,
                           width: '100%',
                           height: '100%',
                           objectFit: 'cover',
                           position: 'absolute',
                           objectPosition: 'top',
                           top: 0,
                           left: 0,
                        }}
                        src={imageUrl}
                        alt="Mentor"
                     />
                  </Box>
               </Box>
               <Box sx={{ position: 'relative', width: '219px' }}>
                  <InputBase
                     sx={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        cursor: 'pointer',
                     }}
                     type="file"
                     onChange={handleImageUpload}
                     inputProps={{ accept: 'image/*' }}
                  />
                  <Box
                     sx={{
                        color: 'rgb(77, 75, 75)',
                        border: '1px solid rgb(210, 210, 211)',
                        borderRadius: '8px',
                        background: 'rgb(255, 255, 255)',
                        boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        padding: '10px 6px',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '19px',
                        fontWeight: '400',
                        lineHeight: '26px',
                        zIndex: 0,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                        justifyContent: 'center',
                     }}
                  >
                     <ImageSearchIcon /> Cүрөт тандоо
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '50px',
                  '@media (max-width: 600px)': {
                     flexDirection: 'column',
                     alignItems: 'flex-start',
                     margin: '0 auto',
                  },
               }}
            >
               <Typography
                  sx={{
                     fontFamily: 'Istok Web, sans-serif',
                     fontSize: '24px',
                     fontWeight: '400',
                     lineHeight: '29px',
                     marginBottom: '10px',
                     '@media (max-width: 750px)': {
                        fontSize: '20px',
                     },
                  }}
               >
                  Ментордун аты-жөнү:
               </Typography>
               <InputBase
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  sx={{
                     borderRadius: '8px',
                     background: 'rgb(255, 255, 255)',
                     boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                     padding: '0 20px',
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '19px',
                     fontWeight: '400',
                     lineHeight: '26px',
                     width: '60%',
                     border: '1px solid grey',
                     '@media (max-width: 600px)': {
                        width: '100%',
                     },
                  }}
                  placeholder="Асан Усонов"
               />
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  '@media (max-width: 600px)': {
                     flexDirection: 'column',
                     alignItems: 'flex-start',
                     marginTop: '20px',
                  },
               }}
            >
               <Typography
                  sx={{
                     fontFamily: 'Istok Web, sans-serif',
                     fontSize: '24px',
                     fontWeight: '400',
                     lineHeight: '29px',
                     marginBottom: '10px',
                     '@media (max-width: 750px)': {
                        fontSize: '20px',
                     },
                  }}
               >
                  Адистиги:
               </Typography>
               <InputBase
                  value={specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                  sx={{
                     borderRadius: '8px',
                     background: 'rgb(255, 255, 255)',
                     boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                     padding: '0 20px',
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '19px',
                     fontWeight: '400',
                     lineHeight: '26px',
                     width: '60%',
                     border: '1px solid grey',
                     '@media (max-width: 600px)': {
                        width: '100%',
                     },
                  }}
                  placeholder="Frontend developer"
               />
            </Box>
            <Button
               onClick={handleSubmit}
               sx={{
                  fontFamily: 'Inter, sans-serif',
                  borderRadius: '8px',
                  background: 'rgb(205, 164, 7)',
                  color: 'rgb(0, 0, 0)',
                  fontSize: '24px',
                  fontWeight: '600',
                  lineHeight: '29px',
                  padding: '13px 48px',
                  marginTop: '35px',
               }}
            >
               Өзгөртүү
            </Button>
         </Box>
         <SendNotificationSuccess
            open={open}
            onClose={handleClose}
            text={'Ментор тууралуу маалымат ийгилүктүү өзгөртүлдү'}
         />
      </Container>
   )
}

export default EditMentor
