import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
   reducerPath: 'api',
   baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
      prepareHeaders: (headers, { getState }) => {
         const token = getState().userAuth.token
         if (token) {
            headers.set('Authorization', `Bearer ${token}`)
         }
         headers.set('Content-Type', 'application/json')
         return headers
      },
   }),
   endpoints: (builder) => ({
      getAllDirectionsData: builder.query({
         query: () => `/directions/getAll`,
         providesTags: ['Direction'],
      }),
      getDirectionById: builder.query({
         query: (id) => `/directions/${id}`,
         providesTags: ['Direction'],
      }),
      getAllDirectionsDataAdmin: builder.query({
         query: () => `/directions/getAll/admin`,
         providesTags: ['Direction'],
      }),
      addDirection: builder.mutation({
         query: (newData) => ({
            url: `/directions`,
            method: 'POST',
            body: newData,
         }),
         invalidatesTags: ['Direction'],
      }),
      updateDirection: builder.mutation({
         query: ({ id, newData }) => ({
            url: `directions/${id}`,
            method: 'PUT',
            body: newData,
         }),
         invalidatesTags: ['Direction'],
      }),
      deleteDirections: builder.mutation({
         query: (id) => ({
            url: `/directions/${id}`,
            method: 'DELETE',
         }),
         invalidatesTags: ['Direction'],
      }),
   }),
})
export const {
   useGetAllDirectionsDataQuery,
   useAddDirectionMutation,
   useGetAllDirectionsDataAdminQuery,
   useDeleteDirectionsMutation,
   useUpdateDirectionMutation,
   useGetDirectionByIdQuery,
} = api
