import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

const DeleteMember = ({ handleCloseDelete, handleDeleteMember, member }) => {
   const [showConfirmation, setShowConfirmation] = useState(false)

   const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 715,
      bgcolor: 'background.paper',
      border: '1px solid #000',
      padding: '32px',
      boxShadow: 24,
      p: 4,
      background: '#fff',
      borderRadius: '10px',
      '@media (max-width: 780px)': {
         width: '80%',
      },
   }

   const handleDelete = async () => {
      await handleDeleteMember()
      setShowConfirmation(true)
   }

   const handleFinalClose = () => {
      setShowConfirmation(false)
      handleCloseDelete()
   }

   return (
      <Box>
         {!showConfirmation ? (
            <Box sx={style}>
               <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                     onClick={handleCloseDelete}
                     style={{
                        minWidth: '46px',
                        minHeight: '46px',
                        borderRadius: '50%',
                        background: 'rgb(3, 22, 52)',
                     }}
                  >
                     <CloseIcon />
                  </Button>
               </Box>
               <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src="imgs/deleteIcon.png" alt="" />
               </Box>
               <Typography
                  sx={{
                     color: 'rgb(0, 0, 0)',
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '32px',
                     fontWeight: '600',
                     lineHeight: '39px',
                     textAlign: 'center',
                     margin: '20px 0 33px 0',
                  }}
               >
                  Катышуучуну тизмеден өчүрүү
               </Typography>
               <Box
                  sx={{
                     display: 'flex',
                     gap: '64px',
                     justifyContent: 'center',
                  }}
               >
                  <Button
                     onClick={handleDelete}
                     sx={{
                        borderRadius: '8px',
                        background: 'rgb(205, 164, 7)',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '17px',
                        color: '#000',
                        padding: '12px 35px',
                     }}
                  >
                     Ооба
                  </Button>
                  <Button
                     onClick={handleCloseDelete}
                     sx={{
                        borderRadius: '8px',
                        background: 'rgb(205, 164, 7)',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '17px',
                        color: '#000',
                        padding: '12px 35px',
                     }}
                  >
                     Жок
                  </Button>
               </Box>
            </Box>
         ) : (
            <Box sx={style}>
               <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                     onClick={handleFinalClose}
                     style={{
                        minWidth: '46px',
                        minHeight: '46px',
                        borderRadius: '50%',
                        background: 'rgb(3, 22, 52)',
                     }}
                  >
                     <CloseIcon />
                  </Button>
               </Box>
               <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CheckIcon
                     sx={{ width: '116px', height: '116px', color: 'green' }}
                  />
               </Box>
               <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                     sx={{
                        color: 'rgb(0, 0, 0)',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '32px',
                        fontWeight: '600',
                        lineHeight: '39px',
                        textAlign: 'center',
                        margin: '20px 0 33px 0',
                        width: '60%',
                     }}
                  >
                     Катышуучу тизмеден өчүрүлдү!
                  </Typography>
               </Box>
            </Box>
         )}
      </Box>
   )
}

export default DeleteMember
