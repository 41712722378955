import React, { useState } from 'react'
import gone from '../../assets/icons/notify_delete.svg'
import mail from '../../assets/icons/notify_mail.svg'
import { Box, Typography } from '@mui/material'
import close from '../../assets/icons/modal.close.svg'
import styled from 'styled-components'

const Modal = ({ onClose, title, description, date, id, onDelete }) => {
   return (
      <ModalOverlay onClick={onClose}>
         <ModalContainer>
            <ModalContent onClick={(e) => e.stopPropagation()}>
               <ModalAllIcons>
                  <ModalDelete onClick={() => onDelete(id)}>
                     <img src={gone} alt="delete" />
                  </ModalDelete>
                  <CloseButton onClick={onClose}>
                     <img src={close} alt="close" />
                  </CloseButton>
               </ModalAllIcons>

               <ModalDate>
                  <p>{date}</p>
               </ModalDate>

               <ModalTitle>{title}</ModalTitle>

               <ModalBody>
                  <p>{description}</p>
               </ModalBody>
            </ModalContent>
         </ModalContainer>
      </ModalOverlay>
   )
}

const Notify = () => {
   const data = [
      {
         id: 1,
         title: 'TechJol',
         description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.мLorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
         date: '01.09.2024',
         booleans: false,
      },
      {
         id: 1,
         title: 'TechJol',
         description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.мLorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
         date: '01.09.2024',
         booleans: true,
      },
   ]

   // состояние для управления видимостью иконок
   const [hoveredIndex, setHoveredIndex] = useState(null)

   // состояние для модалки
   const [modalData, setModalData] = useState(null)

   const openModal = (item) => {
      setModalData(item)
   }
   const closeModal = () => {
      setModalData(null)
   }

   // текст
   const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
         return text.slice(0, maxLength) + '...'
      }
      return text
   }

   // ! delete
   const [notification, setNotifications] = useState(data)
   const deleteNotification = (id) => {
      setNotifications((prevNotifications) =>
         prevNotifications.filter((notification) => notification.id !== id)
      )
   }

   // ! paginaiton
   const [currentPage, setCurrentPage] = useState(1)
   const postsPerPage = 10
   const totalPages = Math.ceil(data.length / postsPerPage)

   const indexOfLastPost = currentPage * postsPerPage
   const indexOfFirstPost = indexOfLastPost - postsPerPage
   data.slice(indexOfFirstPost, indexOfLastPost)

   return (
      <Notifyy>
         <MainTitle>Кулактандыруулар</MainTitle>
         <NotifyContainer>
            {notification.map((item, index) => {
               const titleColor = item.booleans
                  ? 'rgb(0, 0, 0)'
                  : 'rgb(205, 164, 7)'
               const fontWeight = item.booleans ? 400 : 600

               return (
                  <Notification
                     key={index}
                     onMouseEnter={
                        window.innerWidth > 730
                           ? () => setHoveredIndex(index)
                           : undefined
                     }
                     onMouseLeave={
                        window.innerWidth > 730
                           ? () => setHoveredIndex(null)
                           : undefined
                     }
                  >
                     {/* Родительский элемент с Flexbox */}
                     <NotificationContent>
                        <Adaptive onClick={() => openModal(item)}>
                           <NotifyTitle
                              style={{
                                 color: titleColor,
                                 fontWeight: fontWeight,
                              }}
                           >
                              {item.title}
                           </NotifyTitle>
                           <NotifyDescr style={{ fontWeight: fontWeight }}>
                              {truncateText(item.description, 55)}
                           </NotifyDescr>
                        </Adaptive>

                        {/* Элемент с  adaptive */}
                        <NotifyEnd style={{ fontWeight: fontWeight }}>
                           {hoveredIndex === index ? (
                              <IconContainer>
                                 <IconMail onClick={() => openModal(item)}>
                                    <img src={mail} alt="mail" />
                                 </IconMail>
                                 <IconDelete
                                    onClick={() => deleteNotification(item.id)}
                                 >
                                    <img src={gone} alt="gone" />
                                 </IconDelete>
                              </IconContainer>
                           ) : (
                              <NotifyDate>{item.date}</NotifyDate>
                           )}
                        </NotifyEnd>
                     </NotificationContent>
                  </Notification>
               )
            })}
         </NotifyContainer>

         {/* Модалка */}
         {modalData && (
            <Modal
               title={modalData.title}
               description={modalData.description}
               date={modalData.date}
               onClose={closeModal}
               id={modalData.id}
               onDelete={deleteNotification}
            />
         )}

         {/* пагинация */}
         <PaginationContainer>
            <NumberBox
               onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
               disabled={currentPage === 1}
            >
               <svg
                  width="12"
                  height="13.57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M12 13.57L0 8.28L0 5.28L12 0L12 3.45L3.89 6.7L3.89 6.86L12 10.11L12 13.57Z"
                     fill="#031634"
                  />
               </svg>
            </NumberBox>

            {/* Логика для отображения номеров страниц */}
            {totalPages > 4 ? (
               <>
                  <NumberBox
                     onClick={() => setCurrentPage(1)}
                     sx={{
                        cursor: 'pointer',
                        color:
                           currentPage === 1
                              ? 'rgb(205, 164, 7)'
                              : 'rgb(3, 22, 52)',
                     }}
                  >
                     <Typography variant="h6">1</Typography>
                  </NumberBox>
                  <NumberBox
                     onClick={() => setCurrentPage(2)}
                     sx={{
                        cursor: 'pointer',
                        color:
                           currentPage === 2
                              ? 'rgb(205, 164, 7)'
                              : 'rgb(3, 22, 52)',
                     }}
                  >
                     <Typography variant="h6">2</Typography>
                  </NumberBox>
                  {currentPage > 0 && <Typography variant="h6">...</Typography>}
                  {currentPage < totalPages - 1 && (
                     <NumberBox
                        onClick={() => setCurrentPage(totalPages)}
                        sx={{
                           cursor: 'pointer',
                           color:
                              currentPage === totalPages
                                 ? 'rgb(205, 164, 7)'
                                 : 'rgb(3, 22, 52)',
                        }}
                     >
                        <Typography variant="h6">{totalPages}</Typography>
                     </NumberBox>
                  )}
               </>
            ) : (
               [...Array(totalPages)].map((_, index) => (
                  <NumberBox
                     key={index}
                     onClick={() => setCurrentPage(index + 1)}
                     sx={{
                        color:
                           currentPage === index + 1
                              ? 'rgb(205, 164, 7)'
                              : 'rgb(3, 22, 52)',
                        cursor: 'pointer',
                     }}
                  >
                     <Typography variant="h6">{index + 1}</Typography>
                  </NumberBox>
               ))
            )}

            <NumberBox
               onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
               }
               disabled={currentPage === totalPages}
            >
               <svg
                  width="12"
                  height="13.57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M12 5.28L12 8.28L0 13.57L0 10.11L8.1 6.86L8.1 6.7L0 3.45L0 0L12 5.28Z"
                     fill="#031634"
                  />
               </svg>
            </NumberBox>
         </PaginationContainer>
      </Notifyy>
   )
}

// ! main style
const Notifyy = styled.div``
const NotifyContainer = styled.div`
   max-width: 1240px;
   margin: 0 auto;

   @media (max-width: 500px) {
      padding: 0px 10px 10px 20px;
      max-height: 556px;
      overflow-y: auto;

      /* Scrollbar styles */
      &::-webkit-scrollbar {
         width: 6px;
         padding-right: 5px;
      }

      &::-webkit-scrollbar-track {
         background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
         background-color: rgb(205, 164, 7);
         border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
         background-color: #b79506;
      }
   }
`

const MainTitle = styled.div`
   font-size: 48px;
   line-height: 58px;
   padding: 53px 0px 45px 0px;

   @media (max-width: 1024px) {
      font-size: 36px;
      line-height: 46px;
   }

   @media (max-width: 730px) {
      font-size: 32px;
      margin-bottom: 5px;
      line-height: 36px;
   }

   @media (max-width: 500px) {
      font-size: 30px;
      line-height: 36px;
      padding: 26px 0px 36px 0px;
   }
`

const Notification = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-radius: 5px;
   background-color: rgb(255, 255, 255);
   border: 1px solid rgb(165, 165, 165);
   box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
   margin: 0px 10px 20px 10px;
   padding: 10px;

   @media (max-width: 730px) {
      box-shadow: none;
      border: none;
      flex-direction: column;
      display: flex;
      justify-content: start;
      align-items: start;
   }

   @media (max-width: 500px) {
      padding: 0;
   }
`

const NotificationContent = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;

   @media (max-width: 730px) {
      display: flex;
   }
`

const Adaptive = styled.div`
   display: flex;
   align-items: center;
   text-align: start;

   @media (max-width: 730px) {
      display: inline-block;
      text-align: start;
   }
`

const NotifyTitle = styled.div`
   font-family: 'Inter', sans-serif;
   font-size: 22px;
   line-height: 27px;
   padding: 15px 0px 15px 10px;

   @media (max-width: 1024px) {
      font-size: 20px;
      padding: 15px 0px 15px 0px;
   }

   @media (max-width: 730px) {
      font-size: 20px;
   }

   @media (max-width: 500px) {
      font-size: 12px;
      line-height: 15px;
   }
`

const NotifyDescr = styled.div`
   font-family: 'Inter', sans-serif;
   padding: 20px 0px 20px 135px;
   font-size: 22px;
   line-height: 27px;

   @media (max-width: 1133px) {
      padding: 20px 0px 20px 100px;
   }
   @media (max-width: 865px) {
      padding: 20px 0px 20px 40px;
   }
   @media (max-width: 767px) {
      padding: 20px 0px 20px 10px;
   }

   @media (max-width: 1024px) {
      font-size: 18px;
   }
   @media (max-width: 730px) {
      font-size: 18px;
      padding: 0;
   }

   @media (max-width: 500px) {
      font-size: 10px;
      line-height: 12px;
   }
`

const NotifyEnd = styled.div`
   // font-family: 'Inter', sans-serif;
   // padding: 15px 45px 15px 0px;
   // font-size: 22px;
   // line-height: 27px;

   // @media (max-width: 1024px) {
   //    padding: 15px 15px 15px 0px;
   //    font-size: 18px;
   // }

   @media (max-width: 730px) {
      padding: 0px 0px 40px 0px;
   }

   @media (max-width: 500px) {
      display: flex;
   }
`

const IconContainer = styled.div`
   width: 155px;
   display: flex;
   align-items: center;
   justify-content: start;

   @media (max-width: 1024px) {
      width: 100px;
   }
`

const IconMail = styled.div`
   padding-right: 45px;
   cursor: pointer;
`

const IconDelete = styled.div`
   cursor: pointer;
`

const NotifyDate = styled.div`
   font-family: 'Inter', sans-serif;
   padding: 15px 45px 15px 0px;
   font-size: 22px;
   line-height: 27px;

   @media (max-width: 1024px) {
      padding: 15px 15px 15px 0px;
      font-size: 18px;
   }

   @media (max-width: 500px) {
      font-size: 11px;
      line-height: 13px;
      padding: 15px 0px 0px 0px;
   }
`
// ! main style emd

// ! pagination style
const PaginationContainer = styled(Box)({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   gap: '20px',
   paddingBottom: '195px',
   paddingTop: '62px',
   '@media (max-width: 1024px)': {
      paddingBottom: '165px',
      paddingTop: '42px',
   },
   '@media (max-width: 730px)': {
      display: 'none',
   },
})

const NumberBox = styled(Box)({
   width: '50px',
   height: '50px',
   backgroundColor: 'rgb(246, 249, 251)',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   fontFamily: 'Inter, sans-serif',
   fontWeight: 700,
   fontSize: '24px',
   lineHeight: '29px',
   borderRadius: '8px',
})

// ! модалка
const ModalOverlay = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
`

const ModalContainer = styled.div``

const ModalContent = styled.div`
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 767px;
   max-width: 90vw;
   max-height: 90vh;
   background-color: rgb(255, 255, 255);
   border-radius: 8px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   padding: 32px 42px 100px 80px;
   z-index: 1001;
   display: flex;
   flex-direction: column;
   overflow-y: auto;

   @media (max-width: 1024px) {
      width: 90vw;
      padding: 20px;
      flex-direction: column;
   }
`

const ModalAllIcons = styled.div`
   @media (max-width: 730px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
`

const ModalDelete = styled.div`
   @media (min-width: 730px) {
      display: none;
   }
   @media (max-width: 500px) {
      width: 30px;
      height: 30px;
   }
`

const CloseButton = styled.div`
   cursor: pointer;
   display: flex;
   justify-content: flex-end;

   @media (max-width: 730px) {
      width: 35px;
      height: 35px;
   }

   @media (max-width: 500px) {
      width: 30px;
      height: 30px;
   }
`

const ModalDate = styled.div`
   display: flex;
   align-self: self-start;
   font-family: 'Inter', sans-serif;
   font-weight: 400;
   font-size: 16px;
   line-height: 37px;
   color: rgb(20, 26, 22);
   padding-top: 20px;

   @media (max-width: 730px) {
      display: none;
   }
`

const ModalTitle = styled.h2`
   font-family: 'Inter', sans-serif;
   font-weight: 600;
   font-size: 36px;
   line-height: 44px;

   @media (max-width: 730px) {
      font-size: 18px;
   }
`

const ModalBody = styled.div`
   flex-grow: 1;
   overflow-y: auto;
   font-family: 'Inter', sans-serif;
   font-weight: 400;
   font-size: 20px;
   line-height: 37px;
   text-align: start;
   width: 607px;
   max-height: 410px;
   padding-top: 20px;

   /* Прокрутка */
   &::-webkit-scrollbar {
      width: 6px;
      padding-right: 5px;
   }

   &::-webkit-scrollbar-track {
      background: #f1f1f1;
   }

   &::-webkit-scrollbar-thumb {
      background-color: rgb(205, 164, 7);
      border-radius: 5px;
   }

   &::-webkit-scrollbar-thumb:hover {
      background-color: #b79506;
   }

   @media (max-width: 1024px) {
      width: 100%;
      max-height: 400px;
      font-size: 16px;
   }
`
// ! modal end

export default Notify
