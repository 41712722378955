import { Box, Button, Card, Container, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FormBlog from './FormBlog'
import DeleteBlog from './DeleteBlog'
import axios from 'axios'
import { blogActions } from '../../store/features/blogSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const AdminBlogs = () => {
   const [blogs, setBlogs] = useState([])
   const [open, setOpen] = useState(false)
   const [deleteItemId, setDeleteItemId] = useState(0)
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleOpen = (id) => {
      setDeleteItemId(id)
      setOpen(true)
   }

   const EditItem = async (e, id) => {
      e.preventDefault()
      navigate('/edit-blog')

      dispatch(blogActions.editID(id))
   }

   const handleClose = () => setOpen(false)

   const getAllBlogs = async () => {
      const response = await axios.get(
         `${process.env.REACT_APP_BASE_URL}/blogs/getAllBlogs?currentPage=1&pageSize=1`
      )
      setBlogs(response.data.blogs)
   }

   useEffect(() => {
      getAllBlogs()
   }, [])

   if (window.location.pathname === '/form-blog') {
      return <FormBlog />
   }

   return (
      <Container>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               padding: '50px 0',
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  width: '60%',
                  justifyContent: 'space-between',
                  marginLeft: 'auto',
                  '@media (max-width: 700px)': {
                     width: '100%',
                  },
               }}
            >
               <Typography
                  variant="h5"
                  sx={{
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '36px',
                     fontWeight: '600',
                     '@media (max-width: 450px)': {
                        fontSize: '30px',
                     },
                  }}
               >
                  Блогдор
               </Typography>
               <a href="/form-blog">
                  <Button
                     sx={{
                        fontFamily: 'Inter, sans-serif',
                        borderRadius: '8px',
                        background: 'rgb(205, 164, 7)',
                        color: 'rgb(0, 0, 0)',
                        fontSize: '24px',
                        fontWeight: '600',
                        padding: '13px 48px',
                        '@media (max-width: 450px)': {
                           fontSize: '20px',
                           padding: '10px 28px',
                        },
                     }}
                  >
                     Кошуу
                  </Button>
               </a>
            </Box>
            <Box sx={{ padding: '53px 0' }}>
               {blogs.map((blog, index) => (
                  <Card
                     key={blog.id}
                     sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '7px 20px',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        justifyContent: 'space-between',
                        border: '1px solid grey',
                        marginBottom: '20px',
                     }}
                  >
                     <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                           variant="h1"
                           sx={{
                              fontFamily: 'Inter, sans-serif',
                              fontSize: '24px',
                              fontWeight: '600',
                              marginRight: '19px',
                              '@media (max-width: 450px)': {
                                 marginRight: '10px',
                              },
                           }}
                        >
                           {index + 1}.
                        </Typography>
                        <Typography
                           variant="h4"
                           sx={{
                              fontFamily: 'Inter, sans-serif',
                              fontSize: '24px',
                              fontWeight: '400',
                              '@media (max-width: 450px)': {
                                 marginLeft: '20px',
                              },
                           }}
                        >
                           {blog.title}
                        </Typography>
                     </Box>
                     <Box
                        sx={{
                           display: 'flex',
                           gap: '64px',
                           '@media (max-width: 500px)': {
                              gap: '15px',
                           },
                        }}
                     >
                        <a href="/edit-blog">
                           <Button
                              onClick={(e) => EditItem(e, blog.id)}
                              sx={{
                                 width: '40px',
                                 height: '40px',
                                 borderRadius: '5px',
                                 background: 'rgb(255, 255, 255)',
                                 boxShadow:
                                    'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                              }}
                           >
                              <EditIcon sx={{ color: '#000' }} />
                           </Button>
                        </a>
                        <Button
                           onClick={() => handleOpen(blog.id)}
                           sx={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '5px',
                              background: 'rgb(255, 255, 255)',
                              boxShadow:
                                 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                           }}
                        >
                           <DeleteIcon sx={{ color: '#000' }} />
                        </Button>
                     </Box>
                  </Card>
               ))}
            </Box>
         </Box>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <DeleteBlog
               deleteBlogId={deleteItemId}
               handleClose={handleClose}
               getAllBlogs={getAllBlogs}
            />
         </Modal>
      </Container>
   )
}

export default AdminBlogs
