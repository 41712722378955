import React, { useState } from 'react'
import { Box, Button, Container, InputBase, Typography } from '@mui/material'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import ProfilePicture from '../../assets/imgs/amdin-profile-picture.svg'
import CircularProgress from '@mui/material/CircularProgress'
import {
   usePostImageMutation,
   usePostMentorMutation,
} from '../../store/features/mentorSlice'
import SendNotificationFail from '../ui/SendNotificationFail'
import SendNotificationSuccess from '../ui/SendNotificationSuccess'

export const style = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 615,
   bgcolor: 'background.paper',
   boxShadow: 24,
   display: 'flex',
   flexDirection: 'column',
   borderRadius: '10px',
   p: 4,
   '@media (max-width: 700px)': {
      width: '80%',
   },
}

const AddMentor = () => {
   const [isSuccess, setIsSuccess] = useState(false)
   const [isFail, setIsFail] = useState(false)
   const [isUploading, setIsUploading] = useState(false)
   const [imageUrl, setImageUrl] = useState(ProfilePicture)
   const [fullName, setFullName] = useState('')
   const [specialization, setSpecialization] = useState('')

   const [postImage] = usePostImageMutation()
   const [postMentor] = usePostMentorMutation()

   const handleImageUpload = async (event) => {
      const file = event.target.files[0]
      if (!file) return

      setIsUploading(true)

      try {
         const formData = new FormData()
         formData.append('file', file)

         const response = await postImage(formData).unwrap()
         setImageUrl(response.link || response.url)
      } catch (error) {
         console.error('Failed to upload image:', error)
      } finally {
         setIsUploading(false)
      }
   }

   const handleSubmit = async (e) => {
      e.preventDefault()
      try {
         await postMentor({
            imageMentor: imageUrl,
            fullName,
            specialization,
         }).unwrap()

         setIsSuccess(true)
         setFullName('')
         setSpecialization('')
         setImageUrl(ProfilePicture)
      } catch (error) {
         console.error('Failed to add mentor:', error)
      }
   }

   const handleClose = () => {
      setIsSuccess(false)
      setIsFail(false)
   }

   return (
      <Container>
         <form
            onSubmit={handleSubmit}
            sx={{
               padding: '30px 0',
               width: '945px',
               '@media (max-width: 850px)': {
                  width: '100%',
               },
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '15px',
                  marginBottom: '44px',
               }}
            >
               <Box
                  sx={{
                     position: 'relative',
                     borderRadius: '8px',
                     width: '220px',
                     height: '250px',
                     background: 'rgb(205, 164, 7)',
                     padding: '10px',
                  }}
               >
                  {isUploading && (
                     <Box
                        sx={{
                           position: 'absolute',
                           top: '50%',
                           left: '50%',
                           transform: 'translate(-50%, -50%)',
                           zIndex: 1,
                        }}
                     >
                        <CircularProgress />
                     </Box>
                  )}
                  <img
                     style={{
                        borderRadius: '8px',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        opacity: isUploading ? 0.5 : 1,
                     }}
                     src={imageUrl}
                     alt="Mentor"
                  />
               </Box>
               <Box sx={{ position: 'relative', width: '219px' }}>
                  <InputBase
                     sx={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        cursor: 'pointer',
                     }}
                     type="file"
                     onChange={handleImageUpload}
                     inputProps={{ accept: 'image/*' }}
                     accept="image/*"
                     disabled={isUploading}
                  />
                  <Box
                     sx={{
                        color: 'rgb(77, 75, 75)',
                        border: '1px solid rgb(210, 210, 211)',
                        borderRadius: '8px',
                        background: 'rgb(255, 255, 255)',
                        boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        padding: '10px 6px',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '19px',
                        fontWeight: '400',
                        lineHeight: '26px',
                        zIndex: 0,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                        justifyContent: 'center',
                     }}
                  >
                     <ImageSearchIcon /> Cүрөт тандоо
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '50px',
                  '@media (max-width: 600px)': {
                     flexDirection: 'column',
                     alignItems: 'flex-start',
                     margin: '0 auto',
                  },
               }}
            >
               <Typography
                  sx={{
                     fontFamily: 'Istok Web, sans-serif',
                     fontSize: '24px',
                     fontWeight: '400',
                     lineHeight: '29px',
                     marginBottom: '10px',
                     '@media (max-width: 750px)': {
                        fontSize: '20px',
                     },
                  }}
               >
                  Ментордун аты-жөнү:
               </Typography>
               <InputBase
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  sx={{
                     borderRadius: '8px',
                     background: 'rgb(255, 255, 255)',
                     boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                     padding: '0 20px',
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '19px',
                     fontWeight: '400',
                     lineHeight: '26px',
                     border: '1px solid grey',
                     width: '60%',
                     '@media (max-width: 600px)': {
                        width: '100%',
                     },
                  }}
                  placeholder="Асан Үсөнов"
                  required
               />
            </Box>
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '0 0 40px 0',

                  '@media (max-width: 600px)': {
                     flexDirection: 'column',
                     alignItems: 'flex-start',
                     marginTop: '20px ',
                  },
               }}
            >
               <Typography
                  sx={{
                     fontFamily: 'Istok Web, sans-serif',
                     fontSize: '24px',
                     fontWeight: '400',
                     lineHeight: '29px',
                     marginBottom: '10px',
                     '@media (max-width: 750px)': {
                        fontSize: '20px',
                     },
                  }}
               >
                  Адистиги:
               </Typography>
               <InputBase
                  value={specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                  sx={{
                     borderRadius: '8px',
                     background: 'rgb(255, 255, 255)',
                     boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                     padding: '0 20px',
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '19px',
                     fontWeight: '400',
                     lineHeight: '26px',
                     width: '60%',
                     border: '1px solid grey',
                     '@media (max-width: 600px)': {
                        width: '100%',
                     },
                  }}
                  placeholder="Frontend developer"
                  required
               />
            </Box>

            <Button
               sx={{
                  fontFamily: 'Inter, sans-serif',
                  borderRadius: '8px',
                  background: 'rgb(205, 164, 7)',
                  color: 'rgb(0, 0, 0)',
                  fontSize: '24px',
                  fontWeight: '600',
                  lineHeight: '29px',
                  padding: '13px 48px',
                  margin: '0 auto',
                  width: '250px',
               }}
               type="submit"
            >
               Кошуу
            </Button>
         </form>
         <SendNotificationSuccess
            open={isSuccess}
            onClose={handleClose}
            text={'Ментор тууралуу маалымат ийгиликтүү кошулду!'}
         />
         <SendNotificationFail
            open={isFail}
            onClose={handleClose}
            text={'Менторду кошууда ката кетти кайра аракет кылып көрүңүз'}
         />
      </Container>
   )
}

export default AddMentor
