import React from 'react'
import { Container, Box, Typography, Grid } from '@mui/material'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ArrowLeft from '../assets/svg/arrowLeft.svg'
import ArrowRight from '../assets/svg/arrowRight.svg'
import { useGetAllPartnersQuery } from '../store/features/apiPartners'

const Partners = () => {
   const { data, isLoading, error } = useGetAllPartnersQuery()
   console.log(data)

   if (isLoading) return <Typography>Loading...</Typography>
   if (error) return <Typography>Error occurred: {error.message}</Typography>

   const partners = data?.partners || []

   // Настройки для слайдера
   const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 2,
            },
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
            },
         },
      ],
   }

   return (
      <section>
         <Container
            maxWidth={false}
            sx={{
               maxWidth: '1288px',
               margin: '50px auto',
               fontFamily: 'Inter',
            }}
         >
            <Box component="section" sx={{ marginBottom: '87px' }}>
               <Typography
                  variant="h2"
                  component="h2"
                  sx={{
                     fontSize: {
                        xs: '32px',
                        sm: '40px',
                        md: '48px',
                     },
                     fontWeight: 500,
                     textAlign: 'center',
                     marginBottom: {
                        xs: '20px',
                        sm: '40px',
                        md: '70px',
                     },
                  }}
               >
                  Өнөктөштөр
               </Typography>
               <Slider {...settings} style={{ padding: '0 60px' }}>
                  {partners.map((partner) => (
                     <div key={partner.id}>
                        <Grid
                           item
                           sx={{
                              width: {
                                 xs: '180px',
                                 sm: '200px',
                                 md: '210px',
                              },
                              display: 'flex',
                              justifyContent: 'space-between',
                              position: 'relative',
                              textAlign: 'center',
                           }}
                        >
                           <Box
                              sx={{
                                 position: 'relative',
                                 width: '100%',
                                 height: '270px',
                              }}
                           >
                              <img
                                 src={
                                    partner.logo
                                       ? partner.logo
                                       : `${process.env.REACT_APP_BASE_URL}/images/placeholder.png`
                                 }
                                 alt={partner.name}
                                 style={{
                                    width: '230px',
                                    height: '230px',
                                    objectFit: 'cover',
                                 }}
                              />
                              <Typography
                                 variant="h6"
                                 sx={{
                                    fontSize: {
                                       xs: '16px',
                                       sm: '20px',
                                       md: '24px',
                                    },
                                    fontWeight: 500,
                                    position: 'absolute',
                                    top: '237px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: '100%',
                                    padding: '4px 0',
                                    textAlign: 'center',
                                 }}
                              >
                                 {partner.name}
                              </Typography>
                           </Box>
                        </Grid>
                     </div>
                  ))}
               </Slider>
            </Box>
         </Container>
      </section>
   )
}

// Кастомные стрелки
function NextArrow(props) {
   const { onClick } = props
   return (
      <div
         onClick={onClick}
         style={{
            display: 'flex',
            position: 'absolute',
            top: '50%',
            right: '-25px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            width: '50px',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '30px',
            backgroundColor: 'rgba(210, 210, 211, 0.3)',
            borderRadius: '50%',
            border: '2px solid rgba(0, 0, 0, 0.30)',
         }}
      >
         <img
            src={ArrowRight}
            alt="Next"
            style={{ width: '26px', height: '34px' }}
         />
      </div>
   )
}

function PrevArrow(props) {
   const { onClick } = props
   return (
      <div
         onClick={onClick}
         style={{
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '-25px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            width: '50px',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '30px',
            backgroundColor: 'rgba(210, 210, 211, 0.3)',
            borderRadius: '50%',
            border: '2px solid rgba(0, 0, 0, 0.30)',
         }}
      >
         <img
            src={ArrowLeft}
            alt="Previous"
            style={{ width: '26px', height: '34px' }}
         />
      </div>
   )
}

export default Partners
