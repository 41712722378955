import * as React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import { useGetAllDirectionsDataQuery } from '../store/features/apiSlice'

const CardComponent = ({ id, image, title, backgroundColor }) => (
   <Card
      sx={{
         width: '100%',
         maxWidth: 399,
         height: 343,
         backgroundColor: 'rgb(3, 22, 52)',
         borderRadius: '10px',
      }}
   >
      <CardActionArea>
         <Box
            sx={{
               width: '100%',
               height: 205,
               backgroundColor,
               borderRadius: '10px',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <CardMedia
               component="img"
               image={image}
               alt={title}
               sx={{ width: '90%', height: '199.06px', objectFit: 'contain' }}
            />
         </Box>
         <CardContent sx={{ paddingBottom: 0 }}>
            <Typography
               variant="h5"
               component="div"
               sx={{ color: 'white', textAlign: 'center' }}
            >
               {title}
            </Typography>
         </CardContent>
      </CardActionArea>
      <CardActions sx={{ padding: 2 }}>
         <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <a href={`course/${id}`}>
               <Button
                  sx={{
                     borderRadius: '10px',
                     background: 'rgb(205, 164, 7)',
                     width: '168px',
                     height: '40px',
                     color: 'rgb(255, 255, 255)',
                     fontFamily: 'Inter',
                     fontSize: '18px',
                     textTransform: 'none',
                     '&:hover': {
                        background: 'rgb(175, 140, 0)',
                     },
                  }}
               >
                  Кененирээк
               </Button>
            </a>
         </Box>
      </CardActions>
   </Card>
)

export default function BasicGrid() {
   const { data, error, isLoading } = useGetAllDirectionsDataQuery()
   if (isLoading) return <div>Loading...</div>
   if (error) return <div>Error loading data</div>
   const isHomePage = window.location.pathname === '/'
   console.log(data)
   return (
      <Container
         sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            padding: '50px 0 100px 0',
         }}
      >
         <Box sx={{ maxWidth: '1440px', width: '100%', margin: '0 auto' }}>
            <Typography
               variant="h4"
               component="div"
               sx={{ textAlign: 'center', mb: 4 }}
            >
               Багыттар
            </Typography>
            <Grid container spacing={2} justifyContent="center">
               {isHomePage
                  ? data?.slice(0, 3).map((card, index) => (
                       <Grid item xs={12} sm={6} md={4} key={card.id}>
                          <CardComponent
                             id={card.id}
                             image={card.imageLink}
                             title={card.name}
                             backgroundColor={card.color}
                          />
                       </Grid>
                    ))
                  : data?.map((card, index) => (
                       <Grid item xs={12} sm={6} md={4} key={index}>
                          <CardComponent
                             id={card.id}
                             image={card.imageLink}
                             title={card.name}
                             backgroundColor={card.color}
                          />
                       </Grid>
                    ))}
            </Grid>
         </Box>
      </Container>
   )
}
