import React from 'react'
import { Typography, Box, Avatar, Stack } from '@mui/material'
import PenIcon from '../../assets/imgs/path.png'
import TrashIcon from '../../assets/imgs/path (1).png'
import DeleteNotificationModal from './notify_admin/DeleteNotificationModal'
import { useNavigate } from 'react-router-dom'

const IconBoxButton = ({ imgSrc, handleClick,alt }) => {
   return (
      <Box
         width={'40px'}
         height={'40px'}
         sx={{
            boxSizing: 'border-box',
            borderRadius: ' 5px',
            boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            background: 'rgb(255, 255, 255)',
            cursor: 'pointer',
         }}
         display={'flex'}
         alignItems={'center'}
         justifyContent={'center'}
         onClick={handleClick}
      >
         <img src={imgSrc} alt={alt} width="16px" height="16px" />
      </Box>
   )
}

const AdminSectionItems = ({
   numberItems,
   fotoSrc,
   companyName,
   isDirections,
   handleDelete,
   textForDeleteModal,
   editPathName,
}) => {
   const [showDeleteModal, setShowDeleteModal] = React.useState(false)
   const navigate = useNavigate()

   return (
      <Stack
         direction="row"
         spacing={6}
         alignItems={'center'}
         maxWidth={'1152px'}
         p={'4px 24px 4px 20px'}
         sx={{
            borderRadius: '8px',
            boxShadow: '2px 1px 2px 4px  rgba(0, 0, 0, 0.2)',
            background: 'rgb(255, 255, 255)',
         }}
      >
         <Typography
            variant="h5"
            component="div"
            sx={{
               fontFamily: 'Inter, sans-serif',
               font: '600 24px Inter,sans-serif',
               '@media (max-width: 450px)': {
                  marginRight: '10px',
                  width: '32px',
               },
            }}
         >
            {numberItems}.
         </Typography>
         <Avatar
            src={fotoSrc}
            alt="foto"
            sx={{
               width: '50px',
               height: '50px',
               border: isDirections ? '1px solid rgb(205, 164, 7)' : 'none',
            }}
         />
         <Typography
            sx={{
               font: '400 24px Inter,sans-serif',
            }}
         >
            {companyName}
         </Typography>
         <Stack direction="row" flexGrow={2} justifyContent={'end'} spacing={6}>
            <IconBoxButton
               imgSrc={PenIcon}
               handleClick={() => navigate(editPathName)}
               altText='add'
            />
            <IconBoxButton
               imgSrc={TrashIcon}
               altText="Delete"
               handleClick={() => setShowDeleteModal(true)}
            />
         </Stack>
         <DeleteNotificationModal
            deleteOpen={showDeleteModal}
            handleDeleteClose={() => setShowDeleteModal(false)}
            text={textForDeleteModal}
            handleDeleteNotification={handleDelete}
         />
      </Stack>
   )
}

export default AdminSectionItems
