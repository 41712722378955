import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetAllBlogsQuery } from '../store/features/blogUserSlice'
import noImage from '../assets/noImage.png'

const SecondBlog = () => {
   const [page, setPage] = useState(1)
   const { data } = useGetAllBlogsQuery(page)
   const navigate = useNavigate()

   const typographyStyles = {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '20px',
      marginBottom: '16px',
      textAlign: 'start',
      '@media (max-width: 1024px)': {
         width: '800px',
      },
      '@media (max-width: 873px)': {
         width: '700px',
      },
      '@media (max-width: 767px)': {
         width: '600px',
      },
      '@media (max-width: 641px)': {
         width: '550px',
      },
      '@media (max-width: 600px)': {
         fontSize: '16px',
         width: '100%',
      },
   }

   const totalPages = data?.pageSize
   const articlesPerPage = 6
   const currentArticles = data?.blogs?.slice(0, articlesPerPage) || []

   return (
      <Box
         sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '66px',
            maxWidth: '1440px',
            margin: '0 auto',
            '@media (max-width: 1024px)': {
               flexDirection: 'column',
               padding: '20px',
            },
            '@media (max-width: 600px)': {
               flexDirection: 'column',
               padding: '5px',
            },
         }}
      >
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               width: '100%',
               '@media (max-width: 1024px)': {
                  alignItems: 'center',
               },
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: '135px',
                  '@media (max-width: 1024px)': {
                     flexDirection: 'column',
                     paddingBottom: '10px',
                  },
                  '@media (min-width: 1025px)': {
                     flexDirection: 'row',
                  },
                  '@media (max-width: 600px)': {
                     paddingBottom: '0px',
                  },
               }}
            >
               {page === 1 &&
                  data?.blogs.slice(-1).map((el) => (
                     <Box
                        sx={{
                           display: 'flex',
                           width: '100%',
                           maxWidth: '1240px',
                           justifyContent: 'center',
                           cursor: 'pointer',
                        }}
                        key={el.id}
                        onClick={() => navigate(`/blog/${el.id}`)}
                     >
                        <Box sx={{ padding: '16px', flex: 1 }}>
                           <Box>
                              <Typography
                                 variant="h1"
                                 sx={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontWeight: 600,
                                    fontSize: '34px',
                                    lineHeight: '41px',
                                    margin: 0,
                                    '@media (max-width: 800px)': {
                                       fontSize: '26px',
                                       lineHeight: '30px',
                                       margin: 0,
                                    },
                                    '@media (max-width: 600px)': {
                                       fontSize: '20px',
                                       lineHeight: '24px',
                                       margin: 0,
                                    },
                                 }}
                              >
                                 {el.title}
                              </Typography>
                              <Typography
                                 sx={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontWeight: 400,
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    color: 'rgb(85, 96, 113)',
                                    padding: '30px 0px 0px 0px',
                                    '@media (max-width: 600px)': {
                                       fontSize: '14px',
                                       padding: '10px 0px 10px 0px',
                                    },
                                 }}
                              >
                                 {el.createdAt}
                              </Typography>
                           </Box>
                           {/* Image */}
                           <Box
                              sx={{
                                 padding: '15px',
                                 display: { xs: 'block', md: 'none' },
                                 textAlign: 'center',
                                 '@media (max-width: 600px)': {
                                    padding: '0px',
                                 },
                              }}
                           >
                              <Box
                                 component="img"
                                 src={
                                    el.image.startsWith(
                                       'https://images-techjol.s3.amazonaws.com'
                                    )
                                       ? el.image
                                       : noImage
                                 }
                                 sx={{
                                    width: '100%',
                                    maxWidth: '551px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    objectFit: 'cover',
                                    '@media (max-width: 600px)': {
                                       borderRadius: '0px',
                                    },
                                 }}
                              />
                           </Box>
                           <Box
                              sx={{
                                 marginTop: '20px',
                                 width: '100%',
                                 maxWidth: '592px',
                              }}
                           >
                              <Typography sx={typographyStyles}>
                                 {el.text}
                              </Typography>
                           </Box>
                        </Box>

                        <Box
                           sx={{
                              padding: '15px',
                              display: { xs: 'none', md: 'block' },
                           }}
                        >
                           <Box
                              component="img"
                              src={
                                 el.image.startsWith(
                                    'https://images-techjol.s3.amazonaws.com'
                                 )
                                    ? el.image
                                    : noImage
                              }
                              sx={{
                                 width: '551px',
                                 height: '362px',
                                 objectFit: 'cover',
                                 borderRadius: '8px',
                              }}
                           />
                        </Box>
                     </Box>
                  ))}
            </Box>

            <Box
               sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  gap: '20px',
                  width: '100%',
               }}
            >
               {currentArticles.map((post, index) => (
                  <Link key={index} to={`/blog/${post.id}`}>
                     <Box
                        sx={{
                           backgroundColor: 'rgb(246, 249, 251)',
                           width: '400px',
                           borderRadius: '8px',
                           '@media (max-width: 600px)': {
                              width: '100%',
                              height: 'auto',
                              padding: '0px 20px 0px 20px',
                           },
                           '@media (max-width: 445px)': {
                              width: '100%',
                              padding: '0px 20px 0px 20px',
                           },
                        }}
                     >
                        <Box
                           component="img"
                           src={
                              post.image.startsWith(
                                 'https://images-techjol.s3.amazonaws.com'
                              )
                                 ? post.image
                                 : noImage
                           }
                           sx={{
                              width: '100%',
                              height: '308px',
                              borderRadius: '8px',
                              '@media (max-width: 600px)': {
                                 width: '100%',
                                 height: 'auto',
                              },
                           }}
                        />
                        <Box sx={{ padding: '25px' }}>
                           <Typography
                              sx={{
                                 fontFamily: 'Inter, sans-serif',
                                 fontWeight: 600,
                                 fontSize: '24px',
                                 lineHeight: '29px',
                                 '@media (max-width: 600px)': {
                                    fontSize: '18px',
                                 },
                              }}
                           >
                              {post.title}
                           </Typography>
                           <Typography
                              sx={{
                                 fontFamily: 'Inter, sans-serif',
                                 fontWeight: 400,
                                 fontSize: '16px',
                                 lineHeight: '19px',
                                 color: 'rgb(85, 96, 113)',
                                 padding: '16px 0px 20px 0px',
                                 '@media (max-width: 600px)': {
                                    fontSize: '14px',
                                    padding: '6px 0px 10px 0px',
                                 },
                              }}
                           >
                              {post.createdAt}
                           </Typography>
                           <Typography
                              sx={{
                                 fontFamily: 'Inter, sans-serif',
                                 fontWeight: 400,
                                 fontSize: '16px',
                                 lineHeight: '19px',
                                 color: 'rgb(85, 96, 113)',
                                 '@media (max-width: 600px)': {
                                    fontSize: '14px',
                                 },
                              }}
                           >
                              {post.text}
                           </Typography>
                        </Box>
                     </Box>
                  </Link>
               ))}
            </Box>

            <PaginationContainer>
               <NumberBox
                  onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                  disabled={page === 1}
                  sx={{ cursor: 'pointer' }}
               >
                  &lt;
               </NumberBox>
               {Array.from({ length: totalPages }).map((_, index) => (
                  <NumberBox
                     key={index}
                     onClick={() => setPage(index + 1)}
                     sx={{
                        color:
                           page === index + 1
                              ? 'rgb(205, 164, 7)'
                              : 'rgb(3, 22, 52)',
                     }}
                  >
                     {index + 1}
                  </NumberBox>
               ))}
               <NumberBox
                  onClick={() =>
                     setPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={page === totalPages}
                  sx={{ cursor: 'pointer' }}
               >
                  &gt;
               </NumberBox>
            </PaginationContainer>
         </Box>
      </Box>
   )
}
const PaginationContainer = styled(Box)({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   gap: '20px',
   paddingBottom: '195px',
   paddingTop: '62px',
   '@media (max-width: 1024px)': {
      paddingBottom: '165px',
      paddingTop: '42px',
   },
   '@media (max-width: 600px)': {
      paddingBottom: '40px',
      paddingTop: '20px',
   },
})

const NumberBox = styled(Box)({
   width: '50px',
   height: '50px',
   backgroundColor: 'rgb(246, 249, 251)',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   fontFamily: 'Inter, sans-serif',
   fontWeight: 700,
   fontSize: '24px',
   lineHeight: '29px',
   borderRadius: '8px',
   '@media (max-width: 479.98px)': {
      width: '40px',
      height: '40px',
   },
})
export default SecondBlog
