import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
   Box,
   Modal,
   Typography,
   IconButton,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   ListItemText,
   Checkbox,
   TextField,
   Button,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '../../assets/svg/CloseIcon.svg'
import SendNotificationSuccess from './SendNotificationSuccess'
import SendNotificationFail from './SendNotificationFail'
import { fetchNotificationsFromBackend } from '../admin/notify_admin/notification.api'

const AddNotificationModal = ({ open, handleClose, onAddNotification }) => {
   const scrollBarStyles = {
      '&::-webkit-scrollbar': { width: '12px' },
      '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
      '&::-webkit-scrollbar-thumb': {
         background: 'rgb(205, 164, 7)',
         borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
      scrollbarWidth: 'thin',
      scrollbarColor: 'rgb(205, 164, 7) #f1f1f1',
   }

   const [showSuccessNotification, setShowSuccessNotification] = useState(false)
   const [showFailNotification, setShowFailNotification] = useState(false)
   const [newNotification, setNewNotification] = useState({
      type: [],
      subject: '',
      message: '',
   })
   // eslint-disable-next-line no-unused-vars
   const [notifications, setNotifications] = useState([])

   useEffect(() => {
      if (open) {
         fetchNotifications()
         loadNotificationsFromLocalStorage()
      }
   }, [open])

   const fetchNotifications = async () => {
      const fetchedNotifications = await fetchNotificationsFromBackend()
      setNotifications(fetchedNotifications)
   }

   const loadNotificationsFromLocalStorage = () => {
      const storedNotifications = localStorage.getItem('notifications')
      if (storedNotifications) {
         setNotifications((prevNotifications) => [
            ...prevNotifications,
            ...JSON.parse(storedNotifications),
         ])
      }
   }

   const saveNotificationToLocalStorage = (newNotification) => {
      const storedNotifications = localStorage.getItem('notifications')
      const updatedNotifications = storedNotifications
         ? [...JSON.parse(storedNotifications), newNotification]
         : [newNotification]
      localStorage.setItem(
         'notifications',
         JSON.stringify(updatedNotifications)
      )
   }

   const handleSelectChange = (event) => {
      const {
         target: { value },
      } = event
      setNewNotification((prev) => ({
         ...prev,
         type: value,
      }))
   }

   const sendNotificationToBackend = async () => {
      try {
         const response = await axios.post(
            '/api/send-notifications',
            {
               message: newNotification,
            }
         )

         if (response.status === 200) {
            setShowSuccessNotification(true)
            handleClose()
         } else {
            setShowFailNotification(true)
         }
      } catch (error) {
         console.error('Ошибка при добавлении уведомления:', error)
         setShowFailNotification(true)
      }
   }

   const handleAddNotification = async () => {
      if (newNotification) {
         try {
            const addedNotification = await sendNotificationToBackend({
               message: newNotification,
            })

            if (addedNotification) {
               // Сохраняем уведомление в локальное хранилище
               saveNotificationToLocalStorage(newNotification)

               setNotifications((prevNotifications) =>
                  Array.isArray(prevNotifications)
                     ? [...prevNotifications, addedNotification]
                     : [addedNotification]
               )
               setNewNotification({
                  type: [],
                  subject: '',
                  message: '',
               })

               if (onAddNotification) {
                  onAddNotification(addedNotification)
               }

               if (handleClose) {
                  handleClose()
               }
            }
         } catch (error) {
            console.error('Ошибка при добавлении уведомления:', error)
         }
      }
   }

   const renderMenuItem = (item) => (
      <MenuItem
         key={item}
         value={item}
         sx={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            height: '40px',
            padding: '0 10px',
         }}
      >
         <ListItemText primary={item} />
         <Checkbox
            checked={
               Array.isArray(newNotification.type) &&
               newNotification.type.includes(item)
            }
         />
      </MenuItem>
   )

   return (
      <>
         <Modal open={open} onClose={handleClose}>
            <Box
               sx={{
                  width: '828px',
                  height: '525px',
                  bgcolor: 'white',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  p: 4,
                  mx: 'auto',
                  mt: '5vh',
                  border: '1px solid #ccc',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
               }}
            >
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     mb: 2,
                  }}
               >
                  <Typography variant="h5" fontWeight={600}>
                     Кулактандыруу
                  </Typography>
                  <IconButton onClick={handleClose}>
                     <img
                        src={CloseIcon}
                        alt="CloseIcon"
                        style={{ width: 32, height: 32 }}
                     />
                  </IconButton>
               </Box>

               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Box
                     sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                     <Typography
                        variant="body1"
                        fontWeight={600}
                        fontSize="20px"
                        sx={{ width: '40%' }}
                     >
                        Кулактандырууну алуучу:
                     </Typography>
                     <FormControl fullWidth sx={{ width: '60%' }}>
                        <InputLabel>Тандоо</InputLabel>
                        <Select
                           multiple
                           value={newNotification.type}
                           onChange={handleSelectChange}
                           renderValue={(selected) => selected.join(', ')}
                           MenuProps={{
                              PaperProps: {
                                 style: { maxHeight: 300, width: 250 },
                              },
                           }}
                        >
                           <MenuItem sx={{ padding: 0 }}>
                              <TextField
                                 variant="outlined"
                                 size="small"
                                 placeholder="Издөө"
                                 InputProps={{
                                    startAdornment: (
                                       <SearchIcon
                                          sx={{ mr: 1, color: 'gray' }}
                                       />
                                    ),
                                 }}
                                 fullWidth
                              />
                           </MenuItem>
                           {['Баары', 'frontend', 'backend', 'flutter'].map(
                              renderMenuItem
                           )}
                        </Select>
                     </FormControl>
                  </Box>

                  <Box
                     sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                     <Typography
                        variant="body1"
                        fontWeight={600}
                        fontSize="20px"
                        sx={{ width: '40%' }}
                     >
                        Кулактандыруунун темасы:
                     </Typography>
                     <TextField
                        fullWidth
                        value={newNotification.subject}
                        onChange={(e) =>
                           setNewNotification({
                              ...newNotification,
                              subject: e.target.value,
                           })
                        }
                        sx={{ width: '60%' }}
                     />
                  </Box>

                  <Box
                     sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                     <Typography
                        variant="body1"
                        fontWeight={600}
                        fontSize="20px"
                        sx={{ width: '40%' }}
                     >
                        Кулактандыруунун тексти:
                     </Typography>
                     <TextField
                        fullWidth
                        multiline
                        rows={7}
                        value={newNotification.message}
                        onChange={(e) =>
                           setNewNotification({
                              ...newNotification,
                              message: e.target.value,
                           })
                        }
                        sx={{ width: '60%', ...scrollBarStyles }}
                     />
                  </Box>
               </Box>

               <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                  <Button
                     variant="contained"
                     onClick={handleAddNotification} // Исправлено здесь
                     sx={{
                        backgroundColor: 'rgb(205, 164, 7)',
                        color: 'black',
                        fontWeight: '600',
                     }}
                  >
                     Кошуу
                  </Button>
               </Box>
            </Box>
         </Modal>

         <SendNotificationSuccess
            open={showSuccessNotification}
            onClose={() => setShowSuccessNotification(false)}
            text={'Кулактандыруу ийгилүктүү жөнөтүлдү!'}
         />

         <SendNotificationFail
            open={showFailNotification}
            onClose={() => setShowFailNotification(false)}
            text={'Кулактандыруу ийгиликсиз болду!'}
         />
      </>
   )
}

export default AddNotificationModal
