import React, { useState } from 'react'
import {
   Collapse,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Typography,
   List,
   Box,
   Container,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import { FaPeopleGroup } from 'react-icons/fa6'
import { TbTargetArrow } from 'react-icons/tb'
import { GrDirections } from 'react-icons/gr'
import { BsClockHistory } from 'react-icons/bs'
import { BsPersonGear } from 'react-icons/bs'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { MdKeyboardArrowRight } from 'react-icons/md'
import AboutUsTable from './AboutUsTable'

const ListText = [
   {
      id: 1,
      icon: FaPeopleGroup,
      title: 'Долбоор тууралуу',
      hiddenValue: [
         'Долбоор 6 ай уланат. ',
         'Долбоор 6 ай уланат.',
         'Долбоор 6 ай уланат.',
      ],
   },
   {
      id: 2,
      icon: TbTargetArrow,
      title: 'Долбоордун максаттары жана милдеттери ',
      hiddenValue: [
         'Курс жана университетти бүткөн окуучуларга жумуш табышына жардам берүү.',
         'Курс жана университетти бүткөн окуучуларга жумуш табышына жардам берүү.',
         'Курс жана университетти бүткөн окуучуларга жумуш табышына жардам берүү.',
      ],
   },
   {
      id: 3,
      icon: GrDirections,
      title: 'Стажировканын багыттары:',
      hiddenValue: ['Frontend', 'Backend', 'Flutter'],
   },
   {
      id: 4,
      icon: BsPersonGear,
      title: 'Кандидаттарды тандап  алууга талаптар',
      hiddenValue: [
         'IT сферасында бир жылдан ашык проекти талапка карап жасай алган кандидаттар гана кабыл алынат ',
         'IT сферасында бир жылдан ашык проекти талапка карап жасай алган кандидаттар гана кабыл алынат ',
         'IT сферасында бир жылдан ашык проекти талапка карап жасай алган кандидаттар гана кабыл алынат ',
      ],
   },
   {
      id: 5,
      icon: BsClockHistory,
      title: 'Стажировка өтүүнүн форматы жана убактысы ',
      hiddenValue: ['table'],
   },
]

const AboutUsListItem = () => {
   const [openItems, setOpenItems] = useState({})

   const handleClick = (id) => {
      setOpenItems((prevState) => ({
         ...prevState,
         [id]: !prevState[id],
      }))
   }

   return (
      <Container
         maxWidth="lg"
         sx={{
            borderRadius: '10px',
            background: 'white',
            padding: { xs: 2, sm: 3, md: 4, lg: 6 },
            color: 'white',
         }}
      >
         <List>
            {ListText.map((item) => (
               <Box
                  key={item.id}
                  sx={{
                     bgcolor: 'rgba(255, 255, 255)',
                     borderRadius: '4px',
                     overflow: 'hidden',
                  }}
               >
                  <ListItemButton onClick={() => handleClick(item.id)}>
                     <ListItemIcon
                        sx={{
                           color: 'black',
                           bgcolor: 'transparent',
                           minWidth: '20px',
                           maxWidth: '20px',
                           height: '20px',
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           mr: 1,
                        }}
                     >
                        {React.createElement(item.icon)}
                     </ListItemIcon>
                     <ListItemText
                        primary={
                           <Typography
                              sx={{
                                 color: openItems[item.id]
                                    ? 'rgb(25, 130, 205)'
                                    : 'black',
                                 fontWeight: 700,
                                 fontSize: { xs: 16, sm: 18, md: 20 },
                              }}
                           >
                              {item.title}
                           </Typography>
                        }
                     />

                     <ListItemIcon
                        sx={{
                           color: 'black',
                           bgcolor: 'rgb(213, 211, 211)',
                           minWidth: '24px',
                           maxWidth: '24px',
                           height: '24px',
                           display: 'flex',
                           borderRadius: '100px',
                           justifyContent: 'center',
                           alignItems: 'center',
                           mr: 1,
                        }}
                     >
                        {openItems[item.id] ? (
                           <MdKeyboardArrowRight style={{ fontSize: '20px' }} />
                        ) : (
                           <IoIosArrowDown />
                        )}
                     </ListItemIcon>
                  </ListItemButton>
                  <Divider />
                  <Collapse
                     in={openItems[item.id]}
                     timeout="auto"
                     unmountOnExit
                  >
                     <List
                        component="div"
                        sx={{ bgcolor: 'rgba(255, 255, 255)', pl: '35px' }}
                        disablePadding
                     >
                        <ListItemButton sx={{ pl: 4 }}>
                           <ListItemText
                              primary={
                                 <Box
                                    sx={{
                                       color: 'rgb(3, 22, 52)',
                                       display: 'flex',
                                       flexDirection: 'column',
                                       gap: '20px',
                                    }}
                                 >
                                    {item.hiddenValue[0] === 'table' ? (
                                       <AboutUsTable />
                                    ) : (
                                       item.hiddenValue.map((el, idx) => (
                                          <Typography
                                             key={idx}
                                             sx={{
                                                fontSize: '20px',
                                                fontWeight: '400',
                                             }}
                                          >
                                             - {el}
                                          </Typography>
                                       ))
                                    )}
                                 </Box>
                              }
                           />
                        </ListItemButton>
                     </List>
                  </Collapse>
               </Box>
            ))}
         </List>
      </Container>
   )
}

export default AboutUsListItem
