import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

const userSlice = createSlice({
   name: 'user',
   initialState: {
      role: Cookies.get('userRole') || '',
      isAuthenticated: Cookies.get('isAuthenticated') === 'true' || false,
      fullName: Cookies.get('fullName') || '',
      phoneNumber: Cookies.get('phoneNumber') || '',
      email: Cookies.get('email') || '',
      token: Cookies.get('token') || '',
      id: Cookies.get('userID') || '',
   },
   reducers: {
      setUser: (state, action) => {
         state.role = action.payload.role
         state.isAuthenticated = true
         state.fullName = action.payload.fullName
         state.phoneNumber = action.payload.phoneNumber
         state.email = action.payload.email
         state.token = action.payload.token

         Cookies.set('userRole', action.payload.role, { expires: 30 })
         Cookies.set('isAuthenticated', 'true', { expires: 30 })
         Cookies.set('fullName', action.payload.fullName, { expires: 30 })
         Cookies.set('phoneNumber', action.payload.phoneNumber, { expires: 30 })
         Cookies.set('email', action.payload.email, { expires: 30 })
         Cookies.set('token', action.payload.token, { expires: 30 })
         Cookies.set('userID', action.payload.id, { expires: 30 })
      },
      clearUser: (state) => {
         state.role = ''
         state.isAuthenticated = false
         state.fullName = ''
         state.phoneNumber = ''
         state.email = ''
         state.token = ''

         Cookies.remove('userRole')
         Cookies.remove('isAuthenticated')
         Cookies.remove('fullName')
         Cookies.remove('phoneNumber')
         Cookies.remove('email')
         Cookies.remove('token')
         Cookies.remove('userID')
      },
   },
})

export const { setUser, clearUser } = userSlice.actions
export default userSlice.reducer
