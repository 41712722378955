import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
   Container,
   TextField,
   Button,
   Typography,
   Box,
   FormLabel,
   InputAdornment,
} from '@mui/material'
import { useSignInMutation } from '../../store/features/authAPI'
import { useDispatch } from 'react-redux'
import { setUser } from '../../store/features/userAuthSlice'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import SendNotificationFail from '../../components/ui/SendNotificationFail'
import GoogleLoginButton from './GoogleLoginButton'

function Login() {
   const [formData, setFormData] = useState({
      email: '',
      password: '',
   })
   const [signIn, { error, isError, isLoading }] = useSignInMutation()
   const [emailError, setEmailError] = useState('')
   const [authFailModalOpen, setAuthFailModalOpen] = useState(false)
   const [showPass, setShowPass] = useState(false)
   const dispatch = useDispatch()
   const navigate = useNavigate()

   console.log('authFailModalOpen', authFailModalOpen)

   console.log('error', error)
   console.log('isError', isError)
   console.log('error?.data?.message', error?.data?.message)

   const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
   }
   const handleModalClose = () => {
      setAuthFailModalOpen(false)
   }

   const handleChange = (e) => {
      const { name, value } = e.target
      setFormData({ ...formData, [name]: value })

      if (name === 'email') {
         if (!value.trim()) {
            setEmailError('Email талаасы бош болбошу керек')
         } else if (!validateEmail(value)) {
            setEmailError('Туура эмес email форматы')
         } else {
            setEmailError('')
         }
      }
   }

   const handleSubmit = async (e) => {
      e.preventDefault()

      if (!formData.email.trim() || !validateEmail(formData.email)) {
         setEmailError('Туура эмес email форматы')
         return
      }

      try {
         const result = await signIn(formData).unwrap()
         dispatch(setUser(result))
         navigate('/admin')
      } catch (err) {
         console.error('Failed to sign in:', err)
      }
   }

   useEffect(() => {
      if (isError) {
         setAuthFailModalOpen(true)
      }
   }, [isError])

   const inputStyle = {
      '& .MuiInputBase-root': {
         height: '44px',
         marginTop: '4px',
      },
      '& .MuiOutlinedInput-root': {
         '& fieldset': {
            borderColor: 'none',
            border: 'none',
            borderRadius: '10px',
            boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 1)',
            background: 'rgb(255, 255, 255)',
            opacity: '0.1',
         },
      },
      '& .Mui-error .MuiOutlinedInput-notchedOutline': {
         borderColor: 'red',
         borderWidth: '2px',
      },
   }

   const labelStyle = {
      fontWeight: '600',
      color: 'rgba(0, 0, 0, 0.87)',
      marginBottom: '4px',
      fontSize: '14px',
   }

   return (
      <Container maxWidth="sm" sx={{ marginTop: 4, padding: '50px 0 70px 0 ' }}>
         <SendNotificationFail
            open={authFailModalOpen}
            onClose={handleModalClose}
            text={`${error?.data?.message}! Кайра аракет кылып көрүңүз.`}
         />
         <Typography variant="h4" align="center">
            Кош келдиңиз!
         </Typography>

         <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
               border: '1px solid rgba(0, 0, 0, 0.25)',
               boxShadow: '1px 5px 20px rgba(0, 0, 0, 0.25)',
               borderRadius: '10px',
               padding: 3,
               marginTop: 2,
            }}
         >
            <Typography variant="h5" align="center">
               Кирүү
            </Typography>
            <Box sx={{ mb: 2 }}>
               <FormLabel sx={labelStyle}>email*</FormLabel>
               <TextField
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="turatalybaev@gmail.com"
                  required
                  variant="outlined"
                  error={!!emailError}
                  helperText={emailError}
                  sx={inputStyle}
                  inputProps={{
                     type: 'email',
                     pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$',
                  }}
               />
            </Box>

            <Box sx={{ mb: 4 }}>
               <FormLabel sx={labelStyle}>Сыр сөз*</FormLabel>
               <TextField
                  fullWidth
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  type={showPass ? 'text' : 'password'}
                  required
                  variant="outlined"
                  sx={inputStyle}
                  InputProps={{
                     endAdornment: (
                        <InputAdornment position="end">
                           <IconButton
                              onClick={() => setShowPass(!showPass)}
                              edge="end"
                           >
                              {showPass ? <VisibilityOff /> : <Visibility />}
                           </IconButton>
                        </InputAdornment>
                     ),
                  }}
               />
            </Box>
            <Button
               type="submit"
               variant="contained"
               fullWidth
               disabled={isLoading || !!emailError}
               sx={{
                  mt: 2,
                  display: 'flex',
                  alignItems: 'center',
                  width: 168,
                  height: 50,
                  backgroundColor: 'rgb(3,22,52)',
                  color: 'rgb(205,164,7)',
                  margin: 'auto',
               }}
            >
               {isLoading ? 'Кирүүдө...' : 'Кирүү'}
            </Button>

            <GoogleLoginButton />

            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
               <a
                  href="/register"
                  style={{
                     color: 'rgb(161, 158, 158)',
                     textDecoration: 'none',
                     borderBottom: '1px solid rgb(161, 158, 158)',
                     pb: 1,
                  }}
               >
                  Катталуу
               </a>
            </Typography>
         </Box>
      </Container>
   )
}

export default Login
