import React from 'react'
import { Box, Modal, Typography, IconButton, Button } from '@mui/material'
import TrashBag from '../../../assets/svg/TrashBag.svg' // проверьте правильность пути
import CloseIcon from '../../../assets/svg/CloseIcon.svg' // проверьте правильность пути

const DeleteNotificationModal = ({
   deleteOpen,
   handleDeleteClose,
   handleDeleteNotification,
   text = 'Кулактандырууну тизмеден өчүрүү',
}) => {
   return (
      <Modal open={deleteOpen} onClose={handleDeleteClose}>
         <Box
            sx={{
               width: '828px',
               height: '525px',
               bgcolor: 'white',
               borderRadius: '8px',
               boxShadow: 24,
               p: 4,
               mx: 'auto',
               mt: '1vh',
               border: '1px solid #ccc',
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  mb: 2,
                  mt: '-10px',
               }}
            >
               <IconButton onClick={handleDeleteClose}>
                  <img
                     src={CloseIcon}
                     alt="CloseIcon"
                     style={{ width: 36, height: 36 }}
                  />
               </IconButton>
            </Box>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '20px',
                  mt: '-10px',
               }}
            >
               <img
                  src={TrashBag}
                  alt="Описание изображения"
                  style={{ width: '100.75px', height: '150px' }}
               />
            </Box>
            <Typography
               variant="h6"
               textAlign="center"
               marginTop="-10px"
               gutterBottom
               fontSize="32px"
               fontWeight={600}
               maxWidth={'503px'}
               marginInline='auto'
            >
              {text}
               {/* <span style={{ display: 'block', marginLeft: '20px' }}>
                  өчүрүү
               </span> */}
            </Typography>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 3,
                  gap: 9,
               }}
            >
               <Button
                  variant="contained"
                  onClick={handleDeleteNotification}
                  sx={{
                     backgroundColor: 'rgb(205, 164, 7)',
                     color: 'black',
                     width: '115px',
                     height: '40px',
                     fontWeight: '600',
                  }}
               >
                  Ооба
               </Button>
               <Button
                  variant="contained"
                  onClick={handleDeleteClose}
                  sx={{
                     backgroundColor: 'rgb(205, 164, 7)',
                     color: 'black',
                     width: '115px',
                     height: '40px',
                     fontWeight: '600',
                  }}
               >
                  Жок
               </Button>
            </Box>
         </Box>
      </Modal>
   )
}

export default DeleteNotificationModal
