import React, { useState } from 'react'
import { Box, Grid, Checkbox, Snackbar, styled } from '@mui/material'

export const CustomCheckbox = ({ item }) => {
   const [checkedItems, setCheckedItems] = useState({})
   const [showSuccessMessage, setShowSuccessMessage] = useState(false)
   const [successMessage, setSuccessMessage] = useState('')
   const [snackbarStyle, setSnackbarStyle] = useState({})

   const handleCheckboxChange = (item) => {
      setCheckedItems((prev) => {
         const newCheckedItems = { ...prev, [item.id]: !prev[item.id] }
         const message = newCheckedItems[item.id]
            ? 'Пикир башкы бетке кошулду!'
            : 'Пикир башкы беттен алынды!'
         setSuccessMessage(message)
         setShowSuccessMessage(true)
         setSnackbarStyle(
            newCheckedItems[item.id]
               ? { border: '1px solid rgb(23, 241, 17)' }
               : { border: '1px solid rgb(241, 29, 17)' }
         )
         return newCheckedItems
      })
   }

   const handleCloseSuccessMessage = () => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
   }

   return (
      <Box>
         <Grid container spacing={2}>
            <Grid item xs={4}>
               <Grid container justifyContent="flex-end">
                  <Grid item>
                     <Checkbox
                        checked={!!checkedItems[item.id]}
                        onChange={() => handleCheckboxChange(item)}
                        inputProps={{
                           'aria-label': `Checkbox for ${item.name}`,
                        }}
                        sx={{
                           '& .MuiSvgIcon-root': {
                              width: '40px', // Устанавливаем ширину
                              height: '40px', // Устанавливаем высоту
                              borderRadius: '5px',
                              boxShadow:
                                 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                              background: 'rgb(255, 255, 255)',
                           },
                        }}
                     />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
         <Snackbar
            open={showSuccessMessage}
            onClose={handleCloseSuccessMessage}
            autoHideDuration={5000}
            message={successMessage}
            anchorOrigin={{
               vertical: 'top',
               horizontal: 'right',
            }}
            sx={snackbarStyle}
         />
      </Box>
   )
}
