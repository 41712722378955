import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// profileApi.js
export const profileApi = createApi({
   reducerPath: 'profileApi',
   baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
      prepareHeaders: (headers, { getState }) => {
         const token = getState().userAuth.token
         headers.set('Authorization', `Bearer ${token}`)
         return headers
      },
   }),
   endpoints: (builder) => ({
      updateProfile: builder.mutation({
         query: (profileData) => ({
            url: `/users/updateProfile`,
            method: 'PUT',
            params: {
               id: profileData.id,
               imageProfile: profileData.avatar,
               fullName: profileData.name,
               phoneNumber: `${profileData.phone.replace(/\D/g, '')}`,
            },
         }),
      }),
      getProfile: builder.query({
         query: (id) => `/users/getProfile?id=${id}`,
      }),
      uploadImage: builder.mutation({
         query: (link) => ({
            url: '/file',
            method: 'POST',
            body: link,
         }),
      }),
   }),
})

export const {
   useUpdateProfileMutation,
   useGetProfileQuery,
   useUploadImageMutation,
} = profileApi
