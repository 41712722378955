import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
   AppBar,
   Container,
   Box,
   Button,
   IconButton,
   Drawer,
   List,
   ListItem,
   ListItemText,
   Typography,
   useMediaQuery,
   useTheme,
} from '@mui/material'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import { useSelector } from 'react-redux'
import LogoutButton from '../pages/auth/Logout'
import { useGetAllDirectionsDataQuery } from '../store/features/apiSlice'
import LogoutBtn from '../pages/auth/LogoutAdmin'
import { useGetProfileQuery } from '../store/features/profileApi'
import ProfilePicture from '../assets/imgs/profile-picture.svg'

const Header = () => {
   const [drawerOpen, setDrawerOpen] = useState(false)
   const [isOpenDropDown, setIsOpenDropDown] = useState(false)
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('md'))
   const { data } = useGetAllDirectionsDataQuery()
   const userId = useSelector((state) => state.userAuth.id)
   const { data: profileDataFromApi } = useGetProfileQuery(userId)

   const [profileData, setProfileData] = useState({
      id: userId,
      fullName: '',
      phone: '',
      avatar: ProfilePicture,
   })

   const toggleDrawer = (open) => () => {
      setDrawerOpen(open)
   }

   const handleMouseEnter = () => {
      setIsOpenDropDown(true)
   }

   const handleMouseLeave = () => {
      setIsOpenDropDown(false)
   }

   const { isAuthenticated, role } = useSelector((state) => state.userAuth)

   React.useEffect(() => {
      if (profileDataFromApi) {
         setProfileData({
            id: userId,
            avatar: profileDataFromApi.imageProfile || ProfilePicture,
         })
      }
   }, [profileDataFromApi, userId])

   const navLinks = [
      { text: 'Биз тууралуу', href: '/about-us' },
      { text: 'Багыттар', href: '/courses', dropDown: true },
      { text: 'Блог', href: '/blog' },
      { text: 'Контактылар', href: '/contacts' },
   ]
   console.log(profileData)

   return (
      <>
         <AppBar
            position="fixed"
            sx={{
               backgroundColor: '#031634',
               padding: '29px 0 31px 0',
               top: '0',
               left: '0',
               right: '0',
            }}
         >
            <Container
               sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  maxWidth: '1240px !important',
                  width: '100%',
                  margin: '0 auto',
                  padding: '0 20px !important',
                  boxSizing: 'content-box',
               }}
            >
               <Typography
                  variant="h6"
                  sx={{ height: '0px', display: 'flex', alignItems: 'center' }}
               >
                  <Link to="/">
                     <img src="/logo.png" alt="logo" />
                  </Link>
               </Typography>
               {!isMobile && (
                  <Box
                     component="nav"
                     sx={{
                        display: 'flex',
                        gap: '20px',
                        width: '100%',
                        justifyContent: 'space-between',
                     }}
                  >
                     <Box
                        sx={{
                           display: 'flex',
                           width: '100%',
                           justifyContent: 'center',
                           columnGap: '47px',
                        }}
                     >
                        {navLinks.map((link) => (
                           <Link to={link.href} key={link.text}>
                              <Button
                                 onMouseEnter={
                                    link.dropDown ? handleMouseEnter : undefined
                                 }
                                 onMouseLeave={
                                    link.dropDown ? handleMouseLeave : undefined
                                 }
                                 sx={{
                                    color: '#fff',
                                    textTransform: 'none',
                                    fontSize: { md: '16px', lg: '18px' },
                                    '&:hover': {
                                       color: '#CDA407',
                                       textDecoration: 'underline',
                                       textUnderlineOffset: '5px',
                                    },
                                 }}
                              >
                                 {link.text}
                                 {link.dropDown && (
                                    <>
                                       <img
                                          src={
                                             isOpenDropDown
                                                ? '/arrowdown.svg'
                                                : '/arrowdownwhite.svg'
                                          }
                                          alt="arrow"
                                          style={{ marginLeft: '5px' }}
                                       />
                                       <Box
                                          onMouseEnter={handleMouseEnter}
                                          onMouseLeave={handleMouseLeave}
                                          sx={{
                                             display: isOpenDropDown
                                                ? 'flex'
                                                : 'none',
                                             position: 'absolute',
                                             top: '100%',
                                             background: '#031634',
                                             width: '150px',
                                             flexDirection: 'column',
                                             padding: '0 0 0 30px',
                                             alignItems: 'flex-start',
                                          }}
                                       >
                                          {data &&
                                             data.map((el) => (
                                                <Link
                                                   to={`/course/${el.id}`}
                                                   key={el.id}
                                                >
                                                   <Typography
                                                      sx={{
                                                         fontSize: '18px',
                                                         marginBottom: '5px',
                                                         color: '#fff',
                                                         '&:hover': {
                                                            color: '#CDA407',
                                                         },
                                                      }}
                                                   >
                                                      {el.name}
                                                   </Typography>
                                                </Link>
                                             ))}
                                       </Box>
                                    </>
                                 )}
                              </Button>
                           </Link>
                        ))}
                        {role === 'ADMIN' && isAuthenticated && (
                           <Link to="/admin">
                              <Button
                                 sx={{
                                    color: '#fff',
                                    textTransform: 'none',
                                    fontSize: { md: '16px', lg: '18px' },
                                    '&:hover': {
                                       color: '#CDA407',
                                       textDecoration: 'underline',
                                       textUnderlineOffset: '5px',
                                    },
                                 }}
                              >
                                 Админ
                              </Button>
                           </Link>
                        )}
                     </Box>
                     {role !== 'ADMIN' && !isMobile && isAuthenticated ? (
                        <Box
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                           }}
                        >
                           <Link to="/notify">
                              <NotificationsActiveOutlinedIcon
                                 sx={{
                                    fontSize: '35px',
                                    color: 'rgb(205, 164, 7)',
                                 }}
                              />
                           </Link>
                           <Link to="/profile">
                              <Box
                                 component="img"
                                 src={profileData.avatar}
                                 alt="profile"
                                 sx={{
                                    borderRadius: '50%',
                                    width: '60px',
                                    height: '50px',
                                 }}
                              />
                           </Link>
                        </Box>
                     ) : (
                        role !== 'ADMIN' &&
                        !isMobile && (
                           <Link to="/login">
                              <Button
                                 variant="contained"
                                 sx={{
                                    background: '#CDA407',
                                    color: '#fff',
                                    padding: '10px 41px !important',
                                    marginRight: '50px',
                                    '&:hover': { backgroundColor: '#D4B013' },
                                 }}
                              >
                                 Кирүү
                              </Button>
                           </Link>
                        )
                     )}
                  </Box>
               )}
               {role === 'ADMIN' ? <LogoutBtn /> : ''}
               {role !== 'ADMIN' &&
                  isMobile &&
                  (isAuthenticated ? (
                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           gap: 2,
                           padding: '0 30px 0 0',
                        }}
                     >
                        <Link to="/notify">
                           <NotificationsActiveOutlinedIcon
                              sx={{
                                 fontSize: '25px',
                                 color: 'rgb(205, 164, 7)',
                              }}
                           />
                        </Link>
                        <Box
                           component="img"
                           src={profileData.avatar}
                           alt="profile"
                           sx={{
                              borderRadius: '100px',
                              width: '40px',
                              height: '40px',
                           }}
                           onClick={toggleDrawer(true)}
                        />
                     </Box>
                  ) : (
                     <IconButton
                        edge="start"
                        aria-label="menu"
                        sx={{ marginRight: '20px' }}
                     >
                        <img
                           src="/menu.svg"
                           alt=""
                           style={{ height: '30px' }}
                           onClick={toggleDrawer(true)}
                           onClose={toggleDrawer(false)}
                        />
                     </IconButton>
                  ))}
            </Container>
         </AppBar>
         <Drawer anchor="right" open={drawerOpen}>
            <Box
               sx={{
                  width: 250,
                  backgroundColor: '#0A0E24',
                  height: '100%',
                  padding: '20px',
                  color: '#fff',
               }}
               role="presentation"
            >
               <IconButton
                  onClick={toggleDrawer(false)}
                  sx={{
                     color: '#fff',
                     position: 'relative',
                     width: '100%',
                  }}
               >
                  <img
                     src="/close-menu.svg"
                     alt=""
                     style={{
                        height: '20px',
                        position: 'absolute',
                        right: '0',
                     }}
                  />
               </IconButton>
               <ListItem>
                  {role !== 'ADMIN' && isAuthenticated ? (
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           alignItems: 'flex-end',
                           gap: 1,
                        }}
                     >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                           <NotificationsActiveOutlinedIcon
                              sx={{
                                 fontSize: '25px',
                                 color: 'rgb(205, 164, 7)',
                              }}
                           />
                        </Box>
                        <LogoutButton />
                     </Box>
                  ) : (
                     <Link to="/login">
                        <Button
                           variant="contained"
                           sx={{
                              background: '#CDA407',
                              color: '#fff',
                              width: '100%',
                              '&:hover': { backgroundColor: '#D4B013' },
                           }}
                        >
                           Кирүү
                        </Button>
                     </Link>
                  )}
               </ListItem>
               <List>
                  {navLinks.map((link) => (
                     <ListItem button key={link.text}>
                        <ListItemText
                           primary={link.text}
                           onClick={toggleDrawer(false)}
                        />
                     </ListItem>
                  ))}
               </List>
            </Box>
         </Drawer>
      </>
   )
}

export default Header
