import React, { useState } from 'react'
import { Add, Remove } from '@mui/icons-material'
import {
   Collapse,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Typography,
   List,
   Box,
   Container,
} from '@mui/material'
import Divider from '@mui/material/Divider'

const ListText = [
   {
      id: 1,
      title: 'Теориялык билимдерди аласың',
      hiddenValue:
         'Ар бир жандуу лекциядан кийин сени милдеттүү минималдуу үй тапшырмасы жана кошумча практикалык тапшырмалар күтөт.',
   },
   {
      id: 2,
      title: 'Теориялык билимдерди аласың',
      hiddenValue:
         'Арбир лекциядан кийин сени сөзсүз түрдө кичинекей үй тапшырма күтүп турат , жана практикалык тапшырмалар  бонус катарында.',
   },
   {
      id: 3,
      title: 'Теориялык билимдерди аласың',
      hiddenValue:
         'Ар бир жандуу лекциядан кийин сени милдеттүү минималдуу үй тапшырмасы жана кошумча практикалык тапшырмалар күтөт.',
   },
   {
      id: 4,
      title: 'Теориялык билимдерди аласың',
      hiddenValue:
         'Ар бир жандуу лекциядан кийин сени милдеттүү минималдуу үй тапшырмасы жана кошумча практикалык тапшырмалар күтөт.',
   },
   {
      id: 5,
      title: 'Теориялык билимдерди аласың',
      hiddenValue:
         'Ар бир жандуу лекциядан кийин сени милдеттүү минималдуу үй тапшырмасы жана кошумча практикалык тапшырмалар күтөт.',
   },
]

export default function InfoPage() {
   const [openItems, setOpenItems] = useState({})

   const handleClick = (id) => {
      setOpenItems((prevState) => ({
         ...prevState,
         [id]: !prevState[id],
      }))
   }

   return (
      <Container
         maxWidth="lg"
         sx={{
            borderRadius: '10px',
            background: 'rgb(3, 22, 52)',
            padding: { xs: 2, sm: 3, md: 4, lg: 6 },
            color: 'white',
         }}
      >
         <Typography
            variant="h4"
            gutterBottom
            align="left"
            sx={{ mt: 2, mb: 1, fontSize: { xs: 24, sm: 28, md: 30, lg: 36 } }}
         >
            Cтажировка кандай өтөт?
         </Typography>
         <List>
            {ListText.map((item) => (
               <Box
                  key={item.id}
                  sx={{
                     bgcolor: 'rgba(255, 255, 255)',
                     mb: 2,
                     borderRadius: '4px',
                     overflow: 'hidden',
                  }}
               >
                  <ListItemButton onClick={() => handleClick(item.id)}>
                     <ListItemIcon
                        sx={{
                           color: 'white',
                           bgcolor: 'rgb(205, 164, 7);',
                           minWidth: '35px',
                           maxWidth: '35px',
                           height: '35px',
                           borderRadius: '50px',
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           mr: 2,
                        }}
                     >
                        {openItems[item.id] ? <Remove /> : <Add />}
                     </ListItemIcon>
                     <ListItemText
                        primary={
                           <Typography
                              sx={{
                                 color: 'rgb(3, 22, 52)',
                                 fontWeight: 700,
                                 fontSize: '20px',
                              }}
                           >
                              {item.title}
                           </Typography>
                        }
                     />
                  </ListItemButton>
                  <Divider />
                  <Collapse
                     in={openItems[item.id]}
                     timeout="auto"
                     unmountOnExit
                  >
                     <List
                        component="div"
                        sx={{ bgcolor: 'rgba(255, 255, 255)', pl: '35px' }}
                        disablePadding
                     >
                        <ListItemButton sx={{ pl: 4 }}>
                           <ListItemText
                              primary={
                                 <Typography sx={{ color: 'rgb(3, 22, 52)' }}>
                                    {item.hiddenValue}
                                 </Typography>
                              }
                           />
                        </ListItemButton>
                     </List>
                  </Collapse>
               </Box>
            ))}
         </List>
      </Container>
   )
}
