import { Box, Button, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { style } from './AddMentor'
import DeleteIconImg from '../../assets/deleteIcon.png'
import { useDeleteMentorMutation } from '../../store/features/mentorSlice'


const DeleteMentor = ({ handleClose, open, mentorId }) => {
   
   const [deleteMentor, { isLoading }] = useDeleteMentorMutation()

   const handleDeleteConfirm = async () => {
      try {
         await deleteMentor(mentorId).unwrap()
         handleClose("success")
      } catch (error) {
         console.error('Failed to delete mentor:', error)
         handleClose("fail")
      }
   }

  
   
   return (
      <Box>
         
            <Box sx={style}>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'flex-end',
                     marginTop: '-10px',
                  }}
               >
                  <Button
                     onClick={handleClose}
                     sx={{
                        minWidth: '46px',
                        minHeight: '46px',
                        borderRadius: '50%',
                        background: 'rgb(3, 22, 52)',
                        marginLeft: 'auto',
                     }}
                  >
                     <CloseIcon sx={{ color: '#fff' }} />
                  </Button>
               </Box>
               <img
                  style={{
                     margin: '0 auto',
                     maxWidth: '100px',
                     width: '100%',
                  }}
                  src={DeleteIconImg}
                  alt="delete icons"
               />
               <Typography
                  variant="h1"
                  sx={{
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '32px',
                     fontWeight: '600',
                     lineHeight: '39px',
                     color: '#000',
                     textAlign: 'center',
                     margin: '33px 0',
                     '@media (max-width: 700px)': {
                        fontSize: '28px',
                     },
                  }}
               >
                  Менторду тизмеден өчүрүү
               </Typography>
               <Box sx={{ display: 'flex', gap: '64px', margin: '0 auto' }}>
                  <Button
                     onClick={handleDeleteConfirm}
                     disabled={isLoading}
                     sx={{
                        minWidth: '115px',
                        minHeight: '40px',
                        borderRadius: '8px',
                        background: 'rgb(205, 164, 7)',
                        color: '#000',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '17px',
                        '&:disabled': {
                           background: 'rgba(205, 164, 7, 0.5)',
                        },
                     }}
                  >
                     {isLoading ? 'Өчүрүлүүдө...' : 'Ооба'}
                  </Button>
                  <Button
                     onClick={handleClose}
                     sx={{
                        minWidth: '115px',
                        minHeight: '40px',
                        borderRadius: '8px',
                        background: 'rgb(205, 164, 7)',
                        color: '#000',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '17px',
                     }}
                  >
                     Жок
                  </Button>
               </Box>
            </Box>
      </Box>
   )
}

export default DeleteMentor