import React from 'react';
import logo_teh from '../../assets/icons/logo_tehjol.svg';
import { Box, Container, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import EmailIcon from '@mui/icons-material/Email';
import styled from 'styled-components';

const FooterContainer = styled.div`
   background: #031634;
   padding: 20px 0;
   color: #fff;
   font-family: 'Inter', sans-serif;
`;

const FooterText = styled(Typography)`
   width: 350px;
   text-align: left;
   margin-top: -10px;
   color: #ffffff;
   font-size: 16px;
   font-family: 'Inter', sans-serif;
   @media (max-width: 1024px) {
      font-size: 14px;
   }
   @media (max-width: 900px) {
      display: none;
   }
`;

const SectionTitle = styled.h1`
   color: #ffffff;
   font-size: 20px;
   font-weight: 700;
   margin-bottom: 10px;
   font-family: 'Inter', sans-serif;
`;

const ContactItem = styled(Box)`
   display: flex;
   align-items: center;
   margin-bottom: 8px;
   font-size: 16px;
   color: #ffffff;
   p {
      margin-left: 10px;
      font-size: 16px;
      @media (max-width: 1024px) {
         font-size: 14px;
      }
   }
`;

const IconWrapper = styled.span`
   color: rgb(205, 164, 7);
   margin-right: 10px;
`;

const ResponsiveContainer = styled(Box)`
   display: flex;
   gap: 50px;
   @media (max-width: 1024px) and (min-width: 728px) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
   }
   @media (max-width: 727px) {
      flex-direction: column;
   }
`;

const Footer = () => {
   return (
      <FooterContainer>
         <Container>
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: { md: 'center', lg: 'flex-start' }, 
                  justifyContent: 'space-between',
                  gap: { xs: '20px', md: '0' },
               }}
            >
               <Box
                  sx={{
                     textAlign: { xs: 'left', md: 'start' }, 
                     marginBottom: { xs: '20px', md: '0' }, 
                  }}
               >
                  <img src={logo_teh} alt="Company Logo" style={{ marginBottom: '-40px' }} />
                  <FooterText sx={{ fontSize: '11px', marginLeft: '18px' }}>
                     Разработка эффективного решения за
                     <br /> максимально короткие сроки по самым <br />
                     доступным и конкурентным в странах СНГ <br />
                     ценам.
                  </FooterText>
               </Box>

               <ResponsiveContainer>
                  <Box sx={{ mt: "30px" }}>
                     <SectionTitle>Социалдык тармактар</SectionTitle>
                     {[
                        { icon: <InstagramIcon />, label: 'Instagram' },
                        { icon: <TelegramIcon />, label: 'Telegram' },
                        { icon: <WhatsAppIcon />, label: 'WhatsApp' },
                     ].map((item, index) => (
                        <ContactItem key={index}>
                           <IconWrapper>{item.icon}</IconWrapper>
                           <p>{item.label}</p>
                        </ContactItem>
                     ))}
                  </Box>

                  <Box sx={{ mt: "30px" }}>
                     <SectionTitle>Байланыш</SectionTitle>
                     <ContactItem>
                        <IconWrapper><CallIcon /></IconWrapper>
                        <p>+996504188000</p>
                     </ContactItem>
                  </Box>

                  <Box sx={{ mt: "30px" }}>
                     <SectionTitle>Дарек</SectionTitle>
                     <ContactItem>
                        <IconWrapper><FmdGoodIcon /></IconWrapper>
                        <p>Максим Горький 97</p>
                     </ContactItem>
                     <ContactItem>
                        <IconWrapper><EmailIcon /></IconWrapper>
                        <p>info@techjol.kg</p>
                     </ContactItem>
                  </Box>
               </ResponsiveContainer>
            </Box>

            <Box
               sx={{
                  marginLeft: '18px',
                  mt: 3,
                  color: '#FFFF',
                  fontSize: '14px',
                  display: { xs: 'none', md: 'block' }
               }}
            >
               <IconWrapper>Copyright © 2024 </IconWrapper>
            </Box>
         </Container>
      </FooterContainer>
   );
};

export default Footer;
