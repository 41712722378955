// src/apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const scheduleApi = createApi({
   reducerPath: 'scheduleApi',
   baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
      prepareHeaders: (headers, { getState }) => {
         const token = getState().userAuth.token
         headers.set('Authorization', `Bearer ${token}`)
         return headers
      },
   }),

   endpoints: (builder) => ({
      getSchedule: builder.query({
         query: () => 'schedules/getAll',
         providesTags: ['schedules'],
      }),
      setSchedule: builder.mutation({
         query: (newSchedule) => ({
            url: 'schedules/save',
            method: 'POST',
            body: newSchedule,
         }),
         invalidatesTags: ['schedules'],
      }),
   }),
})

export const { useGetScheduleQuery, useSetScheduleMutation } = scheduleApi
