import React, { useState } from 'react'
import {
   Box,
   Button,
   TextareaAutosize,
   Typography,
   Alert,
   Snackbar,
} from '@mui/material'
import { styled } from '@mui/system'
import { useSelector } from 'react-redux'

const StyledTextarea = styled(TextareaAutosize)({
   fontSize: '24px',
   outline: 'none',
   width: '80%',
   maxWidth: '80%',
   borderRadius: '10px',
   padding: '30px 24px',
   marginLeft: '10% ',
   marginTop: '40px',
   marginBottom: '40px',
   minHeight: '200px',
   minWidth: '270px',
   boxSizing: 'border-box',

   '@media (max-width: 780px)': {
      width: '80%',
      maxWidth: '80%',
   },
   '@media (max-width: 650px)': {
      margin: '21px 0',
      maxWidth: '100%',
      width: '100%',
   },
})

const FeedBack = () => {
   const [feedback, setFeedback] = useState('')
   const [isSubmitting, setIsSubmitting] = useState(false)
   const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success',
   })

   const handleChange = (event) => {
      setFeedback(event.target.value)
   }

   const handleCloseSnackbar = () => {
      setSnackbar((prev) => ({ ...prev, open: false }))
   }

   const token = useSelector((state) => state.userAuth.token)

   console.log('toke kajsdfln', token)

   const handleSubmit = async () => {
      if (!feedback.trim()) {
         setSnackbar({
            open: true,
            message: 'Пикир жазыңыз',
            severity: 'error',
         })
         return
      }

      setIsSubmitting(true)

      try {
         const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/reviews`,
            {
               method: 'POST',
               headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify({ text: feedback }),
            }
         )

         if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
         }
         setSnackbar({
            open: true,
            message: 'Пикириңиз ийгиликтүү жөнөтүлдү',
            severity: 'success',
         })
         setFeedback('')
      } catch (error) {
         console.error('Error submitting feedback:', error)
         setSnackbar({
            open: true,
            message: 'Катачылык кетти. Кайра аракет кылыңыз',
            severity: 'error',
         })
      } finally {
         setIsSubmitting(false)
      }
   }

   return (
      <Box
         sx={{
            width: '100%',
            borderRadius: '10px',
            boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            background: 'rgb(3, 22, 52)',
            padding: '31px 0 ',
            margin: '0 auto',
         }}
      >
         <Typography
            sx={{
               color: '#fff',
               fontFamily: 'Inter, sans-serif',
               fontSize: { xs: '40px', sm: '50px', md: '60px' },
               fontWeight: '400',
               textAlign: 'center',
               margin: '0 auto',
               horizontalAlign: 'center',
               '@media (max-width: 650px)': {
                  fontSize: '40px',
                  maxWidth: '250px',
               },
            }}
         >
            Пикир калтырыңыз
         </Typography>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               '@media (max-width: 450px)': {
                  alignItems: 'flex-start',
               },
            }}
         >
            <Box
               sx={{
                  width: '100%',
                  margin: '0 auto',

                  '@media (max-width: 750px)': {
                     padding: '10px',
                  },
               }}
            >
               <StyledTextarea
                  placeholder="Пикир..."
                  value={feedback}
                  onChange={handleChange}
               />
            </Box>
            <Button
               onClick={handleSubmit}
               disabled={isSubmitting}
               sx={{
                  padding: '21px 90px',
                  color: '#fff',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '30px',
                  fontWeight: '400',
                  borderRadius: '10px',
                  margin: '0 auto',
                  horizontalAlign: 'center',
                  background: 'rgba(205, 164, 7, 0.83)',
                  '&:hover': {
                     background: 'rgba(205, 164, 7, 0.9)',
                  },
                  '@media (max-width: 850px)': {
                     padding: '11px 35px',
                     fontSize: '18px',
                  },
               }}
            >
               {isSubmitting ? 'Жөнөтүлүүдө...' : 'Жиберүү'}
            </Button>
         </Box>
         <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={handleCloseSnackbar}
               severity={snackbar.severity}
               sx={{ width: '100%' }}
            >
               {snackbar.message}
            </Alert>
         </Snackbar>
      </Box>
   )
}

export default FeedBack
