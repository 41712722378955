import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'

const ErrorPage = () => {
   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: '#f5f5f5',
            padding: '2rem',
         }}
      >
         <Box
            sx={{
               maxWidth: '600px',
               textAlign: 'center',
               padding: '2rem',
               backgroundColor: 'white',
               borderRadius: '8px',
               boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
         >
            <Typography variant="h2" gutterBottom>
               Упс! Бет табылган жок .
            </Typography>
            <Typography variant="body1" gutterBottom>
               Сиз издеп жаткан бет өчүрүлгөн же башка жака жылган .
               Кыжаалат болбоңуз , биз сизге жардам беребиз  !
            </Typography>
            <Box sx={{ mt: 4 }}>
               <Button
                  component={Link}
                  to="/"
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                     mr: 2,
                  }}
               >
                  Башкы бетке баруу
               </Button>
               <Button
                  component={Link}
                  to="/about-us"
                  variant="outlined"
                  color="primary"
                  size="large"
               >
                  Биз жөнүндө
               </Button>
            </Box>
         </Box>
         <Box sx={{ mt: 4 }}>
            <Typography variant="body2" color="textSecondary">
               Катанын коду: 404
            </Typography>
         </Box>
      </Box>
   )
}

export default ErrorPage
