import {
   Box,
   Button,
   MenuItem,
   Select,
   Typography,
 } from '@mui/material';
 import React, { useEffect } from 'react';
 import { useDispatch } from 'react-redux';
 import CloseIcon from '@mui/icons-material/Close';
 import { profileMembers } from '../../store/memberSlice';
 
 const ModalMember = ({ handleClose, member }) => {
   const dispatch = useDispatch();
   const [paymentHistory, setPaymentHistory] = React.useState('');
 
   useEffect(() => {
     if (member?.id) {
       dispatch(profileMembers(member.id));
     }
   }, [dispatch, member?.id]);
 
   const handleChange = (event) => {
     setPaymentHistory(event.target.value);
   };
 
   const formatDateTime = (dateString) => {
     if (!dateString) return '';
     try {
       const date = new Date(dateString);
       return date.toLocaleDateString('ky-KG', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         second: '2-digit'
       });
     } catch (error) {
       return '';
     }
   };
 
   const renderPaymentHistory = () => {
     const history = member?.paymentResponseForUser?.paymentHistory;
     if (!Array.isArray(history) || history.length === 0) {
       return (
         <MenuItem disabled>
           Төлөмдөр жок
         </MenuItem>
       );
     }
 
     return history.map((payment, index) => (
       <MenuItem key={index} value={payment.id}>
         {formatDateTime(payment.date)} - {payment.amount} сом
       </MenuItem>
     ));
   };
 
   return (
     <Box sx={{
       position: 'absolute',
       top: '50%',
       left: '50%',
       transform: 'translate(-50%, -50%)',
       width: '800px',
       bgcolor: 'background.paper',
       border: '1px solid #000',
       borderRadius: '12px',
       padding: '32px',
       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
       backgroundColor: '#fff',
       '@media (max-width: 900px)': {
         width: '90%',
         padding: '20px',
       },
       '@media (max-width: 600px)': {
         width: '95%',
         padding: '16px',
       }
     }}>
       <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
         <Button
           onClick={handleClose}
           sx={{
             minWidth: '46px',
             minHeight: '46px',
             borderRadius: '50%',
             background: 'rgb(3, 22, 52)',
             '&:hover': {
               background: 'rgb(2, 15, 36)',
             }
           }}
         >
           <CloseIcon sx={{ color: '#fff' }} />
         </Button>
       </Box>
       
       <Box sx={{
         display: 'flex',
         alignItems: 'center',
         gap: '20px',
         marginBottom: 4,
         padding: '0 16px',
         '@media (max-width: 600px)': {
           flexDirection: 'column',
           textAlign: 'center',
         }
       }}>
         <img
           src={member?.direction || 'https://via.placeholder.com/80'}
           alt={member?.fullName || 'User'}
           style={{
             width: '80px',
             height: '80px',
             borderRadius: '50%',
             objectFit: 'cover'
           }}
           onError={(e) => {
             e.target.onerror = null;
             e.target.src = 'https://via.placeholder.com/80';
           }}
         />
         <Typography sx={{
           fontSize: '24px',
           fontWeight: '500',
           '@media (max-width: 600px)': {
             fontSize: '20px',
           }
         }}>
           {member?.fullName || 'Аты-жөнү жок'}
         </Typography>
       </Box>
 
       <Box sx={{
         display: 'flex',
         gap: '20px',
         '@media (max-width: 600px)': {
           flexDirection: 'column',
         }
       }}>
         <Box sx={{
           flex: 1,
           background: 'rgb(249, 212, 70)',
           borderRadius: '8px',
           padding: '20px',
         }}>
           <Typography sx={{
             fontSize: '18px',
             fontWeight: '500',
             textAlign: 'center',
             marginBottom: 2
           }}>
             Жеке маалымат
           </Typography>
 
           <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
             <InfoRow 
               label="Телефон номери:" 
               value={member?.phoneNumber || '+996 XXX XXX XXX'} 
             />
             <InfoRow 
               label="Email:" 
               value={member?.email || 'email@example.com'} 
             />
             <InfoRow 
               label="Катталган күнү:" 
               value={formatDateTime(member?.dateSignUp) || 'Көрсөтүлгөн эмес'} 
             />
             <InfoRow 
               label="Багыты:" 
               value={member?.direction || 'Көрсөтүлгөн эмес'} 
             />
           </Box>
         </Box>
 
         <Box sx={{
           flex: 1,
           background: 'rgb(233, 233, 233)',
           borderRadius: '8px',
           padding: '20px',
         }}>
           <Typography sx={{
             fontSize: '18px',
             fontWeight: '500',
             textAlign: 'center',
             marginBottom: 2
           }}>
             Мүчө акы
           </Typography>
 
           <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
             <InfoRow 
               label="Төлөнгөн датасы:" 
               value={formatDateTime(member?.paymentResponseForUser?.localDate) || '--.--.---- --:--:--'} 
             />
             <InfoRow 
               label="Суммасы:" 
               value={`${member?.paymentResponseForUser?.lastPaymentAmount || '0.00'} сом`}
             />
             
             <Select
               value={paymentHistory}
               onChange={handleChange}
               displayEmpty
               sx={{
                 mt: 1,
                 backgroundColor: 'white',
                 '& .MuiOutlinedInput-notchedOutline': {
                   borderColor: 'rgba(0, 0, 0, 0.23)',
                 },
               }}
             >
               <MenuItem disabled value="">
                 <em>Бардык төлөмдөр</em>
               </MenuItem>
               {renderPaymentHistory()}
             </Select>
           </Box>
         </Box>
       </Box>
     </Box>
   );
 };
 
 const InfoRow = ({ label, value }) => (
   <Box sx={{
     display: 'flex',
     justifyContent: 'space-between',
     alignItems: 'center',
     '@media (max-width: 400px)': {
       flexDirection: 'column',
       alignItems: 'flex-start',
       gap: 1,
     }
   }}>
     <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.87)' }}>
       {label}
     </Typography>
     <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
       {String(value)}
     </Typography>
   </Box>
 );
 
 export default ModalMember;