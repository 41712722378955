import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { style } from './FormBlog'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useDeleteBlogMutation } from '../../store/features/blogSlice'

const DeleteBlog = ({ deleteBlogId, handleClose, getAllBlogs }) => {
   const [openModal, setOpenModal] = React.useState(false)
   const [deleteBlog] = useDeleteBlogMutation()

   const handleOpenModal = async () => {
      try {
         await deleteBlog(deleteBlogId).unwrap()
         getAllBlogs()
         setOpenModal(true)
      } catch (error) {
         console.error('Failed to delete the blog:', error)
      }
   }

   return (
      <Box>
         <Box sx={style}>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '-10px',
               }}
            >
               <Button
                  onClick={handleClose}
                  sx={{
                     minWidth: '46px',
                     minHeight: '46px',
                     borderRadius: '50%',
                     background: 'rgb(3, 22, 52)',
                     marginLeft: 'auto',
                  }}
               >
                  <CloseIcon sx={{ color: '#fff' }} />
               </Button>
            </Box>
            <DeleteForeverIcon
               sx={{ width: '100px', height: '100px', margin: '0 auto' }}
            />
            <Typography
               variant="h1"
               sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '32px',
                  fontWeight: '600',
                  lineHeight: '39px',
                  color: '#000',
                  textAlign: 'center',
                  margin: '33px 0',
                  '@media (max-width: 700px)': {
                     fontSize: '28px',
                  },
               }}
            >
               Блогду тизмеден өчүрүү
            </Typography>
            <Box sx={{ display: 'flex', gap: '64px', margin: '0 auto' }}>
               <Button
                  onClick={handleOpenModal}
                  sx={{
                     minWidth: '115px',
                     minHeight: '40px',
                     borderRadius: '8px',
                     background: 'rgb(205, 164, 7)',
                     color: '#000',
                     fontSize: '14px',
                     fontWeight: '600',
                     lineHeight: '17px',
                  }}
               >
                  Ооба
               </Button>
               <Button
                  onClick={handleClose}
                  sx={{
                     minWidth: '115px',
                     minHeight: '40px',
                     borderRadius: '8px',
                     background: 'rgb(205, 164, 7)',
                     color: '#000',
                     fontSize: '14px',
                     fontWeight: '600',
                     lineHeight: '17px',
                  }}
               >
                  Жок
               </Button>
            </Box>
         </Box>
         <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <Button
                  onClick={handleClose}
                  sx={{
                     minWidth: '46px',
                     minHeight: '46px',
                     borderRadius: '50%',
                     background: 'rgb(3, 22, 52)',
                     marginLeft: 'auto',
                  }}
               >
                  <CloseIcon sx={{ color: '#fff' }} />
               </Button>
               <ErrorOutlineIcon
                  sx={{
                     width: '116px',
                     height: '116px',
                     margin: '0 auto',
                     color: 'red',
                  }}
               />
               <Typography
                  variant="h1"
                  sx={{
                     fontFamily: 'Inter, sans-serif',
                     fontSize: '32px',
                     fontWeight: '600',
                     lineHeight: '39px',
                     color: '#000',
                     textAlign: 'center',
                     margin: '33px auto',
                     width: '503px',
                  }}
               >
                  Блог тизмеден өчүрүлдү
               </Typography>
            </Box>
         </Modal>
      </Box>
   )
}

export default DeleteBlog
