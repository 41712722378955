import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
export const blogUserApi = createApi({
   reducerPath: 'blogApi',
   baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
   endpoints: (builder) => ({
      getAllBlogs: builder.query({
         query: (currentPage) =>
            `/blogs/getAllBlogs?currentPage=${currentPage}`,
      }),
      getBlogsById: builder.query({
         query: (id) => `/blogs/${id}/get`,
      }),
   }),
})

export const { useGetAllBlogsQuery, useGetBlogsByIdQuery } = blogUserApi
