import React, { useState, useEffect } from 'react'
import {
   Box,
   Grid,
   IconButton,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Button,
   TextareaAutosize,
} from '@mui/material'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import CancelIcon from '@mui/icons-material/Cancel'

export const EditItem = ({ item, setData }) => {
   const [openEdit, setOpenEdit] = useState(false)
   const [value, setValue] = useState('')
   const [editSuccessful, setEditSuccessful] = useState(false)

   useEffect(() => {
      if (openEdit) {
         setValue(item.text)
      }
   }, [openEdit, item.text])

   const handleSaveEdit = () => {
      setData((prevData) =>
         prevData.map((currentItem) =>
            currentItem.id === item.id
               ? { ...currentItem, text: value }
               : currentItem
         )
      )
      setEditSuccessful(true)
   }

   const handleEditClick = () => {
      setOpenEdit(true)
   }

   const handleCloseDialog = () => {
      setOpenEdit(false)
      setEditSuccessful(false)
   }

   return (
      <Box>
         <Grid item>
            <IconButton onClick={handleEditClick}>
               <ModeEditIcon sx={{ color: 'black' }} />
            </IconButton>
            <Dialog
               open={openEdit}
               onClose={handleCloseDialog}
               sx={{ width: 714, height: 620, margin: 'auto' }}
            >
               <DialogActions>
                  <IconButton
                     onClick={handleCloseDialog}
                     style={{ marginLeft: 'auto' }}
                  >
                     <CancelIcon />
                  </IconButton>
               </DialogActions>
               {editSuccessful ? (
                  <DialogTitle
                     sx={{
                        width: 500,
                        height: 300,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                     }}
                     variant="h4"
                     textAlign="center"
                  >
                     Пикир өзгөртүлдү!
                  </DialogTitle>
               ) : (
                  <>
                     <DialogTitle variant="h4" textAlign="center">
                        {item.name}
                     </DialogTitle>
                     <DialogContent
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           gap: 5,
                           maxHeight: 350,
                           overflowY: 'auto',
                        }}
                     >
                        <TextareaAutosize
                           style={{
                              width: 500,
                              mHeight: 350,
                              border: '1px solid black',
                              padding: 10,
                           }}
                           value={value}
                           onChange={(e) => setValue(e.target.value)}
                        />
                        <Button
                           sx={{
                              width: 190,
                              height: 60,
                              bgcolor: 'rgb(205, 164, 7)',
                              color: 'black',
                              margin: 'auto',
                           }}
                           onClick={handleSaveEdit}
                        >
                           Өзгөртүү
                        </Button>
                     </DialogContent>
                  </>
               )}
            </Dialog>
         </Grid>
      </Box>
   )
}
