import React from 'react'
import { TextField, Stack, Typography } from '@mui/material'

const Input = ({
   title,
   placeholder,
   multiline = false,
   value,
   onChange,
   error = '',
   helperText = '',
}) => {
   return (
      <Stack direction="row" spacing={1} m="24px 14px">
         <Typography
            variant="h6"
            fontFamily={'Istok Web, sans-serif'}
            fontSize={24}
            component="div"
            minWidth="300px"
         >
            {title}:
         </Typography>
         <TextField
            placeholder={placeholder}
            // fontFamily={'Inter'}
            variant="outlined"
            fullWidth
            multiline={multiline} // enables textarea mode if true
            rows={multiline ? 6 : 1}
            fontFamily="Inter"
            fontWeight={400}
            fontSize="28px"
            sx={{
               // maxWidth: '610px',
               border: '1px solid rgb(210, 210, 211)',
               maxWidth: multiline ? '410px' : '610px',
               boxShadow: `3px 6px 5px -5px rgba(18, 22, 24, 0.6) inset`,
               '& input::placeholder': {
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: '28px',
                  color: 'rgba(0, 0, 0, 0.6)', // Placeholder color (optional)
               },

            }}
            slotProps={{
               htmlInput: {
                  style: {
                     fontFamily: 'Inter',
                     fontWeight: 400,
                     fontSize: multiline ? '18px' : '28px',
                     height: '30px',
                  },
               },
            }}
            required
            type="text"
            value={value}
            onChange={onChange}
            error={error}
            helperText={helperText}
         />
      </Stack>
   )
}

export default Input
