import React, { useState } from 'react'
import {
   Container,
   Box,
   Typography,
   useTheme,
   useMediaQuery,
   Tooltip,
   Snackbar,
} from '@mui/material'
import { GrLanguage } from 'react-icons/gr'
import { MdEmail } from 'react-icons/md'
import { FaTelegram } from 'react-icons/fa'
import { FaLocationDot, FaPhone } from 'react-icons/fa6'
import { IoLogoWhatsapp } from 'react-icons/io'
import { AiFillInstagram } from 'react-icons/ai'

export default function Contact() {
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
   const [snackbarOpen, setSnackbarOpen] = useState(false)

   const contactData = [
      {
         id: 1,
         icon: FaPhone,
         content: '+996 700 700 700',
         action: 'copy',
         href: 'tel:+996700700700',
      },
      {
         id: 2,
         icon: AiFillInstagram,
         content: 'instagram.com',
         action: 'link',
         href: 'https://www.instagram.com',
      },
      {
         id: 3,
         icon: IoLogoWhatsapp,
         content: '+996 700 700 700',
         action: 'link',
         href: 'https://wa.me/996700700700',
      },
      {
         id: 4,
         icon: FaTelegram,
         content: '@techjol',
         action: 'link',
         href: 'https://t.me/techjol',
      },
      {
         id: 5,
         icon: MdEmail,
         content: 'techjol@gmail.com',
         action: 'link',
         href: 'mailto:techjol@gmail.com',
      },
      {
         id: 7,
         icon: GrLanguage,
         content: 'www.techjol.com',
         action: 'link',
         href: 'https://www.techjol.com',
      },
      {
         id: 8,
         icon: FaLocationDot,
         content: 'Горький к., 97',
         action: 'link',
         href: 'https://maps.google.com/?q=Горький к., 97',
      },
   ]

   const handleCopy = (text) => {
      navigator.clipboard.writeText(text)
      setSnackbarOpen(true)
   }

   const handleClick = (el) => {
      if (el.action === 'copy') {
         handleCopy(el.content)
      } else if (el.action === 'link') {
         window.open(el.href, '_blank')
      }
   }

   return (
      <Container maxWidth="lg">
         <Box sx={{ padding: '50px 0 100px 0' }}>
            <Typography
               variant="h4"
               sx={{
                  fontWeight: '700',
                  mb: 8,
                  fontFamily: 'Inter, sans-serif',
                  textAlign: 'center',
               }}
            >
               Байланыш
            </Typography>
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                  flexDirection: { xs: 'column', md: 'row' },
               }}
            >
               <Box
                  sx={{
                     display: 'flex',
                     gap: '10px',
                     flexDirection: 'column',
                     maxWidth: '400px',
                     width: '100%',
                  }}
               >
                  {contactData.map((el, idx) => (
                     <Tooltip
                        key={idx}
                        title={
                           el.action === 'copy'
                              ? 'Көчүрүү үчүн басыңыз'
                              : 'Көчүрүү үчүн басыңыз'
                        }
                     >
                        <Box
                           onClick={() => handleClick(el)}
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '75px',
                              cursor: 'pointer',
                              '&:hover': {
                                 backgroundColor: 'rgba(205, 164, 7, 0.1)',
                                 borderRadius: '4px',
                              },
                              padding: '8px',
                              transition: 'background-color 0.3s',
                           }}
                        >
                           <Box
                              sx={{
                                 fontSize: '35px',
                                 color: 'rgb(205, 164, 7)',
                              }}
                           >
                              <el.icon />
                           </Box>
                           <Typography
                              sx={{
                                 fontSize: '20px',
                                 fontFamily: 'Inter, sans-serif',
                                 fontWeight: '600',
                              }}
                           >
                              {el.content}
                           </Typography>
                        </Box>
                     </Tooltip>
                  ))}
               </Box>
               <Box
                  sx={{
                     maxWidth: '715px',
                     width: '100%',
                     height: isMobile ? '300px' : '450px',
                  }}
               >
                  <iframe
                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2924.7275318977213!2d74.59597087637201!3d42.85748607115106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb7966fa24d3d:0xd7aac2242cef46a6!2sInternational Meta Science University!5e0!3m2!1sru!2skg!4v1727370892058!5m2!1sru!2skg"
                     width="100%"
                     height="100%"
                     style={{ border: '0' }}
                     allowFullScreen=""
                     loading="lazy"
                     referrerPolicy="no-referrer-when-downgrade"
                     title="Map of International Meta Science University"
                  />
               </Box>
            </Box>
         </Box>
         <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
            message="Көчүрүлдүү"
         />
      </Container>
   )
}
