import React, { useState } from 'react'
import { googleLogout } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import { clearUser } from '../../store/features/userAuthSlice'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Snackbar,
   useMediaQuery,
   useTheme,
} from '@mui/material'
import { IoLogOutOutline } from 'react-icons/io5'

const LogoutBtn = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [openDialog, setOpenDialog] = useState(false)
   const [openSnackbar, setOpenSnackbar] = useState(false)
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('md'))

   const handleLogoutClick = () => {
      setOpenDialog(true)
   }

   const handleClose = () => {
      setOpenDialog(false)
   }

   const handleConfirmLogout = () => {
      setOpenDialog(false)

      setOpenSnackbar(true)

      googleLogout()
      Cookies.remove('token')
      dispatch(clearUser())

      setTimeout(() => {
         navigate('/login')
      }, 1500)
   }
   const StyledButton = {
      border: 'none',
      textDecoration: 'none',
      color: 'rgb(205, 164, 7)',
      background: 'none',
      '&.active': {
         background: 'rgb(120, 129, 166)',
         color: 'white',
      },
   }

   return (
      <>
         {isMobile ? (
            <Button
               onClick={handleLogoutClick}
               variant="contained"
               sx={{
                  background: 'none',
                  '&:hover': {
                     color: 'rgb(205, 164, 7)',
                  },
               }}
            >
               <IoLogOutOutline size={25} color="rgb(205, 164, 7)" />
            </Button>
         ) : (
            <Button
               onClick={handleLogoutClick}
               variant="contained"
               sx={{
                  ...StyledButton,
               }}
            >
               <IoLogOutOutline size={25} color="rgb(205, 164, 7)" />
            </Button>
         )}

         <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="logout-dialog-title"
            aria-describedby="logout-dialog-description"
         >
            <DialogTitle id="logout-dialog-title">Чыгууну ырастоо</DialogTitle>
            <DialogContent>
               <DialogContentText id="logout-dialog-description">
                  Сиз чын эле чыгууну каалайсызбы?
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} sx={{ color: 'rgb(3, 22, 52)' }}>
                  Жок
               </Button>
               <Button
                  onClick={handleConfirmLogout}
                  variant="contained"
                  sx={{
                     backgroundColor: 'rgb(3, 22, 52)',
                     color: 'rgb(205, 164, 7)',
                     '&:hover': {
                        backgroundColor: 'rgb(3, 22, 52, 0.9)',
                     },
                  }}
               >
                  Ооба
               </Button>
            </DialogActions>
         </Dialog>
         <Snackbar
            open={openSnackbar}
            autoHideDuration={2000}
            message="Чыгууда..."
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
         />
      </>
   )
}

export default LogoutBtn
