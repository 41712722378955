import React from 'react'
import {
   Box,
   Typography,
   Modal,
   IconButton,
   useTheme,
   useMediaQuery,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { IoWarningOutline } from 'react-icons/io5'

const SendNotificationFail = ({ open, onClose, text='ураныч, кайра аракет кылыңыз!' }) => {
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

   const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 100,
      width: { xs: ' 80%', md: '90%' },
      maxWidth: '715px',
      height: '100%',
      maxHeight: '434px',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      borderRadius: '8px',
      boxShadow: 24,
      p: { xs: '2rem', sm: '3rem', md: '95px 70px 0 70px' }, // Responsive padding
      overflow: 'auto', // Added to handle content overflow
   }

   return (
      <Modal open={open} onClose={onClose}>
         <Box sx={modalStyle}>
            <IconButton
               sx={{
                  position: 'absolute',
                  right: 32,
                  top: 32,
                  bgcolor: 'black',
                  '&:hover': { bgcolor: 'grey.800' },
               }}
               onClick={onClose}
            >
               <CloseIcon sx={{ color: 'white' }} />
            </IconButton>

            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 3,
                  py: 4,
                  height: '60%',
               }}
            >
               <IconButton sx={{ p: 2, color: 'red' }}>
                  <IoWarningOutline
                     style={{
                        fontSize: '100px',
                     }}
                  />
               </IconButton>
               <Typography
                  variant={isMobile ? 'h5' : 'h4'}
                  align="center"
                  fontWeight="bold"
               >
              {text}
               </Typography>
            </Box>
         </Box>
      </Modal>
   )
}

export default SendNotificationFail
