import React, { useState } from 'react'
import {
   Box,
   Grid,
   IconButton,
   Dialog,
   DialogActions,
   Typography,
   Button,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import image from '../../../assets/path.svg'
import CancelIcon from '@mui/icons-material/Cancel'

export const DeleteItem = ({ item, onDelete }) => {
   const [openDelete, setOpenDelete] = useState(false)

   const handleDelete = () => {
      onDelete(item.id)
   }

   const handleClose = () => {
      setOpenDelete(false)
   }

   return (
      <Box>
         <Grid item>
            <IconButton onClick={() => setOpenDelete(true)}>
               <DeleteIcon sx={{ color: 'black' }} />
            </IconButton>
            <Dialog
               open={openDelete}
               onClose={handleClose}
               fullWidth
               maxWidth="sm"
               sx={{
                  width: 714,
                  height: 620,
                  margin: 'auto',
               }}
            >
               <DialogActions
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: 2,
                  }}
               >
                  <IconButton
                     onClick={handleClose}
                     style={{ marginLeft: 'auto' }}
                  >
                     <CancelIcon />
                  </IconButton>
               </DialogActions>
               <Grid sx={{ margin: 5 }}>
                  <img
                     // src={image}
                     alt=""
                     style={{
                        display: 'block',
                        margin: 'auto',
                        maxWidth: '100%',
                     }}
                  />
                  <Typography variant="h6" align="center" sx={{ padding: 2 }}>
                     Пикирди өчүрүү
                  </Typography>
                  <DialogActions sx={{ justifyContent: 'center' }}>
                     <Button
                        sx={{
                           width: 115,
                           height: 40,
                           bgcolor: 'rgb(205, 164, 7)',
                           color: 'black',
                        }}
                        onClick={handleDelete}
                     >
                        Ооба
                     </Button>
                     <Button
                        sx={{
                           width: 115,
                           height: 40,
                           bgcolor: 'rgb(205, 164, 7)',
                           color: 'black',
                        }}
                        onClick={handleClose}
                     >
                        Жок
                     </Button>
                  </DialogActions>
               </Grid>
            </Dialog>
         </Grid>
      </Box>
   )
}
