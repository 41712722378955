import { createBrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import AboutUs from '../pages/AboutUs'
import Header from '../components/Header'
import { PrivateRoute } from './PrivateRoutes'
import Profile from '../components/Profile'
import Direction from '../components/Direction'
import Footer from '../pages/Footer/footer'
import FrontendInfo from '../pages/frontend-info'
import SecondBlog from '../components/SecondBlog'
import Contact from '../pages/Contact'
import Register from '../pages/auth/Register'
import AdminDashboard from '../pages/admin/AdminMainPage'
import Opinions from '../pages/admin/adminOpinions/Opinions'
import NavBar from '../components/navBar/NavBar'
import SponsorsPage from '../pages/admin/sponsorsPage/SponsorsPage'
import AddOrEditPartnersPage from '../pages/admin/sponsorsPage/AddOrEditPartnersPage'
import AdminMentors from '../components/mentor-admin/AdminMentors'
import AddMentor from '../components/mentor-admin/AddMentor'
import EditMentor from '../components/mentor-admin/EditMentor'
import Members from '../components/members/members'
import AdminFooter from '../components/footer/Footer'
import DirectionsPage from '../pages/admin/directionsPage/DirectionsPage'
import AddOrEditDirectionPage from '../pages/admin/directionsPage/AddOrEditDirectionPage'
import IndexNotifications from '../components/admin/notify_admin/IndexNotifications'
import AdminBlogs from '../components/adminBlogs/AdminBlogs'
import FormBlog from '../components/adminBlogs/FormBlog'
import EditBlog from '../components/adminBlogs/EditBlog'
import MainPage from '../pages/mainPage'
import Login from '../pages/auth/Login'
import { ReviewsSlider } from '../components/ReviewsSlider'
import Notify from '../components/notify/Notify'
import { Payment } from '../components/Payment'
import ErrorPage from '../pages/ErrorPage'
import Mentors from '../components/Mentors'
import Partners from '../components/Partners'
import BlogPage from '../pages/blogPage/BlogPage'

const role = 'USER'

const routes = (roles) => {
   return roles.includes(role)
}

const USER_ROLE = {
   USER: 'USER',
   ADMIN: 'ADMIN',
   MENTOR: 'MENTOR',
}

const UserRoute = ({ children }) => {
   const { isAuthenticated, role } = useSelector((state) => state.userAuth)
   return (
      <PrivateRoute
         component={children}
         fallBackPath="/login"
         isAllowed={
            isAuthenticated &&
            [USER_ROLE.USER, USER_ROLE.ADMIN, USER_ROLE.MENTOR].includes(role)
         }
      />
   )
}

const AdminRoute = ({ children }) => {
   const { isAuthenticated, role } = useSelector((state) => state.userAuth)
   return (
      <PrivateRoute
         component={children}
         fallBackPath="/"
         isAllowed={isAuthenticated && role === USER_ROLE.ADMIN}
      />
   )
}

const AdminLayout = ({ children }) => (
   <Box>
      <Header />
      <Box sx={{ display: 'flex', gap: '40px' }}>
         <NavBar />
         {children}
      </Box>
      <AdminFooter />
   </Box>
)

const AuthRoute = ({ children }) => {
   const { isAuthenticated } = useSelector((state) => state.userAuth)
   return (
      <PrivateRoute
         component={children}
         fallBackPath="/"
         isAllowed={!isAuthenticated}
      />
   )
}

export const AppProvider = createBrowserRouter([
   {
      path: '*',
      element: <ErrorPage />,
   },
   {
      path: '/',
      element: (
         <PrivateRoute
            component={
               <>
                  <Header />
                  <MainPage />
                  <Footer />
               </>
            }
            fallBacPath="/admin"
            isAllowed={routes([USER_ROLE.USER])}
         />
      ),
   },
   {
      path: '/about-us',
      element: (
         <PrivateRoute
            component={
               <>
                  <Header />
                  <AboutUs />
                  <Footer />
               </>
            }
            fallBacPath="/admin"
            isAllowed={routes([USER_ROLE.USER])}
         />
      ),
   },
   {
      path: '/courses',
      element: (
         <PrivateRoute
            component={
               <>
                  <Header />
                  <Direction />
                  <Footer />
               </>
            }
            fallBacPath="/admin"
            isAllowed={routes([USER_ROLE.USER])}
         />
      ),
   },
   {
      path: '/courses/frontend',
      element: (
         <PrivateRoute
            component={
               <>
                  <Header />
                  <FrontendInfo />
                  <Footer />
               </>
            }
            fallBacPath="/admin"
            isAllowed={routes([USER_ROLE.USER])}
         />
      ),
   },
   {
      path: '/blog',
      element: (
         <PrivateRoute
            component={
               <>
                  <Header />
                  <SecondBlog />
                  <Footer />
               </>
            }
            fallBacPath="/admin"
            isAllowed={routes([USER_ROLE.USER])}
         />
      ),
   },
   {
      path: '/contacts',
      element: (
         <PrivateRoute
            component={
               <>
                  <Header />
                  <Contact />
                  <Footer />
               </>
            }
            fallBacPath="/admin"
            isAllowed={routes([USER_ROLE.USER])}
         />
      ),
   },
   {
      path: '/profile',
      element: (
         <PrivateRoute
            component={
               <>
                  <Header />
                  <Profile />
                  <Footer />
               </>
            }
            fallBacPath="/admin"
            isAllowed={routes([USER_ROLE.USER])}
         />
      ),
   },
   {
      path: '/register',
      element: (
         <PrivateRoute
            component={
               <>
                  <Header />
                  <Register />
                  <Footer />
               </>
            }
            fallBacPath="/admin"
            isAllowed={routes([USER_ROLE.USER, USER_ROLE.ADMIN])}
         />
      ),
   },
   {
      path: '/login',
      element: (
         <AuthRoute>
            <Header />
            <Login />
            <Footer />
         </AuthRoute>
      ),
   },
   {
      path: '/about-us',
      element: (
         <>
            <Header />
            <AboutUs />
            <Footer />
         </>
      ),
   },
   {
      path: '/courses',
      element: (
         <>
            <Header />
            <Direction />
            <Footer />
         </>
      ),
   },
   {
      path: '/course/frontend',
      element: (
         <>
            <Header />
            <FrontendInfo />
            <Footer />
         </>
      ),
   },
   {
      path: '/blog',
      element: (
         <>
            <Header />
            <SecondBlog />
            <Footer />
         </>
      ),
   },
   {
      path: '/blog/:id',
      element: (
         <PrivateRoute
            component={
               <>
                  <Header />
                  <BlogPage />
                  <Footer />
               </>
            }
            fallBacPath="/admin"
            isAllowed={routes([USER_ROLE.USER])}
         />
      ),
   },
   {
      path: '/contacts',
      element: (
         <>
            <Header />
            <Contact />
            <Footer />
         </>
      ),
   },
   {
      path: '/profile',
      element: (
         <UserRoute>
            <Header />
            <Profile />
            <Footer />
         </UserRoute>
      ),
   },
   {
      path: '/register',
      element: (
         <AuthRoute>
            <Header />
            <Register />
            <Footer />
         </AuthRoute>
      ),
   },
   {
      path: '/payment',
      element: (
         <UserRoute>
            <Header />
            <Payment />
            <Footer />
         </UserRoute>
      ),
   },
   {
      path: '/notify',
      element: (
         <UserRoute>
            <Header />
            <Notify />
            <Footer />
         </UserRoute>
      ),
   },
   // ----------------------------- ADMIN ROUTES ---------------------------------
   {
      path: '/admin',
      element: (
         <AdminRoute>
            <AdminLayout>
               <AdminDashboard />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin-directions',
      element: (
         <AdminRoute>
            <AdminLayout>
               <DirectionsPage />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin-directions/edit/:id',
      element: (
         <AdminRoute>
            <AdminLayout>
               <AddOrEditDirectionPage />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin/add-directions',
      element: (
         <AdminRoute>
            <AdminLayout>
               <AddOrEditDirectionPage />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin-partners',
      element: (
         <AdminRoute>
            <AdminLayout>
               <SponsorsPage />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin/add-sponsors',
      element: (
         <AdminRoute>
            <AdminLayout>
               <AddOrEditPartnersPage />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin-partners/edit/:id',
      element: (
         <AdminRoute>
            <AdminLayout>
               <AddOrEditPartnersPage />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin-mentors',
      element: (
         <AdminRoute>
            <AdminLayout>
               <AdminMentors />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/add-mentor',
      element: (
         <AdminRoute>
            <AdminLayout>
               <AddMentor />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/edit-mentor/:id',
      element: (
         <AdminRoute>
            <AdminLayout>
               <EditMentor />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin-opinions',
      element: (
         <AdminRoute>
            <AdminLayout>
               <Opinions />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin-blogs',
      element: (
         <AdminRoute>
            <AdminLayout>
               <AdminBlogs />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/form-blog',
      element: (
         <AdminRoute>
            <AdminLayout>
               <FormBlog />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/edit-blog',
      element: (
         <AdminRoute>
            <AdminLayout>
               <EditBlog />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin-participants',
      element: (
         <AdminRoute>
            <AdminLayout>
               <Members />
            </AdminLayout>
         </AdminRoute>
      ),
   },
   {
      path: '/admin-notifications',
      element: (
         <AdminRoute>
            <AdminLayout>
               <IndexNotifications />
            </AdminLayout>
         </AdminRoute>
      ),
   },
])
