import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
   Container,
   TextField,
   Button,
   Typography,
   Box,
   MenuItem,
   FormLabel,
   InputAdornment,
   IconButton,
   Snackbar,
   Alert,
} from '@mui/material'
import { useSignUpMutation } from '../../store/features/authAPI'
import { useDispatch } from 'react-redux'
import { setUser } from '../../store/features/userAuthSlice'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import GoogleLoginButton from './GoogleLoginButton'

const Register = () => {
   const [showPass, setShowPass] = useState(false)
   const [googleAuthData, setGoogleAuthData] = useState(null)
   const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'info',
   })
   const [formData, setFormData] = useState({
      fullName: '',
      email: '',
      phoneNumber: '',
      directionID: 0,
      password: '',
      confirmPassword: '',
   })

   const [signUp, { isLoading }] = useSignUpMutation()
   const [isSecondStep, setIsSecondStep] = useState(false)
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleSnackbarClose = () => {
      setSnackbar({ ...snackbar, open: false })
   }

   const showNotification = (message, severity = 'error') => {
      setSnackbar({
         open: true,
         message,
         severity,
      })
   }

   const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value })
   }

   const validateFirstStep = () => {
      if (!formData.fullName.trim()) {
         showNotification('Сураныч, толук аты жөнүңүздү киргизиңиз 📝')
         return false
      }

      if (!googleAuthData && !formData.email.trim()) {
         showNotification('Сураныч, email дарегиңизди киргизиңиз 📧')
         return false
      }

      if (!googleAuthData) {
         if (!formData.password) {
            showNotification('Сураныч, сыр сөздү киргизиңиз 🔑')
            return false
         }
         if (formData.password !== formData.confirmPassword) {
            showNotification('Сыр сөздөр дал келбейт! 🚫')
            return false
         }
      }

      return true
   }

   const handleGoogleData = (data) => {
      setGoogleAuthData(data)
      const payloadBase64 = data.tokenId.split('.')[1]
      const payload = JSON.parse(atob(payloadBase64))
      setFormData((prev) => ({
         ...prev,
         email: payload.email || '',
      }))
      showNotification('Google менен ийгиликтүү кирдиңиз! 🎉', 'success')
   }

   const handleNextStep = () => {
      if (validateFirstStep()) {
         setIsSecondStep(true)
         showNotification('Биринчи кадам ийгиликтүү! 🎯', 'success')
      }
   }

   const handleSubmit = async (e) => {
      e.preventDefault()

      if (!isSecondStep) {
         handleNextStep()
         return
      }

      if (!formData.phoneNumber.trim() || formData.directionID === 0) {
         showNotification('Сураныч, бардык талааларды толтуруңуз 📱')
         return
      }

      try {
         let submitData = {
            fullName: formData.fullName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            directionID: formData.directionID,
            password: formData.password,
            confirmPassword: formData.confirmPassword,
         }

         const result = await signUp(submitData).unwrap()
         dispatch(setUser(result))
         showNotification('Ийгиликтүү катталдыңыз! 🎊', 'success')

         setTimeout(() => {
            if (result.role === 'ADMIN') {
               navigate('/admin')
            } else {
               navigate('/')
            }
         }, 1500)
      } catch (err) {
         console.error('Катталуу ийгиликсиз болду:', err)
         showNotification('Катталуу ийгиликсиз болду. Кайра аракет кылыңыз 😕')
      }
   }

   const inputStyle = {
      '& .MuiInputBase-root': {
         height: '44px',
         marginTop: '4px',
      },
      '& .MuiOutlinedInput-root': {
         '& fieldset': {
            borderColor: 'none',
            border: 'none',
            borderRadius: '10px',
            boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 1)',
            background: 'rgb(255, 255, 255)',
            opacity: '0.1',
         },
      },
   }

   const labelStyle = {
      fontWeight: '600',
      color: 'rgba(0, 0, 0, 0.87)',
      marginBottom: '4px',
      fontSize: '14px',
   }

   console.log('isSecondStep', isSecondStep)

   return (
      <Container
         maxWidth="sm"
         sx={{ marginTop: 4, paddingTop: '50px', paddingBottom: '100px' }}
      >
         <Snackbar
            open={snackbar.open}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={handleSnackbarClose}
               severity={snackbar.severity}
               sx={{
                  width: '100%',
                  '& .MuiAlert-message': {
                     fontSize: '1rem',
                     display: 'flex',
                     alignItems: 'center',
                     gap: '8px',
                  },
                  '& .MuiAlert-icon': {
                     fontSize: '1.5rem',
                  },
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                  borderRadius: '10px',
               }}
            >
               {snackbar.message}
            </Alert>
         </Snackbar>
         <Typography variant="h4" align="center" sx={{ fontWeight: '600' }}>
            Tech Jolgo кош келиңиз!
         </Typography>

         <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
               mt: 4,
               p: 3,
               border: '1px solid rgba(0,0,0,0.25)',
               borderRadius: '10px',
               boxShadow: '1px 5px 20px rgba(0,0,0,0.25)',
            }}
         >
            <Typography
               variant="h5"
               align="center"
               sx={{ mb: 3, fontWeight: '400' }}
            >
               Катталуу
            </Typography>

            {!isSecondStep && (
               <Box>
                  {/* <GoogleLoginButton/> */}

                  <Box sx={{ mb: 2 }}>
                     <FormLabel sx={labelStyle}>Толук аты жөнүңүз*</FormLabel>
                     <TextField
                        fullWidth
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        placeholder="Алыбаев Турат"
                        required
                        variant="outlined"
                        sx={inputStyle}
                     />
                  </Box>
                  {!!googleAuthData ? null : (
                     <Box sx={{ mb: 2 }}>
                        <FormLabel sx={labelStyle}>Email*</FormLabel>
                        <TextField
                           fullWidth
                           name="email"
                           value={formData.email}
                           onChange={handleChange}
                           placeholder="turatalybaev@gmail.com"
                           type="email"
                           required
                           variant="outlined"
                           sx={inputStyle}
                           disabled={!!googleAuthData}
                        />
                     </Box>
                  )}

                  <>
                     <Box sx={{ mb: 2 }}>
                        <FormLabel sx={labelStyle}>Сыр сөз*</FormLabel>
                        <TextField
                           fullWidth
                           name="password"
                           value={formData.password}
                           onChange={handleChange}
                           type={showPass ? 'text' : 'password'}
                           required
                           variant="outlined"
                           sx={inputStyle}
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position="end">
                                    <IconButton
                                       onClick={() => setShowPass(!showPass)}
                                       edge="end"
                                    >
                                       {showPass ? (
                                          <VisibilityOff />
                                       ) : (
                                          <Visibility />
                                       )}
                                    </IconButton>
                                 </InputAdornment>
                              ),
                           }}
                        />
                     </Box>

                     <Box sx={{ mb: 3 }}>
                        <FormLabel sx={labelStyle}>
                           Сыр сөздү кайталаңыз*
                        </FormLabel>
                        <TextField
                           fullWidth
                           name="confirmPassword"
                           value={formData.confirmPassword}
                           onChange={handleChange}
                           type={showPass ? 'text' : 'password'}
                           required
                           variant="outlined"
                           sx={inputStyle}
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position="end">
                                    <IconButton
                                       onClick={() => setShowPass(!showPass)}
                                       edge="end"
                                    >
                                       {showPass ? (
                                          <VisibilityOff />
                                       ) : (
                                          <Visibility />
                                       )}
                                    </IconButton>
                                 </InputAdornment>
                              ),
                           }}
                        />
                     </Box>
                  </>
               </Box>
            )}

            {isSecondStep && (
               <Box>
                  <Box sx={{ mb: 2 }}>
                     <FormLabel sx={labelStyle}>Телефон номери*</FormLabel>
                     <TextField
                        fullWidth
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder="+996 700 700 700"
                        type="tel"
                        required
                        variant="outlined"
                        sx={inputStyle}
                     />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                     <FormLabel sx={labelStyle}>Багыт*</FormLabel>
                     <TextField
                        fullWidth
                        select
                        name="directionID"
                        value={formData.directionID}
                        onChange={handleChange}
                        required
                        variant="outlined"
                        sx={inputStyle}
                     >
                        <MenuItem value={0} disabled>
                           Тандаңыз
                        </MenuItem>
                        <MenuItem value={1}>Frontend</MenuItem>
                        <MenuItem value={2}>Backend</MenuItem>
                        <MenuItem value={3}>Flutter</MenuItem>
                     </TextField>
                  </Box>
               </Box>
            )}

            <Button
               onClick={handleSubmit}
               variant="contained"
               fullWidth
               disabled={isLoading}
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 168,
                  height: 50,
                  backgroundColor: 'rgb(3, 22, 52)',
                  color: 'rgb(205, 164, 7)',
                  margin: 'auto',
                  '&:hover': {
                     backgroundColor: 'rgb(3, 22, 52, 0.9)',
                  },
               }}
               type="submit"
            >
               {isSecondStep ? 'Катталуу' : 'Кийинки'}
            </Button>

            <GoogleLoginButton />

            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
               <a
                  href="/login"
                  style={{
                     color: 'rgb(161, 158, 158)',
                     textDecoration: 'none',
                     borderBottom: '1px solid rgb(161, 158, 158)',
                     pb: 1,
                  }}
               >
                  Кирүү
               </a>
            </Typography>
         </Box>
      </Container>
   )
}

export default Register
