// PaginationComponent.jsx
import React from 'react';
import { Box, Pagination } from '@mui/material';

const PaginationComponent = ({ count, page, onPageChange }) => {
   return (
      <Box sx={{ display: 'flex', justifyContent: 'left', mt: 2 }}>
         <Pagination
            count={count}
            page={page}
            onChange={onPageChange}
            showFirstButton
            showLastButton
            sx={{
               '& .MuiPaginationItem-root': {
                  width: '40px',
                  height: '40px',
                  borderRadius: '0px',
                  border: '1px solid rgb(0, 0, 0)',
                  bgcolor: 'rgb(255, 255, 255)',
                  margin: '0',
               },
               '& .MuiPaginationItem-root:not(:last-child)': {
                  borderRight: 'none',
               },
               '& .MuiPaginationItem-root:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
               },
            }}
         />
      </Box>
   );
};

export default PaginationComponent;
