import React from 'react'
import DirectionForm from '../../../components/admin/formSection/DirectionForms'
import { useGetDirectionByIdQuery } from '../../../store/features/apiSlice'
import { useParams } from 'react-router-dom'

const AddOrEditDirectionPage = () => {
   const { id } = useParams()
   const isEditMode = Boolean(id)
   const { data: initialData, isLoading } = useGetDirectionByIdQuery(id, {
      skip: !isEditMode,
   })
   if (isLoading) return <div>Loading...</div>
   return <DirectionForm initialData={initialData} isEditMode={isEditMode} />
}

export default AddOrEditDirectionPage
