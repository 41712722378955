import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

export const authApi = createApi({
   reducerPath: 'authApi',
   baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
   endpoints: (builder) => ({
      googleSignIn: builder.mutation({
         query: (tokenData) => ({
            url: `/auth/withGoogle?tokenId=${tokenData}`,
            method: 'POST',
         }),
         onQueryStarted: async (_, { queryFulfilled }) => {
            try {
               const { data } = await queryFulfilled
               Cookies.set('token', data.token, { expires: 7 })
            } catch (err) {
               console.error('Error setting token:', err)
            }
         },
      }),

      signUp: builder.mutation({
         query: (userData) => ({
            url: '/auth/signUp',
            method: 'POST',
            body: userData,
         }),
         onQueryStarted: async (_, { queryFulfilled }) => {
            try {
               const { data } = await queryFulfilled
               Cookies.set('token', data.token, { expires: 7 })
            } catch (err) {
               console.error('Error setting token:', err)
            }
         },
      }),
      signIn: builder.mutation({
         query: (credentials) => ({
            url: '/auth/signIn',
            method: 'POST',
            body: credentials,
         }),
         onQueryStarted: async (_, { queryFulfilled }) => {
            try {
               const { data } = await queryFulfilled
               Cookies.set('token', data.token, { expires: 7 })
            } catch (err) {
               console.error('Error setting token:', err)
            }
         },
      }),
   }),
})

export const { useGoogleSignInMutation, useSignUpMutation, useSignInMutation } =
   authApi
