import React from 'react'
import { styled } from '@mui/material'

const StyledFooter = styled('footer')({
   backgroundColor: '#001b37',
   color: '#f0c100',
   padding: '10px',
   textAlign: 'center',
   position: 'fixed',
   bottom: 0,
   width: '100%',
})

const Footer = () => {
   return (
      <StyledFooter>
         <p>Copyright © 2024</p>
      </StyledFooter>
   )
}

export default Footer
