import { Box, Stack, Button } from '@mui/material'
import ImgExample from '../../../assets/imgs/img.png'
import ImageIcon from './ImageIcon.jsx'
import { usePostImageMutation } from '../../../store/features/blogSlice.js'
import { useEffect, useState } from 'react'
const ChoosePhoto = ({ photo, onPhotoChange, isEditMode }) => {
   const [postImage, { data: imageData }] = usePostImageMutation()
   const [title, setTitle] = useState('')

   useEffect(() => {
      if (imageData) {
         onPhotoChange(imageData.link)
      }
   }, [imageData])

   const handleImageUpload = async (event) => {
      const file = new FormData()
      file.append('file', event.target.files[0])
      await postImage(file)
   }

   useEffect(() => {
      setTitle(isEditMode ? 'Cүрөттү өзгөртүү' : 'Cүрөттү тандоо')
   }, [isEditMode])
   return (
      <Stack direction={'row'} alignItems={'end'} spacing={2} m={'20px'}>
         <Box
            width="220px"
            height="250px"
            borderRadius="8px"
            backgroundColor="rgb(205, 164, 7)"
            display="flex"
            justifyContent={'center'}
            alignItems={'center'}
         >
            <img src={photo || ImgExample} alt="Selected" />
         </Box>
         <input
            accept="image/*"
            id="file-input"
            type="file"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            required
         />

         {/* Custom input with icon */}
         <label htmlFor="file-input">
            <Button
               variant="outlined"
               component="div"
               startIcon={<ImageIcon />}
               sx={{
                  paddingBlock: '10px',
                  border: '1px solid rgb(108, 108, 115)',
                  boxShadow: `3px 6px 5px -5px rgba(18, 22, 24, 0.6) inset`,
                  borderRadius: '8px',
                  width: '219px',
                  fontSize: '18px ',
                  textTransform: 'none',
                  color: 'rgb(77, 75, 75)',
                  justifyContent: 'start',
                  gap: '5px',
               }}
            >
               {title}
            </Button>
         </label>
      </Stack>
   )
}

export default ChoosePhoto
