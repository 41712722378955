import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import { IoCardOutline } from 'react-icons/io5'
import LogoutButton from '../pages/auth/Logout'

const StyledBox = {
   display: { xs: 'none', sm: 'none', md: 'flex' },
   flexDirection: 'column',
   alignItems: 'center',
   pt: '130px',
   gap: '35px',
   background: 'rgb(239, 239, 239)',
   width: '100%',
   maxWidth: '500px',
}

const StyledButton = {
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'start',
   pl: '23px',
   gap: '20px',
   borderRadius: '10px',
   textDecoration: 'none',
   color: 'rgb(205, 164, 7)',
   width: '341px',
   height: '41px',
   boxShadow: '3px 5px 5px rgba(0, 0, 0, 0.3)',
   background: 'white',
   '&.active': {
      background: 'rgb(120, 129, 166)',
      color: 'white',
   },
}

const StyledLink = {
   color: 'black',
   fontWeight: '400',
   fontSize: '18px',
   textTransform: 'none',
}

export default function ProfileNav() {
   return (
      <Box sx={StyledBox}>
         <NavLink to="/profile" style={{ textDecoration: 'none' }}>
            {({ isActive }) => (
               <Button
                  sx={{
                     ...StyledButton,
                     ...(isActive && StyledButton['&.active']),
                  }}
               >
                  <AccountCircleOutlinedIcon
                     sx={{ fontSize: '25px', color: 'rgb(205, 164, 7)' }}
                  />
                  <Typography sx={StyledLink}>Профиль</Typography>
               </Button>
            )}
         </NavLink>
         <NavLink to="/payment" style={{ textDecoration: 'none' }}>
            {({ isActive }) => (
               <Button
                  sx={{
                     ...StyledButton,
                     ...(isActive && StyledButton['&.active']),
                  }}
               >
                  <IoCardOutline
                     style={{ fontSize: '25px', color: 'rgb(205, 164, 7)' }}
                  />
                  <Typography sx={StyledLink}>Төлөм</Typography>
               </Button>
            )}
         </NavLink>
         <LogoutButton/>
      </Box>
   )
}
