import styled from '@emotion/styled'
import { Stack, Typography, Box } from '@mui/material'

export const StackItems = styled(Stack)`
   max-width: 1152px;
   gap: 20px;
   width:100%;
   margin-inline:auto;
  
   
`
export const AdminSectionName = styled(Typography)`
   font:
      600 36px 'Inter',
      sans-serif;
   color: rgb(0, 0, 0);
   flex-grow: 2;
   display: flex;
   justify-content: center;

   @media (max-width: 450px) {
      font-size: 30px;
   }
`

export const BoxItem = styled(Box)`
   width: 100%;
   justify-content: space-between;
   display: flex;
   padding-top: 48px;
   padding-bottom: 28px;
   gap: 15px;
`
export const ButtonBox = styled(Box)`
   max-width: 500px;
   display: flex;
   justify-content: end;
`
