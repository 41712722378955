import React, { useState } from 'react'
import RedDeleteIcon from '../../../assets/svg/RedDeleteIcon.svg'
import BlackDeleteIcon from '../../../assets/svg/BlackDeleteIcon.svg'
import VisibilityIcon from '../../../assets/svg/VisibilityIcon.svg'
import { Paper, ListItem, Box, IconButton } from '@mui/material'
export default function NotificationListItem({
   notification,
   index,
   handleDeleteOpen,
   handleViewNotification,
   notificationsPerPage,
   page,
}) {
   const [isRed, setIsRed] = useState()

   return (
      <Paper
         key={notification.id}
         elevation={3}
         sx={{
            mb: 2,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
            border: 'none',
            borderRadius: '12px',
            padding: '12px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 500,
            backgroundColor: 'rgba(255, 255, 255, 1)',
         }}
      >
         <ListItem
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
            }}
         >
            {/* Индекс уведомления */}
            <Box
               sx={{
                  flex: '0 0 auto',
                  mr: 2,
                  fontSize: '24px',
                  fontWeight: '600',
               }}
            >
               {(page - 1) * notificationsPerPage + (index + 1)}
            </Box>

            {/* Тема уведомления */}
            <Box
               sx={{
                  flex: '1 1 auto',
                  marginLeft: 10,
                  fontSize: '22px',
                  fontWeight: 400,
                  color: 'rgba(0, 0, 0, 0.87)',
               }}
            >
               {notification.message.subject}
            </Box>

            {/* Дата уведомления */}
            <Box
               sx={{
                  flex: '0 0 auto',
                  marginRight: 20,
                  fontSize: '20px',
                  fontWeight: 500,
               }}
            >
               {new Date(notification.created).toLocaleDateString()}
            </Box>

            {/* Кнопки действий */}
            {/* View and button  */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
               <IconButton
                  edge="end"
                  aria-label="view"
                  onClick={() => handleViewNotification(notification)}
                  sx={{
                     width: '36px',
                     height: '36px',
                     border: '1px solid rgba(0, 0, 0, 0.12)',
                     borderRadius: '4px',
                     fontFamily: 'Inter, sans-serif',
                     boxShadow: '0px 0px 4px rgba(128, 128, 128, 1)',
                     '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        boxShadow: '0px 0px 6px rgba(128, 128, 128, 1)',
                     },
                     mr: 4.6,
                  }}
               >
                  <img
                     src={VisibilityIcon}
                     alt="View"
                     style={{
                        width: '16px',
                        height: '16px',
                     }}
                  />
               </IconButton>
               <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => (
                     handleDeleteOpen(notification), setIsRed(notification.id)
                  )}
                  sx={{
                     width: '36px',
                     height: '36px',
                     marginRight: '10px',
                     border: '1px solid rgba(0, 0, 0, 0.12)',
                     fontFamily: 'Inter, sans-serif',
                     borderRadius: '4px',
                     boxShadow: '0px 0px 4px rgba(128, 128, 128, 1)',
                     '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        boxShadow: '0px 0px 6px rgba(128, 128, 128, 1)',
                     },
                  }}
               >
                  <img
                     src={
                        isRed === notification.id
                           ? RedDeleteIcon
                           : BlackDeleteIcon
                     }
                     alt="Delete"
                     style={{
                        width: '16px',
                        height: '16px',
                     }}
                  />
               </IconButton>
            </Box>
         </ListItem>
      </Paper>
   )
}
