import {
   Box,
   Button,
   Card,
   Container,
   Modal,
   Typography,
   CircularProgress,
} from '@mui/material'
import { useState, forwardRef } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteMentor from './DeleteMentor'
import { useGetAllMentorsQuery } from '../../store/features/mentorSlice'
import SendNotificationFail from '../ui/SendNotificationFail'
import SendNotificationSuccess from '../ui/SendNotificationSuccess'

const AdminMentors = () => {
   const {
      data: mentorsData,
      error,
      isLoading,
      isError,
   } = useGetAllMentorsQuery({})
   const [showSuccessModal, setShowSuccessModal] = useState(false)
   const [showErrorModal, setShowErrorModal] = useState(false)
   const [open, setOpen] = useState(false)
   const [selectedMentorId, setSelectedMentorId] = useState(null)

   const handleOpen = (mentorId) => {
      setSelectedMentorId(mentorId)
      setOpen(true)
   }
   const handleClose = (status) => {
      setSelectedMentorId(null)
      setOpen(false)
      if (status === 'success') {
         setShowSuccessModal(true)
      } else {
         setShowErrorModal(true)
      }
   }
   const handleCloseSuccessModal = () => {
      setShowSuccessModal(false)
   }

   const handleCloseErrorModal = () => {
      setShowErrorModal(false)
   }

   console.log('showSuccessModal', error)
   console.log('showErrorModal', showErrorModal)

   if (isLoading) {
      return (
         <Container>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '400px',
               }}
            >
               <CircularProgress />
            </Box>
         </Container>
      )
   }

   const mentors = mentorsData?.mentors || []

   const WrappedDeleteMentor = forwardRef((props, ref) => (
      <Box ref={ref}>
         <DeleteMentor {...props} />
      </Box>
   ))

   return (
      <Container>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               padding: '50px 0',
            }}
         >
            {/* Success Notification */}
            <SendNotificationSuccess
               open={showSuccessModal}
               onClose={handleCloseSuccessModal}
               text="Ментор ийгиликтүү өчүрүлдү"
            />

            {/* Error Notification */}
            <SendNotificationFail
               open={showErrorModal}
               onClose={handleCloseErrorModal}
               text="Менторду тизмеден өчүрүүдө ката кетти кайра аракет кылып көрүңүз"
            />
            <Box
               sx={{
                  display: 'flex',
                  width: '60%',
                  justifyContent: 'space-between',
                  justifyItems: 'flex-end',
                  marginLeft: 'auto',
                  '@media (max-width: 700px)': {
                     justifyItems: 'flex-start',
                     width: '100%',
                  },
               }}
            >
               <Typography
                  variant="h5"
                  sx={{
                     fontFamily: 'Inter, sans-serif',
                     color: 'rgb(0, 0, 0)',
                     fontSize: '36px',
                     fontWeight: '600',
                     lineHeight: '44px',
                     '@media (max-width: 450px)': {
                        fontSize: '30px',
                     },
                  }}
               >
                  Менторлор
               </Typography>
               <a href="/add-mentor">
                  <Button
                     sx={{
                        fontFamily: 'Inter, sans-serif',
                        borderRadius: '8px',
                        background: 'rgb(205, 164, 7)',
                        color: 'rgb(0, 0, 0)',
                        fontSize: '24px',
                        fontWeight: '600',
                        lineHeight: '29px',
                        padding: '13px 48px',
                        '@media (max-width: 450px)': {
                           fontSize: '20px',
                           padding: '10px 28px',
                        },
                     }}
                  >
                     Кошуу
                  </Button>
               </a>
            </Box>
            <Box sx={{ padding: '53px 0' }}>
               {isError && (
                  <Typography
                     color="error"
                     sx={{
                        fontSize: '30px',
                        textAlign: 'center',
                        horizontalAlign: 'center',
                     }}
                  >
                     {error.data.message === 'No mentors found'
                        ? 'Менторлор табылган жок'
                        : 'Маалыматтарды жүктөөдө ката кетти'}
                  </Typography>
               )}
               {!isError &&
                  mentors?.map((mentor, index) => (
                     <Card
                        key={index}
                        sx={{
                           width: '100%',
                           display: 'flex',
                           alignItems: 'center',
                           padding: '7px 20px',
                           borderRadius: '8px',
                           boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                           justifyContent: 'space-between',
                           border: '1px solid grey',
                           marginBottom: '10px',
                        }}
                     >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                           <Typography
                              variant="h1"
                              sx={{
                                 fontFamily: 'Inter, sans-serif',
                                 fontSize: '24px',
                                 fontWeight: '600',
                                 lineHeight: '29px',
                                 marginRight: '59px',
                                 '@media (max-width: 450px)': {
                                    marginRight: '10px',
                                 },
                              }}
                           >
                              {index + 1}.
                           </Typography>
                           <img
                              style={{
                                 width: '50px',
                                 height: '50px',
                                 borderRadius: '50%',
                                 objectFit: 'cover',
                              }}
                              src={
                                 mentor.imageMentor ||
                                 'https://via.placeholder.com/50'
                              }
                              alt={mentor.fullName}
                           />
                           <Box sx={{ marginLeft: '48px' }}>
                              <Typography
                                 variant="h1"
                                 sx={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '24px',
                                    fontWeight: '400',
                                    lineHeight: '29px',
                                    '@media (max-width: 450px)': {
                                       marginLeft: '20px',
                                    },
                                 }}
                              >
                                 {mentor.fullName}
                              </Typography>
                              <Typography
                                 variant="body1"
                                 sx={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '16px',
                                    color: 'gray',
                                 }}
                              >
                                 {mentor.specialization}
                              </Typography>
                           </Box>
                        </Box>
                        <Box
                           sx={{
                              display: 'flex',
                              gap: '64px',
                              '@media (max-width: 500px)': {
                                 gap: '15px',
                              },
                           }}
                        >
                           <a href={`/edit-mentor/${mentor.id}`}>
                              <Button
                                 sx={{
                                    width: '40px',
                                    height: '40px',
                                    minWidth: '40px',
                                    minHeight: '40px',
                                    boxSizing: 'border-box',
                                    borderRadius: '5px',
                                    boxShadow:
                                       'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    background: 'rgb(255, 255, 255)',
                                 }}
                              >
                                 <EditIcon sx={{ color: '#000' }} />
                              </Button>
                           </a>
                           <Button
                              onClick={() => handleOpen(mentor.id)}
                              sx={{
                                 width: '40px',
                                 height: '40px',
                                 minWidth: '40px',
                                 minHeight: '40px',
                                 boxSizing: 'border-box',
                                 borderRadius: '5px',
                                 boxShadow:
                                    'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                 background: 'rgb(255, 255, 255)',
                              }}
                           >
                              <DeleteIcon sx={{ color: '#000' }} />
                           </Button>
                        </Box>
                     </Card>
                  ))}
            </Box>
         </Box>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <WrappedDeleteMentor
               handleOpen={handleOpen}
               handleClose={handleClose}
               open={open}
               mentorId={selectedMentorId}
            />
         </Modal>
      </Container>
   )
}

export default AdminMentors
